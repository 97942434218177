import styled from 'styled-components';
import { Button } from '@quotalogic/ui';
import type { FC } from 'react';
import { Check } from '@gravity-ui/icons';

const Layout = styled.div<{ $isChecked: boolean }>`
  align-self: flex-end;
  opacity: ${({ $isChecked }) => ($isChecked ? 1 : 0)};
`;

interface Props {
  isChecked?: boolean
  onClick: (status: boolean) => void
}

export const Checkmark: FC<Props> = ({ isChecked, onClick }) => (
  <Layout $isChecked={isChecked ?? false} className="card-checkmark">
    <Button size="small" colorTheme="green" onClick={() => onClick(!isChecked)} data-testid="toggleCheckmarkBtn">
      <Check color={isChecked ? 'white' : 'transparent'} />
    </Button>
  </Layout>
);
