import React, {
  FC, useMemo, useReducer, ReactNode, useCallback, useState,
} from 'react';
import {
  CreateSchedulerInput,
  DayOfWeek, Scheduler,
  Scheduler as SchedulerType,
  SchedulerActionType, SchedulerStatus,
  ScheduleType, UpdateSchedulerInput,
} from '@quotalogic/gateway/types/Scheduler';
import { useTranslation } from 'next-i18next';
import { Button, Selector, SwitchBase } from '@quotalogic/ui';
import { FetchResult, gql, useMutation, useQuery } from '@apollo/client';
import { Workspace } from '@quotalogic/gateway/types';
import { getSchedulerInput, initializer, initialState, reducer, SchedulerState } from './helpers';
import { CREATE_SCHEDULER, RESTORE_SCHEDULER, UPDATE_SCHEDULER, UPDATE_SCHEDULER_STATUS } from '../../gql/mutation';
import { GET_SCHEDULER } from '../../gql/query';
import {
  Wrapper, TitleBlock, SelectBlock, Block, Label, Layout, NotificationStyle,
} from './styles';
import { StatusIndicator } from '../StatusIndicator';
import { DataPicker } from './DataPicker';
import { MonthDaysPicker } from './MonthDaysPicker';
import { WeekDaysPicker } from './WeekDaysPicker';
import { TimePicker } from './TimePicker';

interface Props {
  status?: SchedulerStatus
  title?: string
  event?: SchedulerType
  type: SchedulerActionType
  entityId: string
  entityName?: string
  children: ReactNode
}

export const Settings: FC<Props> = ({ status, title, event, type, entityId, entityName, children }) => {
  const { t } = useTranslation();

  const [scheduler, dispatchScheduler] = useReducer(reducer, initialState, () => initializer(event));

  const isActive = useMemo(() => status === 'ACTIVE', [status]);

  // Button logic
  const [createScheduler, { loading: loadingCreate }] = useMutation<{ createScheduler: Scheduler }, {
    data: CreateSchedulerInput
  }>(CREATE_SCHEDULER);

  // update scheduler
  /**
   * @description
   * Using separate update state because loading never becomes false with ignoreResults
   * issue — https://github.com/apollographql/apollo-client/issues/9602
   */
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [updateScheduler] = useMutation<never, {
    id: string,
    data: UpdateSchedulerInput
  }>(UPDATE_SCHEDULER);

  // update status
  const [updateSchedulerStatus, { loading: loadingUpdateStatus }] = useMutation<never, {
    id: string,
    status: SchedulerStatus
  }>(UPDATE_SCHEDULER_STATUS);

  // restore closed scheduler
  const [restoreScheduler, { loading: loadingRestore }] = useMutation<never, {
    id: string,
    data: UpdateSchedulerInput
  }>(RESTORE_SCHEDULER);

  const isLoading = useMemo(
    () => loadingCreate || loadingUpdate || loadingUpdateStatus || loadingRestore,
    [loadingCreate, loadingUpdate, loadingUpdateStatus, loadingRestore],
  );

  const { data }: FetchResult<{ workspace: Workspace }> = useQuery(gql`
    query Workspace {
      workspace {
        id
        timeZone
      }
    }
  `);

  const createEvent = useCallback(async () => {
    const { time, scheduleType, daysOfMonth, ...input } = getSchedulerInput(scheduler);
    const defaultDayOfMonth: number[] = daysOfMonth ?? [1];

    if (data?.workspace?.timeZone && time) {
      await createScheduler({
        variables: {
          data: {
            ...input,
            scheduleType: scheduleType ?? 'ONCE',
            time,
            entityId,
            entityName,
            actionType: type,
            timeZone: data.workspace.timeZone,
            daysOfMonth: scheduleType === 'MONTHLY' ? defaultDayOfMonth : undefined,
          },
        },
        refetchQueries: [
          { query: GET_SCHEDULER, variables: { entityId } },
        ],
      });
    }
  }, [entityId, scheduler, type]);

  const restoreEvent = async (id: string) => {
    const { time, ...input } = getSchedulerInput(scheduler);
    if (data?.workspace?.timeZone && time) {
      await restoreScheduler({
        variables: {
          id,
          data: {
            ...input,
            time,
            status: 'ACTIVE',
          },
        },
      });
    }
  };

  const stopScheduler = async (id: string) => {
    await updateSchedulerStatus({
      variables: {
        id,
        status: 'PAUSED',
      },
    });
  };

  const startScheduler = async (id: string) => {
    await updateSchedulerStatus({
      variables: {
        id,
        status: 'ACTIVE',
      },
    });
  };

  const handleAction = useCallback(
    async () => {
      if (event?.id) {
        switch (status) {
          case 'ACTIVE':
            return await stopScheduler(event.id);

          case 'CLOSED':
            return await restoreEvent(event.id);

          default:
            return await startScheduler(event.id);
        }
      }

      return await createEvent();
    },
    [event?.id, status, createEvent],
  );

  const handleUpdate = async (id: string, state: SchedulerState, ignoreResults = false) => {
    if (!ignoreResults) setLoadingUpdate(true);
    const input = getSchedulerInput(state);
    await updateScheduler({
      variables: {
        id,
        data: {
          ...input,
          status: 'PAUSED',
        },
      },
      ignoreResults,
    });
    if (!ignoreResults) setLoadingUpdate(false);
  };

  // update time
  const handleUpdateTime = async (time: string) => {
    // update only if the time has changed
    if (time !== scheduler.time) {
      dispatchScheduler({ action: 'UPDATE', key: 'time', value: time });

      if (event) await handleUpdate(event.id, { ...scheduler, time }, true);
    }
  };

  // update type
  const handleTypeUpdate = async (type: ScheduleType) => {
    dispatchScheduler({ action: 'UPDATE', key: 'type', value: type });

    if (event) await handleUpdate(event.id, { ...scheduler, type });
  };

  // update date
  const handleDateUpdate = async (date?: Date) => {
    dispatchScheduler({ action: 'UPDATE', key: 'date', value: date });

    if (event) await handleUpdate(event.id, { ...scheduler, date });
  };

  // update week
  const handleWeekUpdate = async (daysOfWeek: DayOfWeek[]) => {
    dispatchScheduler({ action: 'UPDATE', key: 'daysOfWeek', value: daysOfWeek });

    if (event) await handleUpdate(event.id, { ...scheduler, daysOfWeek });
  };

  // update month
  const handleMonthUpdate = async (daysOfMonth: number[]) => {
    dispatchScheduler({ action: 'UPDATE', key: 'daysOfMonth', value: daysOfMonth });

    if (event) await handleUpdate(event.id, { ...scheduler, daysOfMonth });
  };

  // update notifications
  const handleNotificationUpdate = async () => {
    const notifications = scheduler.notifications === 'LAUNCH' ? 'LAUNCH_FAILED' : 'LAUNCH';
    dispatchScheduler({ action: 'UPDATE', key: 'notifications', value: notifications });

    if (event && event.status !== 'CLOSED') {
      await updateScheduler({
        variables: {
          id: event.id,
          data: {
            notifications,
          },
        },
      });
    }
  };

  const buttonText = useMemo(() => {
    if (event?.id) {
      switch (status) {
        case 'ACTIVE':
          return 'Stop';

        case 'CLOSED':
          return 'Start';

        default:
          return 'Run';
      }
    }
    return 'Create';
  }, [status, event?.id]);

  const options = useMemo(() => [
    { value: 'ONCE', name: t('scheduler.refresh.list.ONCE') },
    { value: 'DAILY', name: t('scheduler.refresh.list.DAILY') },
    { value: 'WEEKLY', name: t('scheduler.refresh.list.WEEKLY') },
    { value: 'MONTHLY', name: t('scheduler.refresh.list.MONTHLY') },
  ], [t]);

  return (
    <Layout>
      <Wrapper $status={status}>
        <TitleBlock>
          <StatusIndicator status={status} />
          <span>{title}</span>
        </TitleBlock>

        <SelectBlock>
          <Block>
            <Label $isDisabled={isActive}>
              {t('scheduler.refresh.title')}
            </Label>
            <Selector
              value={scheduler.type}
              options={options}
              onChange={(val) => handleTypeUpdate(val as ScheduleType)}
            />
          </Block>

          <Block>
            <Label $isDisabled={isActive}>
              {t('scheduler.time')}
            </Label>
            <TimePicker
              isActive={isActive}
              startTime={scheduler.time}
              onBlur={handleUpdateTime}
            />
          </Block>

        </SelectBlock>
        {!(scheduler.type === 'DAILY') && (
          <>
            <Label $isDisabled={isActive}>
              {t('scheduler.date')}
            </Label>
            {scheduler.type === 'ONCE'
              && (
                <DataPicker
                  status={status}
                  onDateChange={handleDateUpdate}
                  date={scheduler.date}
                />
              )}
            {scheduler.type === 'WEEKLY'
              && (
                <WeekDaysPicker
                  status={status}
                  onDayChange={handleWeekUpdate}
                  days={scheduler.daysOfWeek ?? []}
                />
              )}
            {scheduler.type === 'MONTHLY'
              && (
                <MonthDaysPicker
                  status={status}
                  days={scheduler.daysOfMonth ?? [1]}
                  onDayChange={handleMonthUpdate}
                />
              )}
          </>
        )}

        <Button onClick={handleAction} disabled={event?.isDisabled || isLoading}>
          {buttonText}
        </Button>
      </Wrapper>
      {children}

      <NotificationStyle>
        <div>Notify about the launch on the dashboard</div>
        <SwitchBase
          size="large"
          disabled={isActive}
          checked={scheduler.notifications === 'LAUNCH'}
          onChange={handleNotificationUpdate}
        />
      </NotificationStyle>
    </Layout>
  );
};
