import styled from 'styled-components';

export const Layout = styled.section`
  padding-top: 60px;

  @media (max-width: 768px) {
    padding: 36px 24px 24px 24px;
  }
`;

export const Grid = styled.div`
  padding-top: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Title = styled.h2`
  font-size: 2.714rem;
  font-weight: 700;
  line-height: normal;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1.714rem;
  }
`;
