import React, {FC, useEffect, useState} from 'react';
import { Button, Modal, Picture, Tooltip } from '@quotalogic/ui';
import { useTranslation } from 'next-i18next';
import { StyledCard, StyledActions, StyledImage, StyledStub, StyledTitle, DeliveryLayout } from './styles';
import { Checkmark } from './Actions/Checkmark';
import { ButtonConnect } from './Actions/ButtonConnect';
import { ButtonDisconnect } from './Actions/ButtonDisconnect';

interface Props {
  id: string
  name?: string
  isChecked?: boolean
  image?: string
  onClick: (id: string) => void
  onToggleDefault?: (id: string, status: boolean) => void
  onClickConnect?: (id: string) => void
  onClickDisconnect?: (id: string) => void
  canConnect?: boolean
  workspaceId?: string
  deliveryId?: string | null
}

export const DeliveryCard: FC<Props> = ({
  id,
  name,
  isChecked = false,
  onClick, image,
  onToggleDefault,
  onClickConnect,
  onClickDisconnect,
  canConnect = true,
  workspaceId,
  deliveryId,
}) => {
  const { t: translation } = useTranslation();
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    if (deliveryId === id) {
      onClick(id);
    }
  }, []);

  return (
    <StyledCard $isChecked={isChecked} data-testid="deliveryItemCard">
      <DeliveryLayout role="button" onClick={() => onClick(id)}>
        {image ? (
          <StyledImage>
            <Picture src={image} alt={name} objectFit="cover" />
          </StyledImage>
        ) : <StyledStub />}
        <StyledTitle>
          {name}
        </StyledTitle>
      </DeliveryLayout>
      <StyledActions>
        {onClickDisconnect && <ButtonDisconnect onClick={() => setVisible(true)} />}
        {workspaceId && onClickConnect && canConnect && <ButtonConnect onClick={() => onClickConnect(id)} />}
        {onToggleDefault && (
          <Tooltip
            content={translation('label.autoAdding')}
            placement="top-end"
          >
            <Checkmark
              onClick={() => onToggleDefault(id, !isChecked)}
              isChecked={isChecked}
            />
          </Tooltip>
        )}
      </StyledActions>
      <Modal
        isVisible={isVisible}
        size="small"
        title={translation('delivery.modal.disconnect.title') as string}
        onClose={() => setVisible(false)}
        footer={(
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: '8px' }}>
            <Button view="secondary" onClick={() => setVisible(false)} width="170px">
              {translation('button.cancel')}
            </Button>
            {onClickDisconnect && (
              <Button view="primary" onClick={() => onClickDisconnect(id)} width="170px">
                {translation('delivery.modal.disconnect.button')}
              </Button>
            )}
          </div>
        )}
      >
        {translation('delivery.modal.disconnect.body')}
      </Modal>
    </StyledCard>
  );
};
