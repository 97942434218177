import React, { FC, useMemo } from 'react';
import { Minus, CircleInfo } from '@gravity-ui/icons';
import { EventHistory, EventStatus } from '@quotalogic/gateway/types/Scheduler';
import { Wrapper, DateBlock, RangeBlock, StatusBlock, StatusStyle, FinishBlock } from './styles';
import { DateTime } from '../../../../DateTime';
import { SunUpIcon } from '@quotalogic/ui';

interface Props {
    item: EventHistory
}

const getStatus = (status: EventStatus) => {
  switch (status) {
    case 'SUCCESS':
    case 'SUCCESS_WITH_WARNINGS':
      return 'Success';

    case 'FAIL':
      return 'Error';

    case 'IN_PROGRESS':
    case 'DOWNLOADING_IMAGES':
    case 'IMPORT':
    case 'JUST_CREATED':
    case 'PARSING':
    case 'PROCESSING_IMAGES':
    case 'SAVING_TO_INTERMEDIATE_STORAGE':
      return 'In Progress';

    default:
      return null;
  }
};

export const HistoryItem: FC<Props> = ({ item }) => {
  const status = getStatus(item.status);

  const isNexDayFinished = useMemo(() => {
    const startDate = new Date(item.startedAt);
    const finishDate = item.finishedAt && new Date(item.finishedAt);

    return finishDate && startDate.getDate() !== finishDate.getDate();
  }, [item]);

  return (
    <Wrapper>
      <DateBlock>
        <DateTime date={item.startedAt} options={{ year: 'numeric', month: 'numeric', day: 'numeric' }} />
      </DateBlock>
      <RangeBlock>
        <DateTime date={item.startedAt} options={{ hour: '2-digit', minute: '2-digit' }} />
        <Minus color="#BDBDBD" />
        <FinishBlock>
          {item?.finishedAt && <DateTime date={item.finishedAt} options={{ hour: '2-digit', minute: '2-digit' }} />}
          {isNexDayFinished && <SunUpIcon />}
        </FinishBlock>
      </RangeBlock>

      <StatusBlock>
        {status ? <StatusStyle><div />{status}</StatusStyle> : null}

        <button
          type="button"
        >
          <CircleInfo color="#BDBDBD" />
        </button>
      </StatusBlock>

    </Wrapper>
  );
};
