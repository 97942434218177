import styled from 'styled-components';
import { Button, GlobalColors } from '@quotalogic/ui';

export const StyleButton = styled(Button)`
  font-size: 1rem;
  padding: 0;
  width: 170px;
`;

export const Text = styled.span`
  max-width: 420px;
  color: ${GlobalColors.text.ghost};
`;

export const Container = styled.div`
  display: flex;
  gap: 8px;
  
  svg {
    margin-top: 4px;
    color: ${GlobalColors.icon.ghost};
  }
`;
