import { gql } from '@apollo/client';

export const CHANGE_SELECTED_ITEM = gql`
  mutation UpdateSelectedItemInOfferPublication($id: ID!, $groupId: ID!, $activeProductId: String!) {
    updateSelectedItemInOfferPublication(id: $id, groupId: $groupId, activeProductId: $activeProductId) {
      id
      selectedItemId
    }
  }
`;
