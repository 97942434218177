type TimeState = {
  hours: string;
  minutes: string;
};

type TimeAction = { type: 'SET_HOURS', payload: string } | { type: 'SET_MINUTES', payload: string } | { type: 'BLUR' }

export const timeReducer = (state: TimeState, action: TimeAction): TimeState => {
  if (action.type === 'SET_HOURS') {
    const numbersOnly = action.payload.replace(/\D/g, '');

    // check if the first hour number is greater than 2 -> add a 0 before
    if (numbersOnly.length === 1 && numbersOnly[0] > '2') {
      return { ...state, hours: `0${numbersOnly}` };
    }
    // check if the first hour number is greater than 2 or the first two numbers are greater than 24
    if (numbersOnly[0] > '2' || +(numbersOnly[0] + Number(numbersOnly[1])) > 24) {
      return state;
    }
    // check if input contains more than two numbers
    if (numbersOnly.length > 2) {
      return state;
    }

    return { ...state, hours: numbersOnly };
  }

  if (action.type === 'SET_MINUTES') {
    const numbersOnly = action.payload.replace(/\D/g, '');

    // check if the first minutes number is greater than 5 -> add a 0 before
    if (numbersOnly.length === 1 && numbersOnly[0] > '5') {
      return { ...state, minutes: `0${numbersOnly}` };
    }
    // check if the first minutes number is greater than 5
    if (numbersOnly[0] > '5') {
      return state;
    }
    // check if input contains more than two numbers
    if (numbersOnly.length > 2) {
      return state;
    }

    return { ...state, minutes: numbersOnly };
  }

  if (action.type === 'BLUR') {
    let { hours, minutes } = state;

    // add a 0 before if there is no number
    if (hours.length === 0) hours = '00';
    if (minutes.length === 0) minutes = '00';

    // add a 0 before if there is only one number
    if (hours.length === 1) hours = `0${hours}`;
    if (minutes.length === 1) minutes = `0${minutes}`;

    return { hours, minutes };
  }

  return state;
};

export const initialTime: TimeState = {
  hours: '00',
  minutes: '00',
};

export const timeInitializer = (startTime?: string): TimeState => {
  if (!startTime) return initialTime;
  const time = startTime.split(':');
  return { hours: time[0], minutes: time[1] };
};
