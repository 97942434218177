import styled, { css } from 'styled-components';
import { GlobalColors } from '@quotalogic/ui';

type LayoutType = 'grid' | 'column';

export const StyledCard = styled.article`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 12px;
  padding: 12px;
  background-color: ${GlobalColors.bg.main.thirdly};
`;

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  min-height: 24px;
`;

export const StyledTitle = styled.h3`
  font-size: 1rem;
  font-weight: 700;
`;

export const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;
`;

export const StyledList = styled.ul<{ $layout: LayoutType }>`
  gap: 8px;
  
  ${({ $layout }) => {
    switch ($layout) {
      case 'grid':
        return css`
          display: grid;
          grid-template-columns: repeat(3, 1fr);
        `;

      case 'column':
      default:
        return css`
          display: flex;
          flex-direction: column;
        `;
    }
  }}
`;
