import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Carousel } from '@quotalogic/ui';
import { PublicOfferProduct } from '@quotalogic/gateway/types/Offer';
import { checkValue } from './helpers';
import {
  Wrapper, Images, BlockItem, Title, ValueRichTextStyle, MoreStyle, GradientStyle,
} from './styles';

interface Props {
  product: PublicOfferProduct
}

export const OfferProductPopup: FC<Props> = ({ product }) => {
  const { t } = useTranslation('public');
  const [isFull, setIsFull] = useState(false);
  const [hasFull, setHasFull] = useState(false);

  const description = checkValue(product?.description) ? product?.description : null;
  const longDescription = checkValue(product?.longDescription) ? product?.longDescription : null;

  useEffect(() => {
    const el = document.getElementById('longDescription');
    const clientHeight = el?.clientHeight;
    const scrollHeight = el?.scrollHeight;

    if (scrollHeight && clientHeight && (scrollHeight > clientHeight)) {
      setHasFull(true);
    }
  }, [longDescription]);

  return (
    <Wrapper>
      {!!product.images?.length && (
        <Images>
          <Title>Images</Title>
          <Carousel id="public_offer_product" images={product.images} />
        </Images>
      )}

      {description && (
        <BlockItem>
          <Title>Description</Title>
          <ValueRichTextStyle $isFull dangerouslySetInnerHTML={{ __html: description }} />
        </BlockItem>
      )}

      {longDescription && (
        <BlockItem>
          <Title>LongDescription</Title>
          <ValueRichTextStyle
            $isFull={isFull}
            id="longDescription"
            dangerouslySetInnerHTML={{ __html: longDescription }}
          />
          {hasFull && (
            <>
              <GradientStyle $isFull={isFull} />
              <MoreStyle onClick={() => setIsFull(!isFull)}>
                {isFull ? t('catalog.hide') : t('catalog.show')}
              </MoreStyle>
            </>
          )}
        </BlockItem>
      )}
    </Wrapper>
  );
};
