/* eslint-disable */
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  UniqueIdentifier,
} from '@dnd-kit/core';
import type { Nodes } from '@quotalogic/gateway/types';
import { flatten, getProjection } from './utilities';
import { SensorContext } from './types';
import { SectionItem } from '../SectionItem';

interface Props {
  collapsible?: boolean;
  indentationWidth?: number;
  nodes: Nodes;
  catalogId: string;
  sectionId: string
}

export const UnSortableList: FC<Props> = ({
  collapsible,
  indentationWidth = 16,
  nodes,
  catalogId,
  sectionId,
}) => {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const { collapsedItems, setCollapsedItems } = useCollapsed(nodes, sectionId);
  const flattenedItems = useMemo(() => flatten(nodes, catalogId, collapsedItems), [nodes, collapsedItems]);

  const projected = activeId && overId
    ? getProjection(
      flattenedItems,
      activeId,
      overId,
      offsetLeft,
      indentationWidth,
    )
    : null;

  const sensorContext: SensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  return (
    <div>
      {flattenedItems.map(({ id, children, collapsed, depth }) => (
        <SectionItem
          key={id}
          id={id as string}
          depth={id === activeId && projected ? projected.depth : depth}
          indentationWidth={indentationWidth}
          sectionId={id}
          catalogId={catalogId}
          collapsed={Boolean(collapsed && children.length)}
            onCollapse={
              collapsible && children.length
                ? () => handleCollapse(id, collapsed)
                : undefined
            }
          status={id === sectionId ? 'selected' : 'default'}
        />
      ))}
    </div>
  );
  function handleCollapse(id: UniqueIdentifier, collapsed?: boolean) {
    if (collapsed) {
      const items = collapsedItems.filter((item) => item !== id);
      setCollapsedItems([...items, ...nodes[id]]);
    } else {
      setCollapsedItems([...collapsedItems, id as string]);
    }
  }
};

// collapse all nodes except the current branch
const useCollapsed = (nodes: Nodes, sectionId: string) => {
  const findParent = (id: string) => Object.keys(nodes).find((key) => nodes[key].includes(id));
  const collectParents = (id: string) => {
    const parent = findParent(id);

    if (parent) {
      parents.add(parent);
      collectParents(parent);
    }
  };

  const parents: Set<string> = new Set([]);

  collectParents(sectionId);

  const [collapsedItems, setCollapsedItems] = useState(() =>
    Object.keys(nodes).reduce((acc: string[], key) => {
      if (!parents.has(key)) {
        acc.push(key);
      }
      return acc;
    }, []));

  return {
    collapsedItems, setCollapsedItems,
  };
};
