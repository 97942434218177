import { gql } from '@apollo/client';

export const GET_INVITER_INVITES = gql`
  query InviterInvites {
    inviterInvites {
      id
      email
      status
      createdAt
    }
  }
`;

export const DELETE_INVITE = gql`
  mutation DeleteInvite($id: ID!) {
    deleteInvite(id: $id)
  }
`;
