import { useTranslation } from 'next-i18next';
import { ButtonStyle, IconStyle } from './styles';

type Props = {
  onClick: () => void
}

export const CreateWorkspaceButton = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <ButtonStyle onClick={onClick}>
      <IconStyle width={24} />
      {t('button.createWorkspace')}
    </ButtonStyle>
  );
};
