import { Nodes } from '@quotalogic/gateway/types';

export const checkInChildren = (sections: Nodes, sectionId: string, targetId?: string) => {
  if (sections) {
    // Проверяем, есть ли у данной секции потомки
    const children = sections[sectionId];

    // Если потомков нет, возвращаем false
    if (!children || children.length === 0) {
      return false;
    }

    // Если целевой элемент находится среди потомков, возвращаем true
    if (targetId && children.includes(targetId)) {
      return true;
    }

    // Проверяем всех потомков рекурсивно
    for (const childId of children) {
      if (checkInChildren(sections, childId, targetId)) {
        return true;
      }
    }
  }
  return false;
};
