import { useMemo } from 'react';
import { SchedulerStatus } from '@quotalogic/gateway/types/Scheduler';
import { WatchActiveIcon, WatchIcon, WatchStopIcon, WatchPauseIcon } from '@quotalogic/ui';
import { Wrapper } from './styles';

interface Props {
    status?: SchedulerStatus
}

export const StatusIndicator = ({ status }:Props) => {
  const getStatusIcon = useMemo(() => {
    switch (status) {
      case 'ACTIVE':
        return <WatchActiveIcon color="#3CAA3C" />;
      case 'PAUSED':
        return <WatchPauseIcon />;
      case 'CLOSED':
        return <WatchStopIcon />;
      default:
        return <WatchIcon color="#828282" />;
    }
  }, [status]);

  return (
    <Wrapper>
      {getStatusIcon}
    </Wrapper>
  );
};
