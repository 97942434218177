import { createNanoEvents } from 'nanoevents';

type Events = {
  CATALOG_UPDATE: (payload: { catalogId: string }) => void,
  REFETCH_NOTIFICATIONS: () => void,
  REFETCH_CATALOG_REQUESTS: (payload: { catalogId: string }) => void,
  REFETCH_CATALOG_INVITES: (payload: { catalogId: string }) => void,
  REFETCH_JOURNAL: (payload: { offerId: string }) => void,
  // open feedback modal and hydrate with error message
  OPEN_FEEDBACK: (payload: { summary?: string, message?: string }) => void,
  REFETCH_OFFERS: () => void,
}

export const $emitter = createNanoEvents<Events>();
