import { FetchResult, useMutation } from '@apollo/client';
import { Nodes, Section } from '@quotalogic/gateway/types';
import Router, { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC, useState } from 'react';
import { Plus } from '@gravity-ui/icons';
import { UnSortableList } from './components/UnSortableList';
import { CREATE_SECTION } from './mutation';
import { Aside, Text, NewSectionButton, List, Container, StyleButton } from './styles';
import { SortableList } from './components/SortableList';
import { RootItem } from './components/RootItem';

interface Props {
  catalogId: string
  // sections: Section[]
  nodes: Nodes
  inherit?: boolean
}

export const SectionAside: FC<Props> = ({ catalogId, nodes, inherit }) => {
  const { t: translation } = useTranslation();
  const { asPath, query: { sectionId } } = useRouter();
  const [createSectionLoading, setCreateSectionLoading] = useState(false);
  const [createSection] = useMutation(CREATE_SECTION);

  const create = async (parentId?: string) => {
    setCreateSectionLoading(true);
    await createSection({
      variables: {
        catalogId,
        data: {
          name: '',
          parentId,
        },
      },
      async update(cache, { data }: FetchResult<{ createSection: Section }>) {
        if (data && data?.createSection) {
          // cache.modify<Catalog>({
          //   id: cache.identify({
          //     __typename: 'Catalog',
          //     id: catalogId,
          //   }),
          //   fields: {
          //     nodes(existingNodes, { isReference }) {
          //       if (isReference(existingNodes)) return existingNodes;
          //
          //       return {
          //         ...existingNodes,
          //         [catalogId]: [
          //           ...existingNodes[catalogId],
          //           data.createSection.id,
          //         ],
          //         [data.createSection.id]: [],
          //       };
          //     },
          //     sections(refs, { toReference, isReference }) {
          //       const reference = toReference({ __typename: 'Section', id: data.createSection.id });
          //       if (reference && isReference(refs)) return [...refs, reference];
          //
          //       return refs;
          //     },
          //   },
          // });

          // redirect to the new section
          const sections = asPath.split('/');
          sections[3] = data.createSection.id;
          await Router.push(sections.join('/'));
        }
      },
    });
  };

  if (inherit) {
    return (
      <Aside data-testid="sectionAside">
        <Container>
          <List data-testid="sectionAsideList">
            <RootItem catalogId={catalogId} createItem={create} />
            {Object.keys(nodes[catalogId]).length > 0 && (
              <UnSortableList
                nodes={nodes}
                catalogId={catalogId}
                sectionId={String(sectionId)}
                collapsible
              />
            )}
          </List>
        </Container>
        <NewSectionButton>
          {!inherit && (
            <StyleButton
              view="ghost"
              onClick={() => create()}
              iconLeft={<Plus width={16} height={16} />}
              width="100%"
              loading={createSectionLoading}
              disabled={createSectionLoading}
            >
              {translation('title.newSection')}
            </StyleButton>
          )}
        </NewSectionButton>
      </Aside>
    );
  }

  return (
    <Aside data-testid="sectionAside">
      <Container>
        <List data-testid="sectionAsideList">
          <RootItem catalogId={catalogId} createItem={create} />
          {Object.keys(nodes[catalogId]).length > 0 && (
            <SortableList
              nodes={nodes}
              catalogId={catalogId}
              sectionId={String(sectionId)}
              createItem={create}
              collapsible
              indicator
            />
          )}
        </List>
      </Container>
      <NewSectionButton>
        {!inherit && (
          <StyleButton
            view="ghost"
            onClick={() => create()}
            iconLeft={<Plus />}
            width="100%"
            loading={createSectionLoading}
            disabled={createSectionLoading}
          >
            {translation('title.newSection')}
          </StyleButton>
        )}
      </NewSectionButton>
    </Aside>
  );
};
