import { styled } from 'styled-components';
import { Menu } from '@base-ui-components/react/menu';
import { GlobalColors } from '@quotalogic/ui';

export const MenuPositioner = styled(Menu.Positioner)`
  outline: none;
  width: 272px;
  z-index: 100;
`;

export const MenuPopup = styled(Menu.Popup)`
  background: white;
  outline: none;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.10);
  transform-origin: 0;
  transition: transform 150ms, opacity 150ms;
  color: ${GlobalColors.text.primary};

  &[data-starting-style],
  &[data-ending-style] {
      opacity: 0;
      transform: scale(0.9);
  }
`;

export const MenuSeparator = styled(Menu.Separator)`
    height: 1px;
    margin: 8px 0;
    background: ${GlobalColors.border.secondary};
`;
