import styled, { css } from 'styled-components';

export const TitleStyle = styled.h2`
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  flex: 1;
`;

export const ContentStyle = styled.div`
  padding: 0 24px;
`;

export const FooterStyle = styled.div<{ $placement: 'fill' | 'left' | 'right' | 'center' | 'stack' | 'space-between' }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 32px 24px 0;

  ${({ $placement }) => {
    switch ($placement) {
      case 'stack':
        return css`
          flex-direction: column;
          justify-content: center;
          align-items: stretch;
        `;

      case 'fill':
        return css`
          justify-content: center;
          
          & > { 
              button { width: 50%; } 
          };
        `;

      case 'right':
        return css`
          justify-content: flex-end;
        `;

      case 'center':
        return css`
          justify-content: center;
        `;

      case 'space-between':
        return css`
        justify-content: space-between;
      `;

      case 'left':
      default:
        return css`
          justify-content: flex-start;
        `;
    }
  }};
`;
