import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Button, Modal } from '@quotalogic/ui';
import { ChangePasswordForm } from './ChangePasswordForm';

const ChangePassword = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Button view="outline" onClick={() => setVisible(true)}>
        {t('button.changePassword')}
      </Button>
      <Modal
        onClose={() => setVisible(false)}
        isVisible={visible}
        title={t('title.changePassword')}
        size="small"
      >
        <ChangePasswordForm setVisible={setVisible} />
      </Modal>
    </>
  );
};

export default ChangePassword;
