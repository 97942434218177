/**
 * @deprecated
 * */
import React, { forwardRef, CSSProperties } from 'react';
import { ActionStyles } from './styles';

export interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  active?: {
    fill: string;
    background: string;
  };
  cursor?: CSSProperties['cursor'];
}

export const Action = forwardRef<HTMLButtonElement, Props>(
  ({ active, cursor, style, ...props }, ref) => (
    <ActionStyles
      ref={ref}
      {...props}
      tabIndex={0}
      style={
          {
            ...style,
            cursor,
            '--fill': active?.fill,
            '--background': active?.background,
          } as CSSProperties
        }
    />
  ),
);
