import { Menu } from '@base-ui-components/react/menu';
import { styled } from 'styled-components';
import { ChevronDown } from '@gravity-ui/icons';
import { GlobalColors } from '@quotalogic/ui';

export const TriggerStyle = styled(Menu.Trigger)`
  flex: 1;
  padding: 0 4px;
  height: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  outline: none;
    
  &:focus-visible,
  &:hover {
    background: ${GlobalColors.bg.darkBlue.ghostHover};
  }
`;

export const TextStyle = styled.span`
  flex: 1;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 650;
`;

export const IconStyle = styled(ChevronDown)`
  padding: 0 4px;
  color: ${GlobalColors.icon.ghost};
`;

export const SkeletonStyle = styled.div`
  padding: 0 4px;
  height: 32px;
  flex: 1;
`;
