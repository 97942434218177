import styled from 'styled-components';
import { Button as BaseButton } from '@quotalogic/ui';

export const Aside = styled.aside`
  display: flex;
  flex-flow: column;
  height: 100%;
  gap: 4px;
  width: 286px;
  border-right: 1px solid #e5e5e5;
`;

export const Container = styled.div`
  flex: 1;
  position: relative; 
  overflow: auto;
  
  &::-webkit-scrollbar {
    width: 4px;
    padding: 0;
    background: #f2f2f2;
  },

  &::-webkit-scrollbar-thumb {
    background-color: blue;
    border-radius: 20px;
    border: 3px solid #bdbdbd;
  },
`;

export const List = styled.ul`
  display: flex;
  position: absolute;
  flex-direction: column;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
`;

export const Button = styled.button`
  all: unset;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: #777777;
  padding: var(--spacing-s) 8px;
  outline: none;
  cursor: pointer;

  &:hover {
    background: #f3f3f3;
    border-radius: 4px;
  },

  &:hover svg {
    fill: #888888;
  },

  svg {
    fill: #CCCCCC;
  },
`;

export const Text = styled.p`
  padding: 16px 8px 0 8px;
  flex: 1;
`;

export const NewSectionButton = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  padding: 0 8px;
  border-top: 1px solid #e0e0e0;
`;

export const StyleButton = styled(BaseButton)`
  justify-content: flex-start;
  color: #555;

  &:hover {
    background: transparent;
  }
`;
