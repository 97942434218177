import styled, { css } from 'styled-components';

export const Price = styled.div<{ $count: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  ${({ $count }) => $count > 1 && css`
    padding-bottom: 12px;
    border-bottom: 1px solid var(--public-bg-line);
  `};

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
  }
`;

export const DiscountContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  padding-top: 8px;
  padding-bottom: 12px;
  grid-area: discount;

  @media (max-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const PriceWithDiscount = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: var(--public-text-ghost);

  @media (max-width: 768px) {
    font-size: 0.786rem;
  }
`;

export const Discount = styled.p`
  font-size: 1rem;
  font-weight: 700;
  color: var(--public-text-white);
  background: var(--public-bg-label-gray);
  border-radius: 24px;
  width: max-content;
  padding: 0 4px;

  @media (max-width: 768px) {
    position: absolute;
    left: 20px;
    top: 20px;
  }
`;

export const PriceWithoutDiscount = styled.p`
  font-size: 1.143rem;
  font-weight: 400;
  color: var(--public-text-primary);

  @media (max-width: 768px) {
    font-size: 0.857rem;
  }
`;
