import { FC } from 'react';
import { Total } from './styles';

interface Props {
  totalWithDiscount: string
  totalWithoutDiscount: string
  isDiscounts: boolean
}

export const OfferGroupTotal: FC<Props> = ({ totalWithDiscount, totalWithoutDiscount, isDiscounts }) => {
  if (isDiscounts) {
    return <Total>{totalWithDiscount}</Total>;
  }

  return <Total style={{ textDecoration: 'line-through' }}>{totalWithoutDiscount}</Total>;
};
