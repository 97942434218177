import styled from 'styled-components';
import { GlobalColors } from '@quotalogic/ui';

export const Layout = styled.div`
  display: flex;
  gap: 6px;
  border-radius: 6px;
  background: ${GlobalColors.bg.main.thirdly};
  padding: 14px 8px;
  align-items: center;
`;

export const Image = styled.div`
  width: 36px;
  height: 36px;
  position: relative;
`;

export const Name = styled.span`
  color: ${GlobalColors.text.primary};
  font-weight: 700;
  line-height: 16px;
`;
