import { FC, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Workspace } from '@quotalogic/gateway/types';

export interface DateTimeProps {
  date: string
  options?: Intl.DateTimeFormatOptions
  locale?: string
}

const WORKSPACE_TIMEZONE = gql`
    query workspaceTimezone {
        workspace {
            id
            timeZone
        }
    }
`;

const defaultOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit',
};

export const DateTime: FC<DateTimeProps> = ({ date, options = defaultOptions, locale }) => {
  const { data } = useQuery<{ workspace: Workspace }>(WORKSPACE_TIMEZONE);

  const userLocale = ((): string => {
    if (locale) return locale;
    if (navigator.language === 'en-US') return 'en-GB';
    return navigator.language;
  })();

  const timeZoneDate = useMemo(() => {
    if (data?.workspace?.timeZone && navigator !== undefined) {
      const dateObj = new Date(date);
      return dateObj.toLocaleString(userLocale, {
        timeZone: data.workspace?.timeZone,
        ...options,
      });
    }

    return null;
  }, [userLocale, data, date]);

  if (timeZoneDate) return timeZoneDate;

  return null;
};
