import { gql } from '@apollo/client';

export const GET_CATALOG = gql`
  query Catalog($id: ID!) {
    catalog(id: $id) {
      id
      parent {
        catalogToken
        profileToken
      }
      columns {
        id
        label
      }
    }
  }
`;

export const UPDATE_IMPORT_PROFILE = gql`
  mutation UpdateImportProfile($id: ID!, $data: ImportProfileData) {
    updateImportProfile(id: $id, data: $data) {
      id
      codeName
      importMode
    }
  }
`;
