import { FetchResult, useQuery } from '@apollo/client';
import { FC, useMemo } from 'react';
import { Workspace } from '@quotalogic/gateway/types';
import { SectionTree } from '../SectionTree/SectionTree';
import { Layout, Catalogs, Sections, List, ButtonStyle } from './styles';
import { GET_WORKSPACE_WITH_CATALOGS } from './gql';
import { ModalSelector } from './ModalSelector';

interface Props {
  handleSectionClick: (id: string) => void
  handleCloseModal: () => void
  catalogId: string
}

export const ModalForm: FC<Props> = ({
  handleSectionClick,
  handleCloseModal,
  catalogId,
}) => {
  const { data }: FetchResult<{ workspace: Workspace }> = useQuery(GET_WORKSPACE_WITH_CATALOGS);

  const [list, ids] = useMemo(() => {
    if (data?.workspace?.catalogs) {
      const items = data.workspace.catalogs.filter((item) => !item.parent);
      return [
        items,
        items.map(({ id }) => id),
      ];
    }
    return [null, null];
  }, [data?.workspace?.catalogs]);

  if (!data) return null;

  if (ids && list && catalogId) {
    return (
      <ModalSelector
        ids={ids}
        list={list}
        catalogId={catalogId}
        handleSectionClick={handleSectionClick}
        handleCloseModal={handleCloseModal}
      />
    );
  }

  return null;
};
