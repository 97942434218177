// eslint-disable-next-line import/no-extraneous-dependencies
import styled, { css } from 'styled-components';

interface selectedProps {
    $isSelected: boolean
}

interface disabledProps {
  $isDisabled: boolean
}

export const Wrapper = styled.div`
  display: flex;
  gap: 2px;
`;

export const SelectDaysStyle = styled.div`
  position: relative;
`;

export const MonthWrapper = styled.div`
  position: absolute;
  top: 36px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 328px;
  flex-wrap: wrap;
  gap: 12px;
  background-color: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.10);
  border-radius: 8px;
  padding: 8px 12px;
`;

export const DayStyle = styled.div<selectedProps>`
  width: 24px;
  height: 24px;
  margin: 0 4px;
  line-height: 24px;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
  color: #000000D9;
  
  ${({ $isSelected }) => ($isSelected
    && 'background: #2F80ED; color: #fff; border-radius: 4px; '
  )};
`;

export const SelectedStyle = styled.div<disabledProps>`
  display: grid;
  grid-template-columns: 308px 16px;
  gap: 4px;
  justify-content: space-between;
  overflow-x: hidden;
  align-items: center;
  min-height: 33px;
  border-radius: 6px;
  border: 1px solid #E0E0E0;
  padding: 5px 8px;
  color: #333333;
  cursor: pointer;
  background: ${({ $isDisabled }) => ($isDisabled
    ? '#f8fcfa;'
    : '#ffffff;'
  )};
`;

export const SelectedContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
`;

export const SelectedDay = styled.span<disabledProps>`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  padding: 2px 4px;
  color: #fff;
  margin-right: 4px;
  background: ${({ $isDisabled }) => ($isDisabled
    ? '#7bb0ef;'
    : '#2F80ED;'
  )};
`;
