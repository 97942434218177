import { gql } from '@apollo/client';

export const RESET_ACCOUNT_VERIFICATION_TOKEN = gql`
  mutation ResetAccountVerificationToken {
    resetAccountVerificationToken
  },
`;

export const LOG_OUT = gql`
  mutation LogOut {
    logOut
  }
`;
