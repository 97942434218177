import { StyledLogo } from './styles';

type Props = {
  src: string
  name: string
}

export const Logo = ({ src, name }: Props) => (
  <StyledLogo src={src} alt={name} />
);
