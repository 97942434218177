import { FC, ReactNode, useState } from 'react';
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { Catalog } from '@quotalogic/gateway/types';

interface Props {
  catalog: Catalog
  list: {
    id: string
    name: string
    tab: ReactNode
  }[]
  publishButton: ReactNode
}

/**
 * @deprecated
 */
export const CatalogTabs: FC<Props> = ({
  catalog,
  list,
  publishButton,

}) => {
  const [tabIndex, setTabIndex] = useState(0);

  if (!catalog) return null;

  return (
    <Tabs
      onChange={(index) => setTabIndex(index)}
      variant="horizontal"
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #e5e5e5' }}>
        <TabList>
          {list.map(({ name, id }) => (
            <Tab key={id}>{name}</Tab>
          ))}
        </TabList>
        {publishButton}
      </div>
      <TabPanels>
        {list.map(({ tab, id }) => (
          <TabPanel key={id} padding={0}>
            {tab}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
