import { OfferNode } from '@quotalogic/gateway/types';
import { FC } from 'react';
import { useMutation } from '@apollo/client';
import {
  Layout,
  List,
  ProductContainer,
} from './styles';
import { OfferProduct } from './components/OfferProduct';
import { OfferGroup } from './components/OfferGroup';
import { CHANGE_SELECTED_ITEM } from './components/OfferGroup/gql';

interface Props {
  root: string
  nodes: Record<string, OfferNode[]>
  visibilityOptions: Record<string, boolean>
  currency: string
  publicId: string
}

type TreeNode = {
  id: string
  isGroup: boolean
  children?: TreeNode[]
}

export const GroupList: FC<Props> = ({
  nodes,
  root,
  visibilityOptions,
  currency,
  publicId,
}) => {
  const buildTree = (list: OfferNode[], nodes: Record<string, OfferNode[]>): TreeNode[] => list.map((node) => {
    if (node.isGroup) {
      return {
        ...node,
        children: buildTree(nodes[node.id], nodes),
      };
    }
    return node;
  });

  const [changeSelectedItem] = useMutation(CHANGE_SELECTED_ITEM);

  const handleCheck = async (productId: string, groupId: string) => {
    await changeSelectedItem({
      variables: {
        id: publicId,
        groupId,
        activeProductId: productId,
      },
      optimisticResponse: {
        updateSelectedItemInOfferPublication: {
          id: groupId,
          selectedItemId: productId,
        },
      },
    });
  };

  return (
    <Layout>
      <List>
        {buildTree(nodes[root], nodes).map((node) => (
          <OfferGroup
            id={node.id}
            key={node.id}
            products={nodes[node.id].map(({ id }) => id)}
            visibilityOptions={visibilityOptions}
            currency={currency}
            publicId={publicId}
          >
            {node.isGroup && node.children && (
              <ProductContainer>
                {node.children.map((child) => (
                  <OfferProduct
                    id={child.id}
                    key={child.id}
                    visibilityOptions={visibilityOptions}
                    parentId={node.id}
                    currency={currency}
                    onCheck={handleCheck}
                  />
                ))}
              </ProductContainer>
            )}
          </OfferGroup>
        ))}
      </List>
    </Layout>
  );
};
