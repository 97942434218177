// eslint-disable-next-line import/no-extraneous-dependencies
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  border-top: 1px solid #F2F2F2;
  padding: 4px 0 4px 4px;
  gap: 8px;
`;

export const Title = styled.span`
  font-weight: 700;
  margin-left: 4px;
`;

export const DateBlock = styled.span`
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  width: 84px;
  font-variant-numeric: tabular-nums;
`;

export const RangeBlock = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  font-variant-numeric: tabular-nums;
`;

export const FinishBlock = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StatusBlock = styled.span`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  gap: 8px;
`;

export const StatusStyle = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  line-height: 14px;
  background: #F2F2F2;
  
  & > div {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #D9D9D9;
  }
`;
