import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Avatar } from '@quotalogic/ui';
import { DELETE_AVATAR, UPLOAD_ACCOUNT_PICTURE } from '../gql/mutation';
import { AvatarBlock } from '../styles';

type Props = {
  src?: string
  name?: string
  alt?: string
}
export const AvatarInput = ({ src, name, alt = '' }: Props) => {
  const [state, setState] = useState(src);
  const [uploadAccountPicture] = useMutation(UPLOAD_ACCOUNT_PICTURE);
  const [deleteAvatar] = useMutation(DELETE_AVATAR);
  const handleFileChange = async (e: any) => {
    await uploadAccountPicture({
      variables: {
        picture: e,
      },
    });
    setState(URL.createObjectURL(e));
  };

  const handleDeleteAvatar = async () => {
    await deleteAvatar({
      onCompleted: () => {
        setState(undefined);
      },
    });
  };

  return (
    <AvatarBlock>
      <Avatar
        size={120}
        src={state}
        name={name}
        alt={alt}
        onChange={handleFileChange}
        onClear={handleDeleteAvatar}
      />
    </AvatarBlock>
  );
};
