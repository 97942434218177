import { gql } from '@apollo/client';
import { SECTION } from '@quotalogic/gateway/fragments';

export const GET_CATALOG = gql`
    ${SECTION}
    query Catalog($id: ID!) {
        catalog(id: $id) {
            id
            name
            isPublished
            parent {
                brand {
                    id
                    brandName
                }
                catalogToken
                profileToken
                disabled
            }
            nodes
            currency
            logo
            sections {
                ...Section
            }
        }
    }
`;

export const CONNECT_MULTI_SECTIONS = gql`
  mutation ConnectMultiSections($productId: ID!, $newSectionIds: [ID]!) {
      connectMultiSections(productId: $productId, newSectionIds: $newSectionIds)
  }
`;
