import { ReactNode } from 'react';
import { Xmark } from '@gravity-ui/icons';
import { createPortal } from 'react-dom';
import { Button } from '../../Button';
import { GlobalColors } from '../../GlobalStyle';
import { TitleStyle, FooterStyle } from './styles';
import { DialogHeaderStyle, DialogStyle, ModalOffset } from '../styles';
import { DialogBackdrop } from '../DialogBackdrop';

type Props = {
  title?: string | ReactNode
  width?: number
  centred?: boolean
  isVisible: boolean
  children?: ReactNode
  onClose: () => void
  buttons?: ReactNode
  buttonsPlacement?: 'fill' | 'left' | 'right' | 'center' | 'stack' | 'space-between'
  offset?: ModalOffset
}

export const Modal = ({
  width,
  title,
  isVisible,
  centred = false,
  children,
  onClose,
  buttons,
  buttonsPlacement = 'right',
  offset,
}: Props) => {
  if (!isVisible) return null;

  return createPortal(
    <DialogBackdrop onClose={onClose} centred={centred}>
      <DialogStyle
        $width={width}
        $offset={offset}
        onClick={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
      >
        <DialogHeaderStyle>
          <TitleStyle>{title}</TitleStyle>
          <Button view="ghost" onClick={onClose} autoFocus>
            <Xmark width={16} height={16} color={GlobalColors.icon.disable} />
          </Button>
        </DialogHeaderStyle>
        {children}
        {buttons && (
          <FooterStyle $placement={buttonsPlacement}>
            {buttons}
          </FooterStyle>
        )}
      </DialogStyle>
    </DialogBackdrop>,
    document.body,
  );
};
