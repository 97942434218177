import { Picture } from '@quotalogic/ui';
import { useMemo } from 'react';
import { Layout, Name, Image } from './styles';

type Props = {
  productName: string,
  productImages: string[],
}

export const ProductCard = ({ productName, productImages }: Props) => {
  const src = useMemo(() => {
    if (productImages && productImages.length > 0) {
      return productImages[0];
    }
    return '';
  }, [productImages]);

  return (
    <Layout>
      <Image>
        <Picture src={src} alt={productName} />
      </Image>
      {productName && (<Name>{productName}</Name>)}
    </Layout>
  );
};
