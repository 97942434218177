import { Button } from '@quotalogic/ui';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import {
  StyleContainer, Title, TextContainer, StyledText, StyledButton, Wrapper,
} from './styles';
import { LOG_OUT, RESET_ACCOUNT_VERIFICATION_TOKEN } from '../gql';

type Props = {
  onResetToken: () => void
}
export const ExpiredToken = ({ onResetToken }: Props) => {
  const { t } = useTranslation('register');
  const [logOut] = useMutation<{ logOut: boolean }>(LOG_OUT);
  const [resetAccountVerificationToken, { loading }] = useMutation<{ resetAccountVerificationToken: boolean }>(RESET_ACCOUNT_VERIFICATION_TOKEN);

  const resetLink = async () => {
    await resetAccountVerificationToken({
      onCompleted(data) {
        if (data.resetAccountVerificationToken) {
          onResetToken();
        }
      },
    });
  };

  const handleLogOut = async () => {
    await logOut({
      onCompleted(data) {
        if (data.logOut) {
          window.location.href = '/register';
        }
      },
    });
  };

  return (
    <StyleContainer>
      <Title>
        {t('expiredLink.title')}
      </Title>
      <TextContainer>
        <Wrapper>
          <StyledText>
            {t('expiredLink.preLink')}
          </StyledText>
          <StyledButton
            type="button"
            view="link"
            onClick={handleLogOut}
          >
            {t('expiredLink.registrationLink')}
          </StyledButton>
          <StyledText>
            {t('expiredLink.postLink')}
          </StyledText>
        </Wrapper>
        <StyledText>
          {t('expiredLink.text')}
        </StyledText>
      </TextContainer>
      <Button width="fit-content" onClick={resetLink} loading={loading}>
        {t('expiredLink.button')}
      </Button>
    </StyleContainer>
  );
};
