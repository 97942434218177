import { FC, useMemo } from 'react';
import { Employee } from '@quotalogic/gateway/types';
import { useTranslation } from 'next-i18next';
import { Comment } from '@gravity-ui/icons';
import {
  Contacts, Image, Link, Manager, Picture, Container, NameContainer, Position, Name, ContactsContainer, ChatButton,
} from './styles';
import noAvatar from './avatar_placeholder.png';

interface Props {
  manager: Employee
  width?: string
  view?: 'primary' | 'secondary'
}

export const PublicOfferManagerCard: FC<Props> = ({ manager, width, view = 'primary' }) => {
  const { t } = useTranslation('public-offer');

  const picture = useMemo(() => {
    if (manager?.image && manager.image.length > 0) {
      return manager.image;
    }

    return noAvatar.src;
  }, []);

  return (
    <Manager $width={width} $view={view}>
      <Container>
        <Picture>
          <Image src={picture} />
        </Picture>
        <NameContainer>
          <Position>{t('personalManager')}</Position>
          <Name>{manager?.name}</Name>
        </NameContainer>
      </Container>
      <ContactsContainer>
        <Contacts $view={view}>
          {manager?.email
            && (
            <Link style={{ color: 'var(--public-button-blue)' }} $border href={`mailto:${manager.email}`}>
              {manager.email}
            </Link>
            )}
          {manager?.phone && <Link href={`tel:${manager.phone}`}>{manager.phone}</Link>}
        </Contacts>
        <ChatButton>
          <Comment width="22px" height="22px" />
        </ChatButton>
      </ContactsContainer>
    </Manager>
  );
};
