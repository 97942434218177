import React, { FC, ReactNode, useState, useMemo } from 'react';
import { Button, Modal } from '@quotalogic/ui';
import { Minus, Plus } from '@gravity-ui/icons';
import { useTranslation } from 'next-i18next';
import { StyledCard, StyledHeader, StyledLink, StyledList, StyledTitle } from './styles';

interface Props {
  id: string
  name: string
  isLinkedToWorkspace?: boolean
  hasWorkspace?: boolean
  onStatusChange?: (id: string) => void
  children?: ReactNode
}

export const OrganizationCard: FC<Props> = ({
  id,
  name,
  isLinkedToWorkspace = false,
  onStatusChange,
  hasWorkspace = false,
  children,
}) => {
  const title = useMemo(() => {
    if (name.length > 0) return name;

    return 'No name';
  }, [name]);

  const { t: translation } = useTranslation();
  const [isVisible, setVisible] = useState(false);

  return (
    <StyledCard data-testid="orgItemCard">
      <StyledHeader>
        <StyledTitle>
          <StyledLink href={`/organizations/${id}`}>
            {title}
          </StyledLink>
        </StyledTitle>

        {hasWorkspace && isLinkedToWorkspace
          ? (
            <Button view="ghost" onClick={() => setVisible(true)} data-testid="orgDisconnectBtn">
              <Minus />
            </Button>
          )
          : (
            <Button view="ghost" onClick={() => onStatusChange && onStatusChange(id)} data-testid="orgConnectBtn">
              <Plus />
            </Button>
          )}

      </StyledHeader>
      {children && (
        <StyledList $layout={isLinkedToWorkspace ? 'grid' : 'column'}>
          {children}
        </StyledList>
      )}
      <Modal
        isVisible={isVisible}
        size="small"
        title={translation('organization.disconnect.title') as string}
        onClose={() => setVisible(false)}
        footer={(
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: '8px' }}>
            <Button view="secondary" onClick={() => setVisible(false)} width="170px">
              {translation('button.cancel')}
            </Button>
            <Button view="primary" onClick={() => onStatusChange && onStatusChange(id)} width="170px">
              {translation('organization.disconnect.button')}
            </Button>
          </div>
        )}
      >
        {translation('organization.disconnect.body')}
      </Modal>
    </StyledCard>
  );
};
