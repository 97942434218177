import styled from 'styled-components';
import { Button as ButtonControl } from '@quotalogic/ui';

export const StyleMenu = styled.div`
  color: #777;
  font-weight: 800;
`;

export const ListStyle = styled.ul`
  list-style-type: none;
`;

export const ListButton = styled(ButtonControl)`
  width: 100%;
  color: inherit;
  font-weight: 400;
`;

export const StyleButton = styled.button`
  all: unset;
  display: flex;
  width: 14px;
  padding: 4px;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: none;
  cursor: var(--cursor, pointer);
  border-radius: 5px;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  @media (hover: hover) {
    &:hover {
      background-color: var(--action-background, rgba(0, 0, 0, 0.05));

      svg {
        fill: #6f7b88;
      },
    },
  },

  svg {
    flex: 0 0 auto;
    margin: auto;
    height: 100%;
    overflow: visible;
    fill: #919eab;
  },

  &:active {
    background-color: var(--background, rgba(0, 0, 0, 0.05));

    svg {
      fill: var(--fill, #788491);
    },
  },

  &:focus-visible {
    outline: none;
  },
`;
