import styled, { css } from 'styled-components';
import { GlobalColors, Button } from '@quotalogic/ui';
import { STATUS_TYPES } from './types';

type InviteStatus = 'CREATED' | 'DECLINED' | 'ACCEPTED';

interface StatusProps {
  $status: InviteStatus
}

const getColorStyle = (status: InviteStatus) => {
  switch (status) {
    case STATUS_TYPES.created:
      return css`
        color: ${GlobalColors.text.accent}
      `;
    case STATUS_TYPES.declined:
      return css`
        color: ${GlobalColors.text.error}
      `;
    case STATUS_TYPES.accepted:
      return css`
        color: ${GlobalColors.text.success}
      `;
    default:
      return css`
        background: #DDEFFF
      `;
  }
};

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-bottom: 1px solid ${GlobalColors.border.secondary};
`;

export const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledEmail = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 18px;
  color: ${GlobalColors.text.secondary};
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyleDate = styled.div`
  color: ${GlobalColors.text.ghost};
`;

export const StyledButton = styled(Button)`
  height: 24px;
`;

export const Status = styled.span<StatusProps>`
  display: flex;
  gap: 8px;
  padding-top: 8px;
  font-size: 0.857rem;
  font-weight: 600;
  line-height: 16px;
  ${({ $status }) => getColorStyle($status)}
`;
