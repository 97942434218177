import { styled } from 'styled-components';
import { Menu } from '@base-ui-components/react/menu';
import { Check } from '@gravity-ui/icons';
import { GlobalColors } from '@quotalogic/ui';

export const StyledList = styled.div`
  overflow: auto;
  scrollbar-color: #bdbdbd #f2f2f2;
  min-height: 120px;
  max-height: calc(100vh - 120px);
  
  &::-webkit-scrollbar {
    width: 4px;
    padding: 0;
    background: #f2f2f2;
  },
  
  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
  }
`;

export const MenuItem = styled(Menu.Item)<{ $hasNote?: boolean }>`
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 32px 1fr auto;
  outline: none;
  gap: 8px;
  ${({ $hasNote }) => !$hasNote && 'align-items: center;'}
    
  &:hover,
  &:focus-visible {
    background: rgba(13, 45, 94, 0.04);
  }
`;

export const IconStyle = styled(Check)`
  color: ${GlobalColors.icon.ghost};
`;

export const TextStyle = styled.span`
  display: flex;
  flex-direction: column;
  line-height: 1rem;
  gap: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NoteStyle = styled.span`
  color: ${GlobalColors.text.ghost};
  font-size: 12px;
`;
