import { useQuery } from '@apollo/client';
import { Spinner } from '@quotalogic/ui';
import { Catalog } from '@quotalogic/gateway/types';
import { ModeSelector } from './ModeSelector';
import { CodeNameSelector } from './CodeNameSelector';
import { GET_CATALOG } from './gql';
import { StyledList } from './styles';

type Props = {
  catalogId: string
}

export const ReimportSettings = ({ catalogId }: Props) => {
  const { data, loading } = useQuery<{ catalog: Catalog }>(GET_CATALOG, { variables: { id: catalogId } });
  if (loading) return <Spinner size={16} />;

  return (
    <StyledList>
      <li><ModeSelector ancestor={Boolean(data?.catalog.parent)} /></li>
      <li><CodeNameSelector columns={data?.catalog.columns} /></li>
    </StyledList>
  );
};
