import type { ChangeEvent, FC } from 'react';
import { Plus, Xmark } from '@gravity-ui/icons';
import { useEffect, useState } from 'react';
import {
  StyledList,
  ButtonUpdateFile,
  FileInput,
  StyledImage,
  ImageSelector,
  StyledListItem,
  ButtonRemoveImage,
} from './styles';

type ImageEntity = {
  id: string
  src: string
}

interface Props {
  onUploadFile: (file: File) => void
  onRemoveFile: () => void
  activeImageId?: string
  imageSrc?: string
  defaultImages?: ImageEntity[]
  onSetImageId: (id: string) => void
}

export const ImageList: FC<Props> = ({
  onUploadFile,
  imageSrc,
  activeImageId,
  defaultImages,
  onSetImageId,
  onRemoveFile,
}) => {
  const [src, setSrc] = useState<string|null>(null);
  const [imageId, setImageId] = useState<string>('blob');

  useEffect(() => {
    if (activeImageId && imageSrc) {
      const isCustom = !defaultImages?.some((image) => image.id === activeImageId);
      if (isCustom) {
        setSrc(imageSrc);
        setImageId(activeImageId);
      }
    }
  }, [defaultImages]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileObj = e.target.files && e.target.files[0];
    if (fileObj) {
      onUploadFile(fileObj);
      setSrc(URL.createObjectURL(fileObj));
      onSetImageId('blob');
    }
  };

  const handleRemoveImage = () => {
    setSrc(null);
    onRemoveFile();
    if (activeImageId === 'blob' || activeImageId === imageId) {
      onSetImageId('');
    }
  };

  return (
    <StyledList>
      <StyledListItem>
        {!src && (
          <ButtonUpdateFile>
            <FileInput
              type="file"
              accept="image/png, image/jpeg, image/webp"
              onChange={handleFileChange}
            />
            <Plus />
          </ButtonUpdateFile>
        )}
        {src && (
          <ImageSelector
            type="button"
            $isActive={imageId === activeImageId}
            onClick={() => onSetImageId('blob')}
          >
            <StyledImage src={src} alt="" objectFit="cover" />
          </ImageSelector>
        )}
        {src && (
          <ButtonRemoveImage size="small" view="secondary" rounded onClick={handleRemoveImage}>
            <Xmark />
          </ButtonRemoveImage>
        )}
      </StyledListItem>
      {defaultImages?.map((image) => (
        <StyledListItem key={image.id}>
          <ImageSelector type="button" $isActive={image.id === activeImageId} onClick={() => onSetImageId(image.id)}>
            <StyledImage src={image.src} alt="" objectFit="cover" />
          </ImageSelector>
        </StyledListItem>
      ))}
    </StyledList>
  );
};
