import gql from 'graphql-tag';

export const CATALOG_COLUMN = gql`
    fragment CatalogColumn on Column {
        id
        label
        type
        hidden
        size
        parent
        children
        overrides
        modifier {
            type
            value
        }
        hasParent
    }
`;

export const SECTION = gql`
    fragment Section on Section {
        id
        name
        overrides
    }
`;

export const PUBLIC_SECTION = gql`
    fragment PublicSection on PublicSection {
        id
        name
    }
`;

export const CATALOG = gql`
    fragment Catalog on Catalog {
        id
        name
        logo
        parent {
            brand {
                id
            }
        }
    }
`;

export const PRODUCT = gql`
    fragment Product on Product {
        id
        name
        price
        description
        longDescription
        images
        properties
        modifiers {
            id
            type
            value
        }
        overrides
    }
`;

export const COLUMN = gql`
    fragment Column on Column {
        id
        label
        type
        hidden
        size
        children
        parent
        overrides
        modifier {
            type
            value
        }
    }
`;

export const PUBLIC_PROFILE = gql`
    fragment PublicProfile on PublicProfile {
        id
        name
        comment
        token
        isPublic
        fields {
            newFieldId
            newFieldLabel
            oldFieldId
        }
    }
`;
