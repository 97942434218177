import styled from 'styled-components';

export const Layout = styled.section`
  position: relative;

  @media (max-width: 768px) {
    padding: 24px 0;
  }
`;

export const List = styled.ul`
  list-style-type: none;
  
  > li + li {
    margin-top: 32px;
  };
`;

export const Price = styled.td`
  padding: 8px 8px 8px 0;
  border-bottom: 1px solid #e0e0e0;
  width: 120px;
`;

export const ProductContainer = styled.div`
  display: flex; 
  flex-direction: column;
  gap: 8px; 
  padding: 24px 0;
`;
