import { ChangeEvent, FC, useMemo, useRef } from 'react';
import { addNotification } from '@quotalogic/notifications/store';
import { useTranslation } from 'next-i18next';
import { ModifierInputStyle } from './styles';
import { getModifierString, parseModifierString, ValueModifier } from './utils';

type Props = {
  value?: ValueModifier
  onReset?: () => void
  onValueChange: (value: ValueModifier) => void
  isFade: boolean
  onFocus?: () => void
  onBlur?: () => void
}

export const ModifierInput: FC<Props> = ({ value, onValueChange, onFocus, onBlur, onReset, isFade }) => {
  /**
   * temporary removed cursor position tracking to fix instant focus in safari
   */
  const modifier = useMemo(() => value?.string ?? getModifierString(value), [value]);
  const { t } = useTranslation();

  const ref = useRef<HTMLInputElement>(null);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parsedResult: ValueModifier = parseModifierString(e.target.value);

    // check if result is safe integer
    if (Number.isSafeInteger(parsedResult.value)) {
      onValueChange(parsedResult);
    } else {
      addNotification({
        message: t<string>('product.warning.valueTooLarge'),
        level: 'WARNING',
      });
    }
  };

  const handleBlur = () => {
    if (modifier.length === 0) {
      onReset && onReset();
    }
    onBlur && onBlur();
  };

  return (
    <ModifierInputStyle
      ref={ref}
      $fade={isFade}
      onFocus={onFocus}
      onBlur={handleBlur}
      value={modifier}
      onChange={handleChange}
    />
  );
};
