import { gql } from '@apollo/client';
import { CATALOG } from '@quotalogic/gateway/fragments';

export const GET_WORKSPACE_WITH_CATALOGS = gql`
    ${CATALOG}
    query Workspace {
        workspace {
            id
            name
            role
            currency
            catalogs {
                ...Catalog
            }
        }
    }
`;
