import type { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

import { Nodes } from '@quotalogic/gateway/types';
import type { FlattenedItem } from './types';

const getDragDepth = (offset: number, indentationWidth: number) => Math.round(offset / indentationWidth);

const getMaxDepth = ({ previousItem }: { previousItem: FlattenedItem }) => {
  if (previousItem) {
    return previousItem.depth + 1;
  }

  return 0;
};

const getMinDepth = ({ nextItem }: { nextItem: FlattenedItem }) => {
  if (nextItem) {
    return nextItem.depth;
  }

  return 0;
};

export const getProjection = (
  items: FlattenedItem[],
  activeId: UniqueIdentifier,
  overId: UniqueIdentifier,
  dragOffset: number,
  indentationWidth: number,
) => {
  const overItemIndex = items.findIndex(({ id }) => id === overId);
  const activeItemIndex = items.findIndex(({ id }) => id === activeId);
  const activeItem = items[activeItemIndex];
  const newItems = arrayMove(items, activeItemIndex, overItemIndex);
  const previousItem = newItems[overItemIndex - 1];
  const nextItem = newItems[overItemIndex + 1];
  const dragDepth = getDragDepth(dragOffset, indentationWidth);
  const projectedDepth = activeItem.depth + dragDepth;
  const maxDepth = getMaxDepth({
    previousItem,
  });
  const minDepth = getMinDepth({ nextItem });
  const depth = projectedDepth < 0 ? 0 : projectedDepth;

  // eslint-disable-next-line no-use-before-define
  return { depth, maxDepth, minDepth, parentId: getParentId() };

  function getParentId() {
    if (depth === 0 || !previousItem) {
      return null;
    }

    if (depth === previousItem.depth) {
      return previousItem.parentId;
    }

    if (depth > previousItem.depth) {
      return previousItem.id;
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.depth === depth)?.parentId;

    return newParent ?? null;
  }
};

export const flatten = (
  nodes: Nodes,
  parentId: UniqueIdentifier | null,
  collapsedItems: string[],
  depth = 0,
): FlattenedItem[] => {
  if (!parentId) return [];

  return nodes[parentId].reduce<FlattenedItem[]>((acc, id, index) => {
    const collapsed = collapsedItems.includes(id);
    if (collapsed) {
      acc.push({ id, children: nodes[id], parentId, depth, index, collapsed });
    } else {
      acc.push({
        id,
        children: nodes[id],
        parentId,
        depth,
        index,
        collapsed,
      }, ...flatten(nodes, id, collapsedItems, depth + 1));
    }

    return acc;
  }, []);
};
