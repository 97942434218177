import { FC, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useMutation, gql } from '@apollo/client';
import { Label } from './styles';

interface Props {
  id: string
  isDefault: boolean
}

const UPDATE_DELIVERY_STATUS = gql`
  mutation UpdateDeliveryStatus($id: ID!, $isDefault: Boolean!) {
    updateDeliveryDefaultStatus(id: $id, isDefault: $isDefault) {
      deliveryId
      isDefault
    }
  }
`;

export const DeliveryStatus: FC<Props> = ({ id, isDefault }) => {
  const { t: translation } = useTranslation();
  const [checked, setChecked] = useState(isDefault);
  const [updateDeliveryStatus] = useMutation(UPDATE_DELIVERY_STATUS);

  const handleUpdate = async () => {
    setChecked((prevState) => !prevState);
    await updateDeliveryStatus({
      variables: {
        id,
        isDefault: !checked,
      },
    });
  };

  return (
    <Label>
      <input type="checkbox" checked={checked} onChange={handleUpdate} />
      {translation('delivery.default')}
    </Label>
  );
};
