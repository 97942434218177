import { QueryResult, useQuery } from '@apollo/client';
import { User } from '@quotalogic/gateway/types';
import { useTranslation } from 'next-i18next';
import { GET_USER } from './gql/query';
import ChangePassword from './ChangePassword';
import { Wrapper, Form } from './styles';
import { TextInput } from './AccountForm/TextInput';
import { AvatarInput } from './AccountForm/AvatarInput';

export const AccountForm = () => {
  const { t } = useTranslation('account');
  const { data, loading }: QueryResult<{ user: User }> = useQuery(GET_USER, { errorPolicy: 'all' });

  if (loading) return null;

  return (
    <Form>
      <Wrapper>
        <AvatarInput name={data?.user.name} alt={data?.user.name} src={data?.user.picture} />

        <TextInput name="name" label={t('settings.name')} value={data?.user.name} />
        <TextInput name="email" label={t('settings.email')} value={data?.user.email} disabled />
        <TextInput name="phone" label={t('settings.phone')} value={data?.user.phone} type="tel" />
        <ChangePassword />
      </Wrapper>
    </Form>
  );
};
