import { gql } from '@apollo/client';

export const GET_SCHEDULER = gql`
    query Scheduler($entityId: ID!) {
        scheduler(entityId: $entityId) {
            id
            status
            entity {
                id
            }
            workspaceId
            actionType
            scheduleType
            notifications
            schedule {
                time
                daysOfMonth
                daysOfWeek
                date
            }
            history {
                id
                startedAt
                finishedAt
                status
            }
            isDisabled
        }
    }
`;

export const GET_SCHEDULER_STATUS = gql`
    query SchedulerStatus ($entityId: ID!) {
        scheduler(entityId: $entityId) {
            id
            status
        }
    }
`;
