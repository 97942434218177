import { Money } from './types';
import { toString } from './formatter';

export const money = (amount: number | string): Money => {
  let value: bigint;
  try {
    value = BigInt(amount);
  } catch (error) {
    throw new Error('Invalid amount');
  }

  return {
    value,
    format: (hideNullishDecimal) => toString(value, hideNullishDecimal),
  };
};
