// eslint-disable-next-line import/no-extraneous-dependencies
import styled, { css } from 'styled-components';
import { Clock } from '@gravity-ui/icons';
import { SchedulerStatus } from '@quotalogic/gateway/types/Scheduler';

interface StatusProps {
    $status?: SchedulerStatus
}

interface DisabledProps {
  $isDisabled: boolean
}

const getColorStyle = (status?: SchedulerStatus) => {
  switch (status) {
    case 'PAUSED':
      return '#FEF7F1';

    case 'ACTIVE':
      return '#f5faf7';

    default:
      return '#F2F2F2';
  }
};

export const Layout = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-bottom: 16px;
`;

export const Wrapper = styled.div<StatusProps>`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 8px;
  gap: 12px;
  background: ${({ $status }) => getColorStyle($status)};
`;

export const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  & > span {
    padding-top: 2px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin-left: 8px;
  }
`;

export const SelectBlock = styled.div`
  display: flex;
  gap: 12px;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 4px;
  width: 50%;
`;

const fieldStyle = css`
  border-radius: 6px;
  border: 1px solid #E0E0E0;
  padding: 5px 8px 5px 8px;
  background: #FFFFFF;
  color: #333333;
`;

export const MenuLabel = styled.div<DisabledProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  ${fieldStyle};
  ${({ $isDisabled }) => $isDisabled && 'background: #f8fcfa'};
`;

export const Label = styled.span<DisabledProps>`
  font-weight: 700;
  line-height: 18px;
  margin-left: 4px;
  ${({ $isDisabled }) => $isDisabled && 'color: #8c908d;'};
`;

export const InputStyle = styled.input<DisabledProps>`
  ${fieldStyle};
  padding-left: 28px;
  ${({ $isDisabled }) => $isDisabled && 'background: #f8fcfa'};
  &:focus {
    outline: none;
  }
`;

export const NotificationStyle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 12px;
    height: 60px;
    border-radius: 8px;
    gap: 12px;
    background-color: #F2F2F2;
`;
