export const formatValue = (value: string | number, locale: string) => {
  const num = +value / 100;
  const currencyFractionDigits = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
  }).resolvedOptions().minimumFractionDigits;

  return num.toLocaleString(locale, {
    minimumFractionDigits: currencyFractionDigits,
  });
};

export const valueToNumber = (value: string): number => {
  const formattedValue = +value.replace(/\s+/g, '').replace(/,/g, '.').replace(/\u2212/g, '-');
  return +formattedValue.toFixed(2).replace(/\./g, '');
};
