import { useTranslation } from 'next-i18next';
import { CircleInfoFill } from '@gravity-ui/icons';
import { Container, StyleButton, Text } from './styles';

type Props = {
  isDisconnected: boolean
  handleDisconnect: () => void
}

export const DisconnectedAll = ({ isDisconnected, handleDisconnect }: Props) => {
  const { t } = useTranslation();

  if (isDisconnected) {
    return (
      <Container>
        <CircleInfoFill width={32} />
        <Text>{t('text.unlinkedProduct')}</Text>
      </Container>
    );
  }

  return (
    <StyleButton
      view="link"
      onClick={handleDisconnect}
      width="140px"
    >
      {t('button.unlinkALlSections')}
    </StyleButton>
  );
};
