import { gql } from '@apollo/client';

export const DISABLE_SECTION = gql`
  mutation DisableSection($id: ID!) {
    disableSection(id: $id)
  }
`;

export const INCLUDE_SECTION = gql`
    mutation IncludeSection($fromSectionId: ID!, $toSectionId: ID!) {
        includeSection(fromSectionId: $fromSectionId, toSectionId: $toSectionId) {
            id
            name
        }
    }
`;

export const COPY_SECTION = gql`
    mutation CopySection($sourceSectionId: ID!, $targetSectionId: ID!) {
        copySection(
            sourceSectionId: $sourceSectionId,
            targetSectionId: $targetSectionId
        ) {
            id
            name
        }
    }
`;
