import { map } from 'nanostores';
import { Entries } from 'type-fest';
import { ImportModeType } from '@quotalogic/gateway/types';

type Settings = {
  importMode: ImportModeType
  codeName: string
}

const DEFAULT_SETTINGS = {
  importMode: 'BASIC' as ImportModeType,
  codeName: 'name',
};

export const $reimportSettings = map<Settings>(DEFAULT_SETTINGS);

export const updateReimportSettings = (settings: Partial<Settings>) => {
  const entries = Object.entries(settings) as Entries<Settings>;
  entries.forEach(([key, value]) => {
    $reimportSettings.setKey(key, value);
  });
};

export const resetReimportSettings = () => {
  $reimportSettings.set({ ...DEFAULT_SETTINGS });
};
