import styled from 'styled-components';

export const Layout = styled.div<{ $depth?: number }>`
  display: flex;
  padding-left: ${({ $depth = 0 }) => 16 * $depth}px;
`;

export const Button = styled.button`
  all: unset;
  display: flex;
  width: 16px;
  cursor: pointer;
  transition: all 50ms ease-in-out;
  justify-content: center;
  align-items: center;
    
  > svg {
      fill: #999;
  }
`;

export const Name = styled.button`
  all: unset;
  cursor: pointer;
  padding-left: 4px;
`;
