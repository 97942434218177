import { Column, Modifier, ProductModifier as ProductModifierType } from '@quotalogic/gateway/types';

/**
 * Recursively returns list of parent columns
 * @param columns list of all columns
 * @param parent parent field id
 */
export const getParents = (columns: Record<string, Column>, parent?: string): Column[] => {
  if (!parent) return [];
  // return list of parent columns (sequentially)
  return [columns[parent], ...getParents(columns, columns[parent].parent)];
};

/**
 * Checks if value is modifier
 * @param value
 */
const isModifier = (value: number | string | Modifier): value is Modifier =>
  typeof value === 'object' && value.type !== null;

/**
 * Returns modifier as formatted string
 * @param modifier
 */
export const getModifierString = (modifier?: Modifier) => {
  if (modifier?.type && modifier.value !== null) {
    const result = modifier.value as number / 100;
    if (!Number.isFinite(result)) {
      return '∞';
    }
    return result + (modifier.type === 'PERCENT' ? '%' : '');
  }

  return '';
};

/**
 * Calculates value based on modifier
 * @param modifier product or column modifier
 * @param value value to apply modifier to
 */
export const applyModifier = (value: number, modifier?: Modifier | ProductModifierType): number => {
  if (modifier && typeof modifier.value === 'number') {
    switch (modifier.type) {
      case 'PERCENT':
        return Math.round(value + (value * (modifier.value / 10_000)));

      case 'EXACT':
        return modifier.value;

      default:
        return value + modifier.value;
    }
  }

  return 0;
};

/**
 * Calculates modifier based on value
 * @param value result field value
 * @param parentValue computed parent field value
 * @param type modifier type (to which type it should be calculated)
 */
export type ValueModifier = { value: number, type: 'PERCENT' | 'ABSOLUTE', string?: string }

const applyValue = (value: number, parentValue: number, type: 'PERCENT' | 'ABSOLUTE'): ValueModifier => {
  // if (type === 'PERCENT') {
  const difference = value - parentValue;
  const percentage = (difference / parentValue) * 100;

  return {
    value: +percentage.toFixed(2) * 100,
    type: 'PERCENT',
  };
  // }

  // if (type === 'ABSOLUTE') {
  //   return {
  //     value: value - parentValue,
  //     type: 'ABSOLUTE',
  //   };
  // }

  // return {
  //   value: 0,
  //   type: 'ABSOLUTE',
  // };
};

/**
 * Computes parent field value
 * @param parentColumns
 * @param fields
 */
export const computeParentValue = (parentColumns: Column[], fields: Record<string, number | string | Modifier>) =>
  parentColumns.reduce<number>((acc, column) => {
    // if no modifier -> get value
    if (!column.modifier) {
      acc = Number(fields[column.id] ?? 0);
    } else {
      acc = applyModifier(
        acc,
        isModifier(fields[column.id])
          // product modifier
          ? fields[column.id] as Modifier
          // column modifier
          : column.modifier,
      );
    }

    return acc;
  }, 0);

/**
 * Value reducer
 * @param state contains modifier, value and type
 * @param action contains type, modifierPayload, valuePayload and parentValue
 */
type ReducerState = {
  modifier?: ValueModifier & { isParent: boolean }
  value?: number
  type: 'PERCENT' | 'ABSOLUTE' | 'EXACT'
}

type ReducerAction = {
  parentValue: number
  type: 'UPDATE' | 'COLUMN_UPDATE' | 'PARENT_UPDATE'
  modifierPayload?: ValueModifier & { isParent?: boolean }
  modifierType?: 'PERCENT' | 'ABSOLUTE' | 'EXACT'
  valuePayload?: number
}

export const reducer = (state: ReducerState, action: ReducerAction): ReducerState => {
  switch (action.type) {
    case 'UPDATE':
      // if value dispatched
      if (action.valuePayload !== undefined) {
        return {
          modifier: {
            ...applyValue(action.valuePayload, action.parentValue, state.modifier?.type ?? 'ABSOLUTE'),
            isParent: false,
          },
          value: action.valuePayload,
          type: 'EXACT',
        };
      }

      // if modifier dispatched
      if (action.modifierPayload !== undefined) {
        return {
          modifier: {
            ...action.modifierPayload,
            isParent: false,
          },
          value: applyModifier(action.parentValue, action.modifierPayload),
          type: action.modifierPayload.type,
        };
      }
      break;

    case 'COLUMN_UPDATE':
      if (!action.modifierPayload) break;

      // if type is percent
      if (action.modifierPayload.type === 'PERCENT') {
        return {
          modifier: {
            ...action.modifierPayload,
            isParent: true,
          },
          value: applyModifier(action.parentValue, action.modifierPayload),
          type: 'PERCENT',
        };
      }

      return {
        modifier: {
          ...action.modifierPayload,
          isParent: true,
        },
        value: action.parentValue + action.modifierPayload.value,
        type: 'ABSOLUTE',
      };

    case 'PARENT_UPDATE':
      if (state.type === 'EXACT') {
        return {
          modifier: {
            ...applyValue(state.value ?? 0, action.parentValue, state.modifier?.type ?? 'ABSOLUTE'),
            isParent: state.modifier?.isParent ?? false,
          },
          value: state.value,
          type: 'EXACT',
        };
      }

      return {
        modifier: state.modifier,
        value: applyModifier(action.parentValue, state.modifier),
        type: state.type,
      };

    default:
      return state;
  }

  return state;
};

/**
 * Reducer initializer
 * @param productModifier product field modifier
 * @param columnModifier column field modifier
 * @param parentValue computed parent field value
 * @desc
 * 1. if parent price updated -> recalc price
 * 2. if modifier updated -> recalc price
 * 3. if column modifier updated and is no product modifier -> recalc price
 * 4. if product modifier reset -> replace modifier value with column modifier
 */
type InitializerProps = {
  productModifier?: Modifier
  columnModifier?: Modifier
  parentValue: number
}
export const initializer = ({ productModifier, columnModifier, parentValue }: InitializerProps): ReducerState => {
  if (productModifier && typeof productModifier.value === 'number') {
    if (productModifier.type === 'EXACT') {
      return {
        modifier: {
          ...applyValue(productModifier.value, parentValue, 'PERCENT'),
          isParent: false,
        },
        value: productModifier.value,
        type: productModifier.type,
      };
    }

    return {
      modifier: {
        value: productModifier.value,
        type: productModifier.type ?? 'ABSOLUTE',
        isParent: false,
      },
      value: applyModifier(parentValue, productModifier),
      type: productModifier.type ?? 'ABSOLUTE',
    };
  }

  // TODO: fix column type in gateway (no EXACT type)
  if (columnModifier && typeof columnModifier.value === 'number' && columnModifier.type !== 'EXACT') {
    return {
      modifier: {
        value: columnModifier.value,
        type: columnModifier.type ?? 'ABSOLUTE',
        isParent: true,
      },
      value: applyModifier(parentValue, columnModifier),
      type: columnModifier.type,
    };
  }

  return {
    modifier: {
      value: 0,
      type: 'ABSOLUTE',
      isParent: true,
    },
    value: applyModifier(parentValue),
    type: 'ABSOLUTE',
  };
};

/**
 * Parses modifier input string to object
 * @param value modifier input string
 * @returns modifier object
 */
export const parseModifierString = (value: string): ValueModifier => {
  const hasPercent = value.length > 0 && value.indexOf('%') === value.length - 1;
  const isNegative = value.indexOf('-') === 0;

  let result = value.replace(',', '.').replace(/[^\d.,]|(?<=([.,]))[.,]/g, '');

  if (isNegative) result = `-${result}`;

  // converting to cents
  // checking if result is number
  const int = parseFloat(result)
    ? +Number(result).toFixed(2).replace('.', '')
    : 0;

  // removing last char if NaN (.)
  // if (Number.isNaN(parseInt(result[result.length - 1], 10))) {
  //   result = result.slice(0, -1);
  // }

  // adding percent sign
  if (hasPercent) {
    result += '%';
  }

  return {
    value: int,
    type: hasPercent ? 'PERCENT' : 'ABSOLUTE',
    string: result,
  };
};
