import { styled } from 'styled-components';

export const Error = styled.div`
  display: flex;
  gap: 4px;
`;

export const Container = styled.div`
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-flow: column;
  row-gap: 8px;
`;
