import { useMemo, useState } from 'react';
import { Button, Drawer, Modal } from '@quotalogic/ui';
import { QueryResult, useQuery } from '@apollo/client';
import { Scheduler as SchedulerType, SchedulerActionType, SchedulerStatus } from '@quotalogic/gateway/types/Scheduler';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { DeleteButton, StatusIndicator } from '../Scheduler';
import type { SchedulerProps } from '../Scheduler';
import { GET_SCHEDULER_STATUS } from '../Scheduler/gql/query';
import { ReimportButton } from './ReimportButton';
import { ReimportSettings } from './ReimportSettings';

const Scheduler = dynamic<SchedulerProps>(
  () => import('../Scheduler').then((mod) => mod.Scheduler),
  { ssr: false },
);

interface Props {
  entityId: string
  entityName?: string
  title?: string
  type: SchedulerActionType
  // can be created
  importMode?: string
  codeName?: string
  catalogId: string
}

export const SchedulerButton = ({
  entityId,
  title,
  type,
  entityName,
  importMode,
  codeName,
  catalogId,
}: Props) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  // check schedulers for current entity
  const { data, refetch }: QueryResult<{ scheduler: SchedulerType }, { entityId: string }> = useQuery(
    GET_SCHEDULER_STATUS,
    {
      variables: { entityId },
      fetchPolicy: 'network-only',
    },
  );

  const currentStatus = useMemo(
    () => data?.scheduler?.status,
    [data],
  );

  const closeDrawer = (e?: React.MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    // SchedulerDetailsItemVar(null);
    // SchedulerEntityIdVar(undefined);
    // SchedulerTypeVar(undefined);
    // SchedulerIdVar(undefined);
    setIsVisible(false);
    refetch();
  };

  const getButtonColor = (status: SchedulerStatus | undefined) => {
    switch (status) {
      case 'ACTIVE':
        return 'green';
      case 'PAUSED':
        return 'yellow';
      case 'CLOSED':
      default:
        return 'dark';
    }
  };

  const schedulerIsActive = useMemo(() => Boolean(codeName && importMode), [codeName, importMode]);

  return (
    <>
      <Button
        view="ghost"
        type="button"
        size="small"
        colorTheme={getButtonColor(data?.scheduler?.status)}
        onClick={(event) => {
          event.stopPropagation();
          setIsVisible(true);
        }}
      >
        <StatusIndicator status={currentStatus} />
      </Button>

      {schedulerIsActive ? (
        <Drawer
          visible={isVisible}
          close={closeDrawer}
          controls={data?.scheduler && (
          <DeleteButton id={data?.scheduler.id} closeDrawer={closeDrawer} />
          )}
          title="Scheduler"
          isScrollable={false}
        >
          <Scheduler
            title={title}
            entityId={entityId}
            type={type}
            entityName={entityName}
          />
        </Drawer>
      ) : (
        <Modal
          isVisible={isVisible}
          onClose={() => setIsVisible(false)}
          title={t('scheduler.reimport.title')}
          footer={<ReimportButton profileId={entityId} />}
        >
          <ReimportSettings catalogId={catalogId} />
        </Modal>
      )}
    </>
  );
};
