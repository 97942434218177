import styled from 'styled-components';
import { BaseInput } from '@quotalogic/ui/base';

export const StyledLink = styled(BaseInput)<{ $size?: number, $stretched?: boolean }>`
  width: ${({ $size, $stretched }) => {
    if (!$stretched && !$size) {
      return 'auto';
    }

    if ($stretched) {
      return '100%';
    }

    return `${$size}px`;
  }};
`;

export const StyledLinkText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
`;
