import React, { FC } from 'react';
// import { useReactiveVar } from '@apollo/client';
import type { Scheduler as SchedulerType, EventHistory } from '@quotalogic/gateway/types';
import { SchedulerActionType, SchedulerStatus } from '@quotalogic/gateway/types/Scheduler';
// import { SchedulerDetailsItemVar } from '../SchedulerReactiveVar';
import { useQuery } from '@apollo/client';
import { Settings, History } from '../components';
import { GET_SCHEDULER } from '../gql/query';
// import { STATUS } from '../types';

export interface Props {
  title?: string
  type: SchedulerActionType
  entityId: string
  entityName?: string
}

export const SchedulerLayout: FC<Props> = ({ title, type, entityId, entityName }) => {
  /**
   * TODO: Uncomment this code when detailed will be implemented
   * const schedulerDetailsItem = useReactiveVar(SchedulerDetailsItemVar);
   *
   *   if (schedulerDetailsItem) {
   *     return (
   *       <Wrapper>
   *         <Details item={schedulerDetailsItem} />
   *       </Wrapper>
   *     );
   *   }
   */

  const { data } = useQuery<{ scheduler: SchedulerType }, { entityId: string }>(
    GET_SCHEDULER,
    {
      variables: { entityId },
      fetchPolicy: 'network-only',
    },
  );

  if (!data) return null;

  return (
    <Settings
      title={title}
      status={data?.scheduler?.status}
      event={data?.scheduler}
      type={type}
      entityId={entityId}
      entityName={entityName}
    >
      <History list={data?.scheduler?.history ?? []} />
    </Settings>
  );
};
