/* eslint-disable import/no-extraneous-dependencies */
import { gql } from '@apollo/client';

export const LOG_OUT = gql`
    mutation LogOut {
        logOut
    }
`;

export const SELECT_WORKSPACE = gql`
    mutation SelectWorkspace($id: ID!) {
        selectWorkspace(id: $id) {
            id
            name
            role
        }
    }
`;

export const CREATE_WORKSPACE = gql`
    mutation CreateWorkspace($name: String!, $currency: String!, $timeZone: String!) {
        createWorkspace(name: $name, currency: $currency, timeZone: $timeZone) {
            id
            name
            role
            currency
        }
    }
`;
