import { Workspace } from '@quotalogic/gateway/types';
import { Logo } from '@quotalogic/ui';
import { IconStyle, TextStyle, TriggerStyle } from './styles';

type Props = {
  workspace: Workspace
  onClick: () => void
}

export const WorkspaceButton = ({ workspace, onClick }: Props) => (
  <TriggerStyle onClick={onClick}>
    <Logo src={workspace.logo} alt={workspace.name} />
    <TextStyle>
      {workspace.name}
    </TextStyle>
    <IconStyle width={24} />
  </TriggerStyle>
);
