import styled from 'styled-components';
import { GlobalColors, Button } from '@quotalogic/ui';

export const StyleContainer = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  text-align: center;
  border-radius: 16px;
  background: ${GlobalColors.bg.main.secondary};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.04);
`;

export const Title = styled.h2`
  color: ${GlobalColors.text.primary};
  font-size: 1.429rem;
  font-weight: 700;
  line-height: 24px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  
  &:first-of-type {
    border-bottom: 1px solid ${GlobalColors.border.primary};
  }

  &:last-of-type {
    padding: 16px 0 0 0;
  }
`;

export const StyledTopText = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 18px;
  color: ${GlobalColors.text.secondary};
`;

export const StyledEmail = styled(StyledTopText)`
  font-weight: 700;
  color: ${GlobalColors.text.primary};
`;

export const StyledBottomText = styled.span`
  font-size: 0.857rem;
  font-weight: 500;
  line-height: 16px;
  color: ${GlobalColors.text.primary};
`;

export const StyledButton = styled(Button)`
  padding: 0 4px;
  color: ${GlobalColors.text.accent};
`;
