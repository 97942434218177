import React, { FormEvent, useState } from 'react';
import { Eye, EyeSlash, TriangleExclamation } from '@gravity-ui/icons';
import { FetchResult, useMutation } from '@apollo/client';
import { User } from '@quotalogic/gateway/types';
import { useTranslation } from 'next-i18next';
import { Button, Input } from '@quotalogic/ui';
import { CHANGE_PASSWORD } from './gql/mutation';
import { Container, Form, Error } from './styles';

type Props = {
  setVisible: (status: boolean) => void
}

export const ChangePasswordForm = ({ setVisible }: Props) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [changePassword, { error }] = useMutation(CHANGE_PASSWORD, { errorPolicy: 'all' });
  const [change, setChange] = useState('');

  const passHandleClick = () => setShowPass(!showPass);
  const newPassHandleClick = () => setShowNewPass(!showNewPass);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (password !== passwordConfirm) {
      setPasswordInvalid(true);
      return;
    }

    setPasswordInvalid(false);
    const { data }: FetchResult<{ changePassword: User }> = await changePassword({
      variables: {
        password,
      },
    });

    if (data?.changePassword) {
      setChange(data.changePassword.id);
      setTimeout(() => { setVisible(false); }, 1000);
    }
  };

  return (
    <Container>
      { change.length > 0
        ? <p style={{ padding: 24 }}>{t('text.youChangeYourPassword')}</p>
        : (
          <Form onSubmit={handleSubmit}>
            <Input
              type={showPass ? 'text' : 'password'}
              placeholder={t<string>('placeholder.password')}
              name="password"
              value={password}
              onChange={(val) => setPassword(val)}
            >
              <Button
                view="ghost"
                size="small"
                onClick={passHandleClick}
              >
                {showPass ? <Eye /> : <EyeSlash />}
              </Button>
            </Input>
            <Input
              type={showNewPass ? 'text' : 'password'}
              placeholder={t<string>('placeholder.repeatPassword')}
              name="passwordConfirm"
              value={passwordConfirm}
              onChange={(val) => setPasswordConfirm(val)}
            >
              <Button
                size="small"
                view="ghost"
                onClick={newPassHandleClick}
              >
                {showNewPass ? <Eye /> : <EyeSlash />}
              </Button>
            </Input>
            <p style={{ padding: '12px 0' }}>
              {t('text.usePassword')}
            </p>
            <Button type="submit">
              {t('button.changePassword')}
            </Button>
            {passwordInvalid
            && (
            <Error>
              <TriangleExclamation />
              {t('errors.passwordsNotMatch')}
            </Error>
            )}
            {error
            && (
            <Error>
              <TriangleExclamation />
              {error.message}
            </Error>
            )}
          </Form>
        )}
    </Container>
  );
};
