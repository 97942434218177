import { Catalog } from '@quotalogic/gateway/types';
import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { SectionTree } from '../SectionTree/SectionTree';
import { ButtonStyle, Catalogs, Layout, List, Sections, Text } from './styles';

type Props = {
  ids: string[]
  list: Catalog[]
  catalogId: string
  handleSectionClick: (id: string) => void
  handleCloseModal: () => void
}

export const ModalSelector = ({ ids, list, catalogId, handleSectionClick, handleCloseModal }: Props) => {
  const { t } = useTranslation();
  const [activeCatalogId, setActiveCatalogId] = useState(() => {
    if (ids.includes(catalogId)) return catalogId;
    return ids[0];
  });

  if (activeCatalogId) {
    return (
      <Layout>
        <Catalogs>
          <List>
            {list?.map((catalog) => (
              <li key={catalog.id}>
                <ButtonStyle
                  type="button"
                  $isActive={activeCatalogId === catalog.id}
                  onClick={() => setActiveCatalogId(catalog.id)}
                >
                  {catalog.name}
                </ButtonStyle>
              </li>
            ))}
            {!list && (
              <li>
                {t('catalog.empty.noCatalogs')}
              </li>
            )}
          </List>
        </Catalogs>
        <Sections>
          <SectionTree
            activeCatalogId={activeCatalogId}
            handleSectionClick={handleSectionClick}
            handleCloseModal={handleCloseModal}
          />
        </Sections>
      </Layout>
    );
  }

  return (<Text>{t('catalog.empty.noCatalogs')}</Text>);
};
