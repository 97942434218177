import styled, { css } from 'styled-components';

interface WidthProps {
  $width?: string
}

interface ViewProps {
  $view: 'primary' | 'secondary'
}

export const Manager = styled.div<WidthProps & ViewProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  column-gap: 16px;
  background: #E5E4FB;
  padding: 16px;
  border-radius: 16px;
  color: var(--public-text-secondary);
  max-width: ${({ $width }) => ($width || '100%')};
  grid-area: manager;
  
  ${({ $view }) => {
    switch ($view) {
      case 'secondary':
        return css`
          background-color: transparent;
        `;

      case 'primary':
      default:
        return css`
          background-color: var(--public-bg-element-blue);
        `;
    }
  }}
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr;
  gap: 12px;
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Position = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: var(--public-text-ghost);
`;

export const Name = styled.p`
  font-size: 1.431rem;
  font-weight: 700;
  line-height: normal;
`;

export const Picture = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #f0f0f0;
  overflow: hidden;
  border: 1.5px solid var(--public-bg-white);
`;

export const Image = styled.img`
  object-fit: fill;
`;

export const Contacts = styled.div<ViewProps>`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 2px;

  ${({ $view }) => {
    switch ($view) {
      case 'secondary':
        return css`
          padding-left: 58px;
        `;

      case 'primary':
      default:
        return css`
          padding: 0;
        `;
    }
  }}
`;

export const Link = styled.a<{ $border?: boolean }>`
  font-size: 1rem;
  font-weight: 500;
  text-decoration: ${({ $border = false }) => ($border ? 'underline' : 'none')};
`;

export const ContactsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
`;

export const ChatButton = styled.button`
  all: unset;
  display: flex;
  width: 42px;
  height: 42px;
  border-radius: 24px;
  background: var(--public-bg-element-blue);
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    color: var(--public-icon-color);
  }
  
  &:hover {
    background: var(--public-button-secondary-blue-click);
  }
`;
