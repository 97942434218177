import styled from 'styled-components';

export const InputStyle = styled.input<{ $align?: 'left' | 'right' }>`
  width: 100%;
  cursor: text;
  padding: 0;
  margin: 0;
  outline: none;
  border: 0;
  background: transparent;
  font-variant-numeric: tabular-nums;
  text-align: ${({ $align = 'left' }) => $align};
`;
