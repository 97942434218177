import React, { FC, useMemo } from 'react';
import { InviteEntity } from '@quotalogic/gateway/types';
import { useTranslation } from 'next-i18next';
import { FetchResult, useMutation } from '@apollo/client';
import { Xmark, Calendar, CircleFill } from '@gravity-ui/icons';
import { STATUS_TYPES } from './types';
import {
  Item,
  StyleDate,
  StatusContainer,
  Status,
  StyledEmail,
  DateContainer,
  StyledDetails,
  StyledButton,
} from './styles';
import { DELETE_INVITE, GET_INVITER_INVITES } from './gql';

export const InviteDetails: FC<InviteEntity> = ({ id, status, email, createdAt }) => {
  const { t: translation } = useTranslation();

  const date = useMemo(() => {
    try {
      const D = new Date(createdAt);
      return new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }).format(D).replaceAll('/', '.');
    } catch (e: unknown) {
      return null;
    }
  }, [createdAt]);

  const [deleteInvite] = useMutation(DELETE_INVITE);

  const deleteItem = async () => {
    await deleteInvite({
      variables: {
        id,
      },
      refetchQueries: [{
        query: GET_INVITER_INVITES,
        fetchPolicy: 'network-only',
      }],
    });
  };

  return (
    <Item>
      <StatusContainer>
        <StyledDetails>
          <StyledEmail>{email}</StyledEmail>
          <DateContainer>
            <Calendar color="#0D2D5E8F" />
            <StyleDate>{date}</StyleDate>
          </DateContainer>
        </StyledDetails>
        <StyledButton size="small" view="ghost" onClick={deleteItem}>
          <Xmark color="#0D2D5E8F" />
        </StyledButton>
      </StatusContainer>
      <Status $status={status}>
        <CircleFill width="6px" />
        {status === STATUS_TYPES.created
              && translation('inviterInvites.statuses.invited')}
        {status === STATUS_TYPES.declined
              && translation('inviterInvites.statuses.declined')}
        {status === STATUS_TYPES.accepted
                && translation('inviterInvites.statuses.accepted')}
      </Status>
    </Item>
  );
};
