import { Money } from './types';
import { toString } from './formatter';

/**
 * Returns the addition of two money values.
 *
 * @param a The first money value.
 * @param b The second money value.
 *
 * @example
 * add(money(100), money(50)).format() -> 150.00
 */
export const add = (a: Money, b: Money): Money => {
  const value = a.value + b.value;

  return {
    value,
    format: () => toString(value),
  };
};
