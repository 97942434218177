import { FC, useMemo, useState } from 'react';
import { InviteEntity } from '@quotalogic/gateway/types';
import { Button } from '@quotalogic/ui';
import { useTranslation } from 'next-i18next';
import { useMutation } from '@apollo/client';
import { Icon, StyledControls, StyledInvite, StyledText } from './styles';
import { ACCEPT_INVITE, DECLINE_INVITE, GET_INVITES, GET_WORKSPACE } from './query';
import { activateWorkspace } from '../../Header/WorkspaceBlock/channel';

interface Props {
  invite: InviteEntity
}

export const InviteeInvite: FC<Props> = ({ invite }) => {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);
  const [acceptInvite] = useMutation(ACCEPT_INVITE, { variables: { inviteId: invite.id } });
  const [declineInvite] = useMutation(DECLINE_INVITE, { variables: { inviteId: invite.id } });
  const title = useMemo(() => `${t('invite.to')} «${invite.workspace?.name}»`, [invite.workspace?.name, t]);

  const processInvite = async (accept: boolean) => {
    setIsDisabled(true);
    if (accept) {
      await acceptInvite({
        refetchQueries: [
          { query: GET_INVITES, fetchPolicy: 'network-only' },
          { query: GET_WORKSPACE, fetchPolicy: 'network-only' },
        ],
      });
      activateWorkspace();
    } else {
      await declineInvite({
        refetchQueries: [{ query: GET_INVITES, fetchPolicy: 'network-only' }],
      });
    }
  };

  return (
    <StyledInvite>
      <Icon />
      <StyledText>
        {title}
      </StyledText>
      <StyledControls>
        <Button disabled={isDisabled} size="small" onClick={() => processInvite(true)}>
          {t('invite.accept')}
        </Button>
        <Button disabled={isDisabled} view="ghost" size="small" onClick={() => processInvite(false)}>
          {t('invite.reject')}
        </Button>
      </StyledControls>
    </StyledInvite>
  );
};
