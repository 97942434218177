import { Modal } from '@quotalogic/ui';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { WorkspaceForm } from '../../Header/forms/WorkspaceForm';

type Props = {
  defaultCurrency: string
  isVisible: boolean
  onClose: () => void
}

export const CreateWorkspaceForm = ({ defaultCurrency, isVisible, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal isVisible={isVisible} onClose={onClose} width="460px" title={t<string>('button.createWorkspace')}>
      <WorkspaceForm defaultCurrency={defaultCurrency} />
    </Modal>
  );
};
