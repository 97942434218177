import styled from 'styled-components';
import { GlobalColors } from '@quotalogic/ui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const List = styled.ul`
  margin: 0;
  list-style: none;
  gap: 12px;
  width: 330px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${GlobalColors.border.secondary};
  padding: 0 0 8px 12px;
`;

export const Title = styled.h2`
  font-size: 1rem;
  font-weight: 700;
  line-height: 18px;
  margin: 0;
  color: ${GlobalColors.text.primary};
`;

export const SwitchBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Label = styled.span`
  font-size: 0.857rem;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: ${GlobalColors.text.secondary};
  cursor: pointer;
`;

export const EmptyInvite = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 80px;
  text-align: center;
  color: ${GlobalColors.text.ghost};
`;
