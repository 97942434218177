import styled, { css } from 'styled-components';
import { Button, Picture } from '@quotalogic/ui';

export const StyledList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  list-style-type: none;
`;

export const ButtonUpdateFile = styled.label`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  width: 64px;
  height: 64px;
  border: 1px dashed #ccc;
  border-radius: 12px;
  overflow: hidden;
`;

export const FileInput = styled.input`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 100%;
`;

export const StyledImage = styled(Picture)`
  width: 64px;
  height: 64px;
  border-radius: 12px;
  border: 2px red;
`;

export const ImageSelector = styled.button<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  width: 64px;
  height: 64px;
  border-radius: 12px;
  overflow: hidden;
  
  ${({ $isActive }) => $isActive && css`
    box-shadow: 0 0 0 2px royalblue;
  `}
`;

export const StyledListItem = styled.li`
  position: relative;
  
  &:hover button {
    opacity: 1;
  }
`;

export const ButtonRemoveImage = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  margin-top: -8px;
  margin-right: -8px;
  z-index: 1;
`;
