import React, { FC } from 'react';
import { Avatar } from '@quotalogic/ui';
import {
  StyledCard,
  StyledContact,
  StyledImage,
  StyledInfo,
  StyledName,
  StyledPosition,
  StyledComment,
  Container,
  Wrap,
  CommentContainer,
} from './styles';

interface Props {
  id: string
  name?: string
  image?: string
  position?: string
  phone?: string
  email?: string
  comment?: string
  onClick: (id: string) => void
}
export const EmployeeCard: FC<Props> = ({
  id,
  name,
  image,
  position,
  phone,
  email,
  comment,
  onClick,
}) => (
  <StyledCard role="button" onClick={() => onClick(id)}>
    <Container>
      <StyledImage>
        <Avatar
          src={image}
          name={name}
          type="pic"
        />
      </StyledImage>
      <StyledInfo>
        {name && <StyledName>{name}</StyledName>}
        {position && <StyledPosition>{position}</StyledPosition>}
      </StyledInfo>
    </Container>
    <StyledInfo>
      {email && <StyledContact href={`mailto:${email}`} onClick={(e) => e.stopPropagation()}>{email}</StyledContact>}
      {phone && <StyledContact href={`tel:${phone}`} onClick={(e) => e.stopPropagation()}>{phone}</StyledContact>}
    </StyledInfo>
    <Wrap>
      {comment && (
        <CommentContainer>
          <StyledComment>
            {comment}
          </StyledComment>
        </CommentContainer>
      )}
    </Wrap>
  </StyledCard>
);
