import { Workspace } from '@quotalogic/gateway/types';
import { Logo, Spinner } from '@quotalogic/ui';
import { useMutation } from '@apollo/client';
import Router from 'next/router';
import { IconStyle, MenuItem, NoteStyle, StyledList, TextStyle } from './styles';
import { SELECT_WORKSPACE } from './gql';
import { GET_WORKSPACE, GET_WORKSPACE_LIST } from '../WorkspaceMenu/gql';

type Props = {
  loading: boolean
  list?: Workspace[]
}

export const WorkspaceList = ({ loading, list }: Props) => {
  const [selectWorkspace] = useMutation(SELECT_WORKSPACE);

  const handleSelectWorkspace = async (workspaceId: string) => {
    await selectWorkspace({
      variables: { id: workspaceId },
      refetchQueries: [
        { query: GET_WORKSPACE },
        { query: GET_WORKSPACE_LIST },
      ],
    });
    // TODO: send event through intercom and refetch all data
    await Router.push('/');
  };

  if (loading) {
    return (
      <div>
        <Spinner size={24} />
      </div>
    );
  }

  return (
    <StyledList>
      {list?.map((workspace) => (
        <MenuItem key={workspace.id} $hasNote={!!workspace.note} onClick={() => handleSelectWorkspace(workspace.id)}>
          <Logo alt={workspace.name} src={workspace.logo} size={32} />
          <TextStyle>
            {workspace.name}
            <NoteStyle>
              {workspace.note}
            </NoteStyle>
          </TextStyle>
          {workspace.isActive && <IconStyle />}
        </MenuItem>
      ))}
    </StyledList>
  );
};
