import { useQuery } from '@apollo/client';
import { Product, Workspace } from '@quotalogic/gateway/types';
import { useMemo } from 'react';
import { GET_WORKSPACE_WITH_CATALOGS, GET_PRODUCT } from './gql';
import { Container } from './styles';
import { SectionTree } from './SectionTree/SectionTree';
import { ProductCard } from './ProductCard';

interface Props {
  handleCloseModal: () => void
  parentCatalogId?: string
  productId: string
  sectionId?: string
}

export const ProductConnectFrom = ({
  parentCatalogId,
  handleCloseModal,
  productId,
  sectionId,
}: Props) => {
  const { data } = useQuery<{ workspace: Workspace }>(GET_WORKSPACE_WITH_CATALOGS);

  const { data: productData } = useQuery<{ product: Product }, { id: string }>(
    GET_PRODUCT,
    {
      variables: {
        id: productId,
      },
    },
  );

  const [list, ids] = useMemo(() => {
    if (data?.workspace?.catalogs) {
      const items = data.workspace.catalogs.filter((item) => !item.parent);
      return [
        items,
        items.map(({ id }) => id),
      ];
    }
    return [null, null];
  }, [data?.workspace?.catalogs]);

  if (!data) return null;
  if (!productData) return null;

  if (parentCatalogId) {
    return (
      <Container>
        <ProductCard
          productName={productData.product.name}
          productImages={productData.product.images ?? []}
        />
        {data && list && list.length > 0 && ids
          && (
            <SectionTree
              activeCatalogId={parentCatalogId}
              handleCloseModal={handleCloseModal}
              sectionIds={productData.product.originIds}
              productId={productId}
              sectionId={sectionId}
            />
          )}
      </Container>
    );
  }

  return null;
};
