/* eslint-disable import/no-extraneous-dependencies */
import { gql } from '@apollo/client';

export const CREATE_SCHEDULER = gql`
    mutation CreateScheduler($data: CreateSchedulerInput!) {
        createScheduler(data: $data) {
            id
            entity {
                id
            }
            actionType
            scheduleType
            notifications
            status
            schedule {
                time
                daysOfMonth
                daysOfWeek
                date
            }
            history {
                id
                startedAt
                finishedAt
                status
            }
        }
    }
`;

export const UPDATE_SCHEDULER = gql`
    mutation UpdateScheduler($id: ID!, $data: UpdateSchedulerInput!) {
        updateScheduler(id: $id, data: $data) {
            id
            status
            scheduleType
            schedule {
                time
                daysOfMonth
                daysOfWeek
                date
            }
            isDisabled
        }
    }
`;

export const DELETE_SCHEDULER = gql`
    mutation DeleteScheduler($id: ID!) {
        deleteScheduler(id: $id)
    }
`;

export const UPDATE_SCHEDULER_STATUS = gql`
    mutation UpdateSchedulerStatus($id: ID!, $status: SchedulerStatus!) {
        updateSchedulerStatus(id: $id, status: $status) {
            id
            status
        }
    }
`;

export const RESTORE_SCHEDULER = gql`
    mutation RestoreScheduler($id: ID!, $data: UpdateSchedulerInput!) {
        restoreScheduler(id: $id, data: $data) {
            id
            status
        }
    }
`;
