import styled from 'styled-components';
import { GlobalColors } from '@quotalogic/ui';

export const Separator = styled.hr`
  all: unset;
  display: block;
  width: 100%;
  height: 1px;
  margin: 4px 0;
  background: ${GlobalColors.bg.darkBlue.ghostHover};
`;

export const NameStyle = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin-top: 6px;
    
  &:hover { 
    cursor: pointer;
    color: ${GlobalColors.text.accentHover};
  }
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 32px;
  border-radius: 8px;
  padding: 4px 8px;
  color: ${GlobalColors.text.primary};
  cursor: pointer;
  
  &:focus-visible { 
    outline: 2px solid ${GlobalColors.border.focus};
  }

  &:hover {
    background: ${GlobalColors.bg.darkBlue.disable};
  }
`;

export const StyledButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  height: 32px;
  width: 100%;
  box-sizing: border-box;
  justify-content: flex-start;
  border-radius: 8px;
  cursor: pointer;
  padding: 4px 8px;
  color: ${GlobalColors.text.primary};

  &:focus-visible {
    outline: 2px solid ${GlobalColors.border.focus};
  }

  &:hover {
      background: ${GlobalColors.bg.darkBlue.disable};
  }
`;
