import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const List = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 1.25em;
`;

export const ListItem = styled.li`
  line-height: 1.5;
`;

export const SmallText = styled.p`
  font-size: 12px;
  color: #828282;
`;

export const Footer = styled.footer`
  display: flex;
  gap: 8px;
  padding-top: 16px;
`;

export const Content = styled.div`
  padding: 8px 24px 0 52px;
`;
