import styled from 'styled-components';
import { GlobalColors } from '@quotalogic/ui';

export const Layout = styled.div<{ $depth?: number, $isConnect: boolean }>`
  display: flex;
  margin-left: ${({ $depth = 0 }) => 16 * $depth}px;
  padding: 0 8px;
  background: ${({ $isConnect }) => ($isConnect ? `${GlobalColors.bg.darkBlue.ghostHover}` : 'transparent')};
  min-height: 32px;
  border-radius: 6px;
  align-items: center;
`;

export const Button = styled.button`
  all: unset;
  display: flex;
  width: 16px;
  cursor: pointer;
  transition: all 50ms ease-in-out;
  justify-content: center;
  align-items: center;
    
  > svg {
      fill: #999;
  }
`;

export const Name = styled.button`
  all: unset;
  cursor: pointer;
  padding-left: 4px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  flex: 1 0 0;
  overflow: hidden;
  color: ${GlobalColors.text.secondary};
  text-overflow: ellipsis;
  font-weight: 500;
  line-height: 16px;
  min-height: 24px;
`;
