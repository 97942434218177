import React, { useRef, useState } from 'react';
import { FetchResult, useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import { Drawer, Button, Popup, PopupRef } from '@quotalogic/ui';
import { Person, ArrowRightToSquare } from '@gravity-ui/icons';
import { LOG_OUT } from '../gql/mutation';
import { AccountForm } from '../../AccountForms/Settings';
import { UserButton } from './UserButton';
import { DeleteUserAction } from './DeleteUserAction';

export const UserBlock = () => {
  const { t } = useTranslation('account');

  const [isVisible, setVisible] = useState(false);
  const [logOut, { loading }] = useMutation(LOG_OUT);

  const logOutHandler = async () => {
    const { data }: FetchResult<{ logOut: boolean }> = await logOut();
    if (data?.logOut) {
      window && window.location.reload();
    }
  };

  const ref = useRef<PopupRef>(null);

  const showUserDrawer = () => {
    ref.current?.onClose();
    setVisible(true);
  };

  return (
    <>
      <Popup
        placement="bottom-end"
        allowedPlacements={['bottom-end']}
        lazy
        withPortal={false}
        trigger={(
          <Button view="ghost">
            <Person width={16} height={16} />
          </Button>
        )}
        ref={ref}
        width={240}
      >
        <UserButton showUserDrawer={showUserDrawer} />
        <Button
          width="100%"
          view="ghost"
          iconLeft={<ArrowRightToSquare />}
          onClick={() => logOutHandler()}
          loading={loading}
          disabled={loading}
        >
          <span>{t('button.logOut')}</span>
        </Button>
      </Popup>
      <Drawer
        visible={isVisible}
        controls={(
          <DeleteUserAction />
        )}
        close={() => setVisible(false)}
        title={t('settings.title')}
      >
        <AccountForm />
      </Drawer>
    </>
  );
};
