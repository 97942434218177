import {
  DayOfWeek,
  Scheduler, SchedulerNotificationStatus,
  ScheduleType,
  UpdateSchedulerInput,
} from '@quotalogic/gateway/types/Scheduler';

const getCurrentDayOfWeek = (): DayOfWeek[] => {
  const days: DayOfWeek[] = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  const currentDay = days.find((day, index) => index + 1 === new Date().getDay());
  return currentDay ? [currentDay] : [];
};

export type SchedulerState = {
  type: ScheduleType
  daysOfWeek?: DayOfWeek[]
  daysOfMonth?: number[]
  time?: string
  date?: Date
  notifications?: SchedulerNotificationStatus
}
export const initialState: SchedulerState = {
  type: 'ONCE',
  time: '00:00',
  daysOfWeek: getCurrentDayOfWeek(),
  date: new Date(),
  notifications: 'LAUNCH_FAILED',
};

export type SchedulerAction = {
  action: 'SET'
  value: SchedulerState
  key: undefined
} | {
  action: 'UPDATE'
  value: ScheduleType | DayOfWeek[] | number[] | string | Date | undefined
  key: keyof SchedulerState
}

export const reducer = (state: SchedulerState, { action, value, key }: SchedulerAction): SchedulerState => {
  switch (action) {
    case 'UPDATE':
      return {
        ...state,
        [key]: value,
      };

    case 'SET':
      return {
        ...initialState,
        ...value,
      };

    default:
      return state;
  }
};

export const initializer = (event: Scheduler | undefined): SchedulerState => {
  if (!event) {
    return initialState;
  }

  const { scheduleType, schedule, notifications } = event;
  const { time, date, daysOfWeek, daysOfMonth } = schedule;

  return {
    type: scheduleType,
    time,
    date: date ? new Date(date) : undefined,
    daysOfWeek,
    daysOfMonth,
    notifications,
  };
};

export const getDateIsoString = (date?: Date) => {
  if (!date) return undefined;
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
};

export const getSchedulerInput = (state: SchedulerState): UpdateSchedulerInput => {
  const {
    type,
    time,
    date,
    daysOfWeek,
    daysOfMonth,
    notifications,
  } = state;

  return {
    scheduleType: type,
    time: time ?? '00:00',
    daysOfWeek: type === 'WEEKLY' ? daysOfWeek : undefined,
    daysOfMonth: type === 'MONTHLY' ? daysOfMonth : undefined,
    date: type === 'ONCE' ? getDateIsoString(date) : undefined,
    notifications: notifications ?? 'LAUNCH_FAILED',
  };
};
