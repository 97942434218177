import styled from 'styled-components';
import { ProductPrice } from '../ProductPrice';
import { NumberInput } from './NumberInput';

export const LayoutStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 4px;
`;

export const ModifierInputStyle = styled.input<{ $fade: boolean }>`
  all: unset;
  width: 100%;
  cursor: text;
  font-variant-numeric: tabular-nums;
  ${({ $fade }) => $fade && 'color: #888888;'}
`;

export const PriceInputStyle = styled(ProductPrice)<{ $fade: boolean }>`
  width: 100%;
  text-align: right;
  ${({ $fade }) => $fade && 'color: #888888;'}
`;

export const NumberInputStyle = styled(NumberInput)<{ $fade: boolean }>`
  ${({ $fade }) => $fade && 'color: #888888;'}
`;
