import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Controls = styled.div`
  display: grid;
  grid-template-columns: 1fr 140px;
  justify-content: space-between;
  width: 100%;
  height: 42px;
  gap: 16px;
`;

export const List = styled.div`
  max-height: calc(90vh - 240px);
  overflow: auto;
`;
