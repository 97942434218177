import { ReactNode, useCallback, useMemo } from 'react';
import { Xmark, CircleInfo, TrashBin, CircleCheck, CircleXmark, CircleExclamation } from '@gravity-ui/icons';
import { createPortal } from 'react-dom';
import { Button } from '../../Button';
import { GlobalColors } from '../../GlobalStyle';
import { TitleStyle, FooterStyle, ContentStyle } from './styles';
import { DialogBackdrop } from '../DialogBackdrop';
import { DialogHeaderStyle, DialogStyle } from '../styles';

type Props = {
  viewStyle?: 'info' | 'warning' | 'error' | 'success' | 'delete'
  title?: string
  text?: string
  icon?: ReactNode
  children?: ReactNode
  onClose: () => void
  onSubmit?: () => void
  submitText: string
  cancelText: string
  isLoading?: boolean
  isDisabled?: boolean
  isVisible: boolean
}

export const Alert = ({
  viewStyle = 'info',
  title,
  text,
  icon,
  children,
  onClose,
  onSubmit,
  submitText,
  cancelText,
  isLoading,
  isDisabled,
  isVisible,
}: Props) => {
  const handleSubmit = useCallback(() => {
    onSubmit && onSubmit();
  }, [onSubmit]);

  const iconElement = useMemo(() => {
    switch (viewStyle) {
      case 'warning':
        return <CircleExclamation width={20} height={20} color={GlobalColors.icon.danger} />;
      case 'error':
        return <CircleXmark width={20} height={20} color={GlobalColors.icon.error} />;
      case 'success':
        return <CircleCheck width={20} height={20} color={GlobalColors.icon.success} />;
      case 'delete':
        return <TrashBin width={20} height={20} color={GlobalColors.icon.error} />;
      case 'info':
      default:
        return <CircleInfo width={20} height={20} color={GlobalColors.icon.secondary} />;
    }
  }, [viewStyle]);

  if (!isVisible) return null;

  return createPortal(
    <DialogBackdrop onClose={onClose} centred={false}>
      <DialogStyle $width={400} onClick={(e) => e.stopPropagation()} onMouseUp={(e) => e.stopPropagation()}>
        <DialogHeaderStyle>
          <TitleStyle>
            <span>
              {icon || iconElement}
            </span>
            {title}
          </TitleStyle>
          <Button view="ghost" onClick={onClose}>
            <Xmark width={16} height={16} color={GlobalColors.icon.disable} />
          </Button>
        </DialogHeaderStyle>
        {text && <ContentStyle>{text}</ContentStyle>}
        {children}
        <FooterStyle>
          <Button view="secondary" onClick={onClose} autoFocus>
            {cancelText}
          </Button>
          <Button
            view="primary"
            onClick={handleSubmit}
            loading={isLoading}
            disabled={isDisabled}
            colorTheme={viewStyle === 'delete' ? 'red' : 'dark'}
          >
            {submitText}
          </Button>
        </FooterStyle>
      </DialogStyle>
    </DialogBackdrop>,
    document.body,
  );
};
