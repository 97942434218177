import { gql } from '@apollo/client';

export const SELECT_WORKSPACE = gql`
    mutation SelectWorkspace($id: ID!) {
        selectWorkspace(id: $id) {
            id
            name
            role
        }
    }
`;