import React, { FC, useCallback, useMemo } from 'react';
import { DayOfWeek, SchedulerStatus } from '@quotalogic/gateway/types/Scheduler';
import { Wrapper, DayItem } from './styles';

const week: DayOfWeek[] = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

interface Props {
  status?: SchedulerStatus
  days: DayOfWeek[]
  onDayChange: (daysOfWeek: DayOfWeek[]) => void
}

export const WeekDaysPicker: FC<Props> = ({ status, days, onDayChange }) => {
  const isActive = useMemo(() => status === 'ACTIVE', [status]);

  // TODO: rewrite to reducer to simpler time complexity
  const selectDayHandler = useCallback((day: DayOfWeek) => {
    if (days.includes(day)) {
      onDayChange(days.filter((el) => el !== day));
    } else onDayChange([...days, day]);
  }, [days, onDayChange]);

  return (
    <Wrapper>
      {week.map((day) => (
        <DayItem
          key={`day_${day}`}
          onClick={() => { if (!isActive) selectDayHandler(day); }}
          $isSelected={days.includes(day)}
          $isDisabled={isActive}
        >
          {day.charAt(0) + day.slice(1).toLowerCase()}
        </DayItem>
      ))}
    </Wrapper>
  );
};
