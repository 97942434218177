import styled from 'styled-components';

export const Group = styled.li`
  border-radius: 16px;
  background-color: var(--public-bg-element-blue);
  padding: 24px;

  @media (max-width: 768px) {
    padding: 24px 12px;
  }
`;

export const HeaderGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  gap: 16px;


  @media (max-width: 768px) {
    padding: 0 12px;
  }
`;

export const Title = styled.h2`
  font-size: 2.143rem;
  font-weight: 700;
  line-height: normal;
  color: var(--public-text-primary);

  @media (max-width: 768px) {
    font-size: 1.714rem;
  }
`;

export const Comment = styled.p`
  font-size: 1.143rem;
  font-weight: 400;
  line-height: normal;
  color: var(--public-text-secondary);

  @media (max-width: 768px) {
    font-size: 0.857rem;
  }
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 0;

  @media (max-width: 768px) {
    padding: 24px 12px 0 12px;
  }
`;

export const FooterTitle = styled.span`
  font-size: 1.714rem;
  color: var(--public-text-primary);
  font-weight: 700;
  line-height: 2.143rem;
  padding: 0 24px;
  
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileFooterTitle = styled(FooterTitle)`
  display: none;
  font-size: 0.857rem;
  font-weight: 500;
  line-height: normal;
  padding: 0;

  @media (max-width: 768px) {
   display: block; 
  }
`;
