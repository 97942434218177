import styled from 'styled-components';
import { GlobalColors } from '@quotalogic/ui';

export const StyledCard = styled.article<{ $isChecked?: boolean }>`
  display: flex;
  gap: 8px;
  border-radius: 16px;
  padding: 8px;
  width: 100%;
  align-items: center;
  background-color: ${({ $isChecked = false }) => ($isChecked ? GlobalColors.bg.main.primary : 'transparent')};
  
  &:hover {
    background-color: ${GlobalColors.bg.main.primary};
  }
  
  &:hover .card-checkmark,
  &:hover .card-disconnect,
  &:hover .card-connect {
    opacity: 1;
  }
`;

export const DeliveryLayout = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
`;

export const StyledImage = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
`;

export const StyledStub = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 12px;
  overflow: hidden;
  background: var(--main-gray-200);
`;

export const StyledTitle = styled.h3`
  font-weight: 600;
  font-size: 1rem;
  flex: 1;
  display: flex;
  align-items: center;
`;

export const StyledActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
