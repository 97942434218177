import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    gap: 32px;
    margin-bottom: 8px;
    padding-top: 36px;
    border-top: 1px solid rgba(13, 45, 94, 0.08);
    div:last-child {
        border: none;
        padding-bottom: 0;
    }

    @media(max-width: 524px) {
        gap: 24px;
        padding-top: 24px;
    }
`;

export const separatorStyle = css`
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(13, 45, 94, 0.08);

    @media(max-width: 524px) {
        padding-bottom: 24px;
    }
`;

export const Images = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    ${separatorStyle};
`;

export const TextBlock = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 32px;
    color:  rgba(37, 52, 71, 0.96);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    :last-child {
        border: none;
        padding-bottom: 0;
    }

    @media(max-width: 524px) {
        gap: 24px;
    }
`;

export const blockStyles = css`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 19px;
  gap: 12px;
`;

export const Block = styled.div`
  width: 100%;
  max-width: 199px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media(max-width: 524px) {
     gap: 4px;
     max-width: 180px;
  }

  @media(max-width: 321px) {
     max-width: 300px;
  }
`;

export const BlockItem = styled.div`
    position: relative;
    ${blockStyles};
    ${separatorStyle};
`;

export const Title = styled.span`
    overflow: hidden;
    color:  #222934;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
`;

export const ValueRichTextStyle = styled.div<{ $isFull: boolean }>`
    ${({ $isFull }) => (!$isFull && 'max-height: 172px;')};
    overflow: hidden;

    > p + p {
        margin-top: 16px;
    }

    ul, ol {
        margin: 16px 0;
        padding-left: 40px
    }

    h1 {
        font-size: 2rem;
        margin: 16px 0 8px 0;
    }

    h2 {
        font-size: 1.714rem;
        margin: 16px 0 8px 0;
    }

    h3 {
        font-size: 1.143rem;
        margin: 16px 0 8px 0;
    }

    @media (max-width: 768px) {
        padding: 0 12px;
    };
`;

export const MoreStyle = styled.button`
    display: flex;
    max-width: 70px;
    height: 24px;
    padding: 0 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: rgba(13, 45, 94, 0.80);
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    border-radius: 6px;
    background: rgba(13, 45, 94, 0.16);
`;

export const GradientStyle = styled.div<{ $isFull: boolean }>`
    display: none;

    ${({ $isFull }) => (!$isFull && css`
    display: block;
    position: absolute;
    width: 100%;
    bottom: 60px;
    height: 40px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
  `)};
`;
