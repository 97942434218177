import { FC } from 'react';
import { useTranslation } from 'next-i18next';

type ModalType = 'MOVE' | 'COPY' | 'DUPLICATE' | 'ADD' | null

interface Props {
  name?: string
  modalType: ModalType
}

export const ModalTitle: FC<Props> = ({ name, modalType }) => {
  const { t: translation } = useTranslation();

  const title = () => {
    switch (modalType) {
      case 'ADD':
        return (
          <div>
            {translation('text.add')}
            {name}
            {translation('text.to')}
          </div>
        );

      case 'DUPLICATE':
        return (
          <div>
            {translation('text.duplicate')}
            {name}
            {translation('text.to')}
          </div>
        );

      case 'COPY':
        return (
          <div>
            {translation('text.copy')}
            {name}
            {translation('text.to')}
          </div>
        );

      case 'MOVE':
        return (
          <div>
            {translation('text.move')}
            {name}
            {translation('text.to')}
          </div>
        );

      default:
        throw Error('Action not specified');
    }
  };
  return (
    <div>
      { title() }
    </div>
  );
};
