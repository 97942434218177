import React, { FC } from 'react';
import { TrashBin } from '@gravity-ui/icons';
import { Button } from '@quotalogic/ui';
import { Alert } from '@quotalogic/ui/Dialog';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import { DELETE_SCHEDULER } from '../../gql/mutation';

interface Props {
    id: string
    closeDrawer: (e?: React.MouseEvent<HTMLElement>) => void
}

export const DeleteButton: FC<Props> = ({ id, closeDrawer }) => {
  const { t } = useTranslation();
  const [alertIsOpen, setAlertIsOpen] = React.useState(false);
  const [deleteScheduler] = useMutation(DELETE_SCHEDULER);

  const deleteHandler = async () => {
    await deleteScheduler({
      variables: { id },
      update: (cache) => {
        // removing item from cache
        cache.evict({
          id: cache.identify({
            __typename: 'Scheduler',
            id,
          }),
        });
        cache.gc();
      },
    });
    setAlertIsOpen(false);
    closeDrawer();
  };

  return (
    <>
      <Button
        view="ghost"
        onClick={() => setAlertIsOpen(true)}
      >
        <TrashBin color="#BDBDBD" />
      </Button>

      {alertIsOpen && (
        <Alert
          viewStyle="delete"
          isVisible={alertIsOpen}
          onClose={() => setAlertIsOpen(false)}
          title={t<string>('text.deleteScheduler')}
          text={t<string>('text.areYouSure')}
          submitText={t('button.delete')}
          cancelText={t('button.cancel')}
          onSubmit={deleteHandler}
        />
      )}
    </>
  );
};
