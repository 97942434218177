import styled from 'styled-components';
import { Hand } from '@gravity-ui/icons';

export const StyledInvite = styled.div`
  background: #e5e5e5;
  padding: 12px;
  border-radius: 12px;
  display: grid;
  grid-template-columns: 20px 1fr;
  column-gap: 8px;
  row-gap: 8px;
  grid-template-areas: 
          'icon text' 
          'icon controls';
`;

export const StyledText = styled.div`
  grid-area: text;
  line-height: 20px;
`;

export const StyledControls = styled.div`
  grid-area: controls;
  display: flex;
  gap: 8px;
`;

export const Icon = styled(Hand)`
  grid-area: icon;
  transform: rotate(15deg);
  color: #293547;
`;
