import { FetchResult, useMutation, useQuery } from '@apollo/client';
import { FieldChild, Column, Section, Catalog } from '@quotalogic/gateway/types';
import { DELETE_CALCULATED_FIELD, DELETE_FIELD, GET_FIELD_CHILDREN } from './gql';

type DeleteColumnProps = {
  catalogId: string
  sectionId: string
  columnId: string
  columnParent?: string
}

export const useDeleteColumn = ({ catalogId, sectionId, columnId, columnParent }: DeleteColumnProps) => {
  const { data: children } = useQuery<{ fieldChildren: FieldChild[] }>(GET_FIELD_CHILDREN, {
    variables: {
      sectionId,
      fieldId: columnId,
    },
    fetchPolicy: 'no-cache',
  });

  const [deleteField] = useMutation<
    { deleteField: boolean },
    { catalogId: string, sectionId: string, fieldId: string }
  >(DELETE_FIELD);

  const [deleteCalcField] = useMutation<
    { deleteCalcField: boolean },
    { catalogId: string, sectionId: string, fieldId: string, baseField: string }
  >(DELETE_CALCULATED_FIELD);

  const deleteColumn = async () => {
    // TODO: move delete field to single mutation.ts
    if (columnParent) {
      await deleteCalcField({
        variables: {
          catalogId,
          sectionId,
          fieldId: columnId,
          baseField: columnParent,
        },
        update(cache, { data }: FetchResult<{ deleteCalcField: boolean }>) {
          if (data?.deleteCalcField) {
            const ids = [columnId];
            if (children?.fieldChildren) {
              ids.push(
                ...children.fieldChildren
                  .filter((child) => child.sections.some((section) => section.id === sectionId))
                  .map((child) => child.id),
              );
            }

            // remove from catalog
            cache.modify<Catalog>({
              id: cache.identify({ id: catalogId, __typename: 'Catalog' }),
              fields: {
                columns(list, { readField }) {
                  if (list && list.length > 0) {
                    return list.filter((item) => {
                      const id = readField('id', item);
                      return columnId !== id;
                    });
                  }
                  return [];
                },
              },
            });

            // remove with children
            cache.modify<Section>({
              id: cache.identify({ id: sectionId, __typename: 'Section' }),
              fields: {
                columns(list, { readField }) {
                  return list.filter((item) => {
                    const id = readField('id', item);
                    return typeof id === 'string' ? !ids.includes(id) : false;
                  });
                },
              },
            });

            // remove from parent
            if (columnParent) {
              cache.modify<Column>({
                id: cache.identify({ id: columnParent, __typename: 'Column' }),
                fields: {
                  children(list) {
                    if (Array.isArray(list)) {
                      return list.filter((id) => !ids.includes(id));
                    }
                    return list;
                  },
                },
              });
            }

            cache.gc();
          }
        },
      });
    } else {
      await deleteField({
        variables: {
          catalogId,
          sectionId,
          fieldId: columnId,
        },
        update(cache, { data }: FetchResult<{ deleteField: boolean }>) {
          if (data?.deleteField) {
            const ids = [columnId];
            if (children?.fieldChildren) {
              ids.push(
                ...children.fieldChildren
                  .filter((child) => child.sections.some((section) => section.id === sectionId))
                  .map((child) => child.id),
              );
            }

            // remove with children
            cache.modify<Section>({
              id: cache.identify({ id: sectionId, __typename: 'Section' }),
              fields: {
                columns(list, { readField }) {
                  return list.filter((item) => {
                    const id = readField('id', item);
                    return typeof id === 'string' ? !ids.includes(id) : false;
                  });
                },
              },
            });

            cache.gc();
          }
        },
      });
    }
  };

  return { deleteColumn, children: children?.fieldChildren };
};
