import styled from 'styled-components';

export const Price = styled.div`
  grid-area: total;
  text-align: right;
  align-self: center;
  font-size: 1.143rem;
  font-weight: 700;
  color: var(--public-text-primary);

  @media (max-width: 768px) {
    text-align: left;
    align-self: flex-start;
  }
`;
