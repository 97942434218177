import styled from 'styled-components';

export const Manager = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr;
  column-gap: 16px;
  color: #4F4F4F;
`;

export const Picture = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #f0f0f0;
  overflow: hidden;
`;

export const Image = styled.img`
  object-fit: fill;
`;

export const Contacts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 12px;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const Link = styled.a<{ $border?: boolean }>`
  font-size: 1rem;
  text-decoration: ${({ $border = false }) => ($border ? 'underline' : 'none')};
`;

export const Position = styled.p`
  font-size: 0.857rem;
`;

export const Name = styled.p`
  font-size: 1.143rem;
  font-weight: 700;
`;
