import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-flow: column nowrap;
  row-gap: 16px;
  width: 400px;
`;

export const Controls = styled.div`
  text-align: right;
  margin-top: 8px;
`;
