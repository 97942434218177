import styled from 'styled-components';
import { Button } from '@quotalogic/ui';
import { FC } from 'react';
import { Minus } from '@gravity-ui/icons';

const Layout = styled.div`
  align-self: flex-start;
  opacity: 0;
`;

interface Props {
  onClick: () => void
}

export const ButtonDisconnect: FC<Props> = ({ onClick }) => (
  <Layout className="card-disconnect">
    <Button size="small" view="secondary" onClick={onClick} rounded data-testid="buttonDisconnect">
      <Minus />
    </Button>
  </Layout>
);
