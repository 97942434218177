import { css, styled } from 'styled-components';

export const StyledRoot = styled.li<{ $active?: boolean }>`
  padding: 8px;
  gap: 8px;
  display: flex;
  justify-content: space-between;
  
  ${({ $active = false }) => $active && css`
    background: #e9e9e9;
    border-radius: 8px;
  `}
`;

export const StyledLink = styled.a`
    flex: 1;
`;
