import type { FC } from 'react';
import { useMemo } from 'react';

export interface PublicOfferDateProps {
  date?: string
}

export const PublicOfferDate: FC<PublicOfferDateProps> = ({ date }) => {
  const createdAt = useMemo(() => {
    if (date) {
      const dateObj = new Date(date);

      // TODO: temp locale solution
      return dateObj.toLocaleString(
        navigator.language,
        { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' },
      );
    }

    return null;
  }, [date]);

  return (
    createdAt
  );
};
