import React, { FC, useCallback, useMemo, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import { PUBLIC_OFFER_PRODUCT, OFFER_GROUP } from '@quotalogic/gateway/fragments';
import { ImageGroup, Modal } from '@quotalogic/ui';
import { Check } from '@gravity-ui/icons';
import { PublicOfferProduct } from '@quotalogic/gateway/types/Offer';
import { OfferProductPrice } from './OfferProductPrice';
import { OfferProductTotal } from './OfferProductTotal';
import {
  Product,
  Comment,
  ShortDescription,
  Description,
  NameTitle,
  Title,
  Quantity,
  DescriptionContainer,
  ButtonStyle,
  Container,
  RadioContainer,
  DescriptionButtonStyle,
  Xmark,
  StyledProductPrice,
  StyledProductTotal,
  StyledRadioLayout,
  StyledPriceColumn, CollapseStyle, ValueNameStyle,
} from './styles';
import { OfferProductPopup } from './OfferProductPopup';

interface Props {
  id: string
  visibilityOptions: Record<string, boolean>
  parentId: string
  currency: string
  onCheck: (productId: string, groupId: string) => void
}

type Maybe<T> = T | undefined | null

export const OfferProduct: FC<Props> = ({ id, visibilityOptions, parentId, currency, onCheck }) => {
  const { t: translation } = useTranslation();
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const client = useApolloClient();
  const product: Maybe<PublicOfferProduct> = client.readFragment({
    id: `PublicOfferProduct:${id}`,
    fragment: PUBLIC_OFFER_PRODUCT,
  });

  const group = client.readFragment({
    id: `OfferGroup:${parentId}`,
    fragment: OFFER_GROUP,
  });

  const [show, setShow] = useState(false);

  const handleToggle = () => setShow(!show);

  const isAlternative = useMemo(() => group.type === 'ALTERNATIVE', [group.type]);

  const isChecked: boolean = useMemo(
    () => group.type === 'ALTERNATIVE' && product?.id === group.selectedItemId,
    [group.selectedItemId],
  );

  const visibleAllPrices = !(!visibilityOptions.discounts && !visibilityOptions.allPrices && !visibilityOptions.total);
  const isPricesVisible = visibilityOptions.allPrices || visibilityOptions.discounts;

  const props = useMemo(() => {
    if (isAlternative) {
      return {
        role: 'button',
      };
    }

    return {};
  }, [isAlternative]);

  const handleCheck = useCallback(() => {
    if (isAlternative) {
      return !isChecked && onCheck(id, parentId);
    }

    return null;
  }, [id, isAlternative, onCheck, parentId, isChecked]);

  if (!product) return null;

  return (
    <Container>
      <Product
        $visibleAllPrices={visibleAllPrices}
        $isAlternative={isAlternative}
        $isChecked={isChecked}
        onClick={handleCheck}
        {...props}
      >
        {isAlternative && (
          <StyledRadioLayout>
            <RadioContainer $isChecked={isChecked}>
              {isChecked && <Check />}
            </RadioContainer>
          </StyledRadioLayout>
        )}
        <ImageGroup images={product.images} />
        <Title onClick={() => setIsPopupVisible(true)}>
          { product.longDescription && product.longDescription.length > 0 ? (
            <DescriptionButtonStyle type="button">
              {product?.name}
            </DescriptionButtonStyle>
          ) : product?.name}
        </Title>
        <Description>
          <DescriptionContainer>
            {product?.description && product?.description.length > 0 && (
              <>
                <CollapseStyle $height={60} $isOpen={show}>
                  <ShortDescription dangerouslySetInnerHTML={{ __html: product?.description }} />
                </CollapseStyle>
                {isChecked && product?.description.length > 214 && (
                  <ButtonStyle onClick={handleToggle} $isChecked={isChecked}>
                    { show ? translation('button.less') : translation('button.open') }
                  </ButtonStyle>
                )}
              </>
            )}
          </DescriptionContainer>
          {product?.comment && product?.comment.length > 0 && (
            <Comment>
              {product.comment}
            </Comment>
          )}
        </Description>
        {visibleAllPrices
          && (
            <StyledPriceColumn>
              <StyledProductPrice $isSingle={product?.count === 1}>
                { isPricesVisible && (
                  <OfferProductPrice
                    price={Number(product.price)}
                    discountPrice={product.discountPrice ?? 0}
                    discount={product.discountAmount ?? 0}
                    visibilityOptions={visibilityOptions}
                    currency={currency}
                    count={product?.count ?? 1}
                  />
                )}
                <StyledProductTotal>
                  <Quantity>
                    <Xmark>&times;</Xmark>
                    {product?.count} {translation('text.pcs')}
                  </Quantity>
                  <OfferProductTotal
                    totalPrice={Number(product.totalPrice)}
                    totalDiscountPrice={Number(product.totalDiscountPrice)}
                    visibilityOptions={visibilityOptions}
                    currency={currency}
                  />
                </StyledProductTotal>
              </StyledProductPrice>
            </StyledPriceColumn>
          )}
      </Product>

      <Modal
        size="large"
        isVisible={isPopupVisible}
        onClose={() => setIsPopupVisible(false)}
        title={(
          <>
            <NameTitle>Name</NameTitle>
            <ValueNameStyle>{product.name}</ValueNameStyle>
          </>
        )}
      >
        <OfferProductPopup product={product} />
      </Modal>
    </Container>
  );
};
