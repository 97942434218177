import styled from 'styled-components';

export const Total = styled.p`
  padding: 0 24px;
  font-size: 1.714rem;
  line-height: normal;
  color: var(--public-text-primary);
  font-weight: 700;
  white-space: nowrap;

  @media (max-width: 768px) {
    padding: 0;
    font-size: 1rem;
  }
`;
