import styled from 'styled-components';
import { GlobalColors, Button } from '@quotalogic/ui';

export const StyledName = styled.h2`
  font-size: 1.143rem;
  font-weight: 700;
  line-height: 20px;
  color: ${GlobalColors.text.primary};
`;

export const StyledContact = styled.a`
  display: flex;
  gap: 8px;
  font-size: 1rem;
  line-height: 18px;
  color: ${GlobalColors.text.secondary};
  padding: 0 6px;

  &:hover {
    color: ${GlobalColors.text.accent};
  }
`;

export const StyledCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 260px 260px;
  grid-template-areas: 'info ';
  padding: 12px;
  background: ${GlobalColors.bg.main.secondary};
  border-radius: 12px;
  gap: 24px;
  align-items: center;

  &:hover {
    outline: 2px solid ${GlobalColors.border.focus};

    ${StyledName} {
      color: ${GlobalColors.text.accent};
    }

    &:has(${StyledContact}:hover) ${StyledName} {
      color: ${GlobalColors.text.primary};
    }
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 12px;
  flex: 1;
  grid-area: info;
  align-items: center;
`;

export const StyledImage = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
`;

export const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  flex: 1;
`;

export const StyledPosition = styled.span`
  font-size: 1rem;
  line-height: 18px;
  color: ${GlobalColors.text.secondary};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledButton = styled(Button)`
  height: 24px;
`;

export const CommentContainer = styled.div`
  padding: 12px;
  border-radius: 12px;
  width: 100%;
`;

export const StyledComment = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  font-weight: 500;
  line-height: 18px;
  color: ${GlobalColors.text.ghost};
`;

export const Wrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;
