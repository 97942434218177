import { FormField, Selector } from '@quotalogic/ui';
import { useMemo } from 'react';
import { useStore } from '@nanostores/react';
import { useTranslation } from 'next-i18next';
import { Catalog } from '@quotalogic/gateway/types';
import { $reimportSettings, updateReimportSettings } from '../storage';

type Props = {
  columns: Catalog['columns']
}

export const CodeNameSelector = ({ columns }: Props) => {
  const settings = useStore($reimportSettings);
  const { t } = useTranslation();

  const codeNameOptions = useMemo(
    () => columns?.map(({ id, label }) => ({ value: id, name: label })) ?? [],
    [columns],
  );

  return (
    <FormField name="code-field" label={t('importForm.codeField')} labelPlacement="left" rowLabelSize="short">
      <Selector
        name="codeField"
        value={settings.codeName ?? ''}
        options={codeNameOptions}
        onChange={(codeName) => updateReimportSettings({ codeName })}
      />
    </FormField>
  );
};
