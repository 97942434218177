/* eslint-disable import/no-extraneous-dependencies */
import { gql } from '@apollo/client';

export const DELETE_ACCOUNT = gql`
    mutation DeleteAccount($id: ID!) {
        deleteAccount(id: $id) {
            id
            name
            isActive
        }
    }
`;

export const LOG_OUT = gql`
    mutation LogOut {
        logOut
    }
`;

export const UPDATE_ACCOUNT = gql`
    mutation UpdateAccount($data: AccountData!) {
        updateAccount(data: $data) {
            id
            name
            picture
            phone
        }
    }
`;

export const UPLOAD_ACCOUNT_PICTURE = gql`
    mutation UploadAccountPicture($picture: File!) {
        uploadAccountPicture(picture: $picture) {
            id
            picture
        }
    }
`;

export const DELETE_AVATAR = gql`
    mutation DeleteAvatar {
        deleteAvatar {
            id
            picture
        }
    }
`;
