import { FC } from 'react';
import { formatter } from '@quotalogic/gateway/utils/formatter';
import { money } from '@quotalogic/money';
import { Price } from './styles';

interface Props {
  totalPrice: number
  totalDiscountPrice: number
  visibilityOptions: Record<string, boolean>
  currency: string
}

export const OfferProductTotal: FC<Props> = ({
  totalPrice,
  totalDiscountPrice,
  visibilityOptions,
  currency,
}) => {
  if (visibilityOptions.discounts) {
    return (
      <Price>
        {formatter(+money(totalDiscountPrice).format(), currency)}
      </Price>
    );
  }

  if (visibilityOptions.allPrices) {
    return (
      <Price style={{ textDecoration: 'line-through' }}>
        {formatter(+money(totalPrice).format(), currency)}
      </Price>
    );
  }

  return null;
};
