import { ReactNode } from 'react';
import { WorkspaceSelectorStyle } from './styles';
import { WorkspaceMenu } from './WorkspaceMenu';

type Props = {
  defaultCurrency: string
  settingsButton: ReactNode
}

export const WorkspaceSelector = ({ defaultCurrency, settingsButton }: Props) => {
  return (
    <WorkspaceSelectorStyle>
      <WorkspaceMenu defaultCurrency={defaultCurrency} />
      {settingsButton}
    </WorkspaceSelectorStyle>
  );
};
