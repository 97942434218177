import { FC } from 'react';
import { formatter } from '@quotalogic/gateway/utils/formatter';
import { money } from '@quotalogic/money';
import { ProductPriceBlock } from './ProductPriceBlock';
import { Discount, DiscountContainer, Price, PriceWithDiscount } from './styles';

interface Props {
  price: number
  discountPrice: number
  discount: number
  visibilityOptions: Record<string, boolean>
  currency: string
  count: number
}

export const OfferProductPrice: FC<Props> = ({
  price,
  discount,
  visibilityOptions,
  currency,
  count,
  discountPrice,
}) => {
  const showDiscountContainer = discount > 0 && visibilityOptions.allPrices && visibilityOptions.discounts;

  return (
    <Price $count={count}>
      {count > 1 && (
        <ProductPriceBlock
          price={price}
          currency={currency}
          discountPrice={discountPrice}
          visibilityOptions={visibilityOptions}
        />
      )}
      {showDiscountContainer && (
        <DiscountContainer>
          <PriceWithDiscount style={{ textDecoration: discount > 0 ? 'line-through' : 'none' }}>
            {formatter(+money(price).format(), currency)}
          </PriceWithDiscount>
          <Discount>
            -
            {+money(discount).format()}
            %
          </Discount>
        </DiscountContainer>
      )}
    </Price>
  );
};
