import styled from 'styled-components';
import { GlobalColors } from '@quotalogic/ui';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  padding: 12px 12px 12px 16px;
  justify-content: space-between;
  align-items: center;
  background-color: ${GlobalColors.bg.main.secondary};
  color: ${GlobalColors.text.secondary};
  z-index: 100;
  border-bottom: 1px solid ${GlobalColors.border.secondary};
  box-shadow: 0 2px 8px 0 #0000000A;
`;

export const LinksBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const IconsBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 16px;
`;

export const UserLinksBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LogoBlock = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: ${GlobalColors.icon.primary};
`;
