type MapIds = {
  nodeId: string
  nodes: Record<string, string[]>
  rootId: string
}

export const mapIds = ({
  nodeId,
  nodes,
  rootId,
}: MapIds): string[] => Object.keys(nodes).reduce<string[]>((acc, key) => {
  if (nodes[key].includes(nodeId)) {
    acc.push(key);

    if (key !== rootId) {
      return [
        ...acc,
        ...mapIds({
          nodeId: key,
          nodes,
          rootId,
        }),
      ];
    }
  }

  return acc;
}, []);
