import { styled } from 'styled-components';
import { GlobalColors } from '@quotalogic/ui';

export const Layout = styled.div`
  border-top: 1px solid #eaeaea;
  display: grid;
  grid-template-columns: 240px auto;
  overflow: hidden;
`;

export const Catalogs = styled.div`
  list-style-type: none;
  padding-right: 24px;
  padding-top: 20px;
  padding-bottom: 24px;
  border-right: 1px solid #eaeaea;
  overflow: auto;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const ButtonStyle = styled.button<{ $isActive: boolean }>`
  all: unset;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ $isActive }) => ($isActive ? GlobalColors.bg.gray.ghostActive : 'transparent')};
`;

export const Sections = styled.div`
  padding-left: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  overflow: auto;
    
  > div {
    margin-top: 8px;
  }
`;

export const Text = styled.span`
  color: ${GlobalColors.text.primary};
`;
