import React, { forwardRef, HTMLAttributes } from 'react';
import { useApolloClient } from '@apollo/client';
import { Section as CatalogSection } from '@quotalogic/gateway/types';
import { useRouter } from 'next/router';
import { ArrowUpArrowDown, ChevronDown } from '@gravity-ui/icons';
import { useTranslation } from 'next-i18next';
import { SECTION } from '@quotalogic/gateway/fragments';
import { SectionMenu } from '../SectionMenu';
import { Action } from './components/Action';
// eslint-disable-next-line import/no-cycle
import { Container, StyleItem, Text } from './styles';

export enum STATUS {
  DEFAULT = 'default',
  DISABLED = 'disabled',
  SELECTED = 'selected',
  DRAGDROP = 'dragDrop',
  PUSHING = 'pushing',
  SUCCESS = 'success'
}

export type CardStatus = 'default' | 'disabled' | 'selected' | 'dragDrop' | 'pushing' | 'success'

function setPath(asPath: string, sectionId: string): string {
  const path = asPath.split('/').slice(0, 3);

  if (path[2].includes('?')) {
    const result = path[2].split('?')[0];
    return [path[0], path[1], result, sectionId].join('/');
  }
  path.push(sectionId);
  return path.join('/');
}

type Maybe<T> = T | null;

export interface Props extends Omit<HTMLAttributes<HTMLLIElement>, 'id'> {
  id: string | number;
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  depth: number;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  handleProps?: any;
  indicator?: boolean;
  indentationWidth: number;
  sectionId: string;
  catalogId: string;
  createItem?: (parentId?: string) => void;
  status?: CardStatus

  onCollapse?(): void;

  wrapperRef?(node: HTMLLIElement): void;
}

export const SectionItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      id,
      childCount,
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      collapsed,
      onCollapse,
      style,
      sectionId,
      catalogId,
      createItem,
      wrapperRef,
      status = 'default',
      ...props
    },
    ref,
  ) => {
    const { t: translation } = useTranslation();
    const { asPath } = useRouter();

    const client = useApolloClient();
    const section: Maybe<CatalogSection> = client.readFragment({
      id: `Section:${sectionId}`,
      fragment: SECTION,
    });

    if (!section) {
      return null;
    }

    const calculateSpacing = (indent: number, depth: number) => {
      const width = indent * depth;
      return width > 100 ? 100 : width;
    };

    return (
      <Container
        ref={wrapperRef}
        $status={ghost ? 'pushing' : status}
        $ghost={ghost}
        {...handleProps}
        style={
         { ...style,
           '--spacing': `${calculateSpacing(indentationWidth, depth)}px` } as React.CSSProperties
        }
        {...props}
      >
        <StyleItem
          ref={ref}
          $isCollapse={onCollapse}
        >
          {onCollapse && (
            <Action onClick={onCollapse}>
              <ChevronDown style={{ color: '#828282', transform: collapsed ? 'rotate(-90deg)' : '' }} />
            </Action>
          )}
          <Text href={setPath(asPath, sectionId)}>{section.name.length > 0
            ? section.name
            : translation('placeholder.newSection').toString()}
          </Text>
          <div style={{ display: 'flex', gap: 4 }}>
            {(status === STATUS.DEFAULT || status === STATUS.DISABLED || status === STATUS.SELECTED)
              && (
              <SectionMenu
                sectionName={section?.name}
                sectionId={sectionId}
                catalogId={catalogId}
                createItem={createItem}
              />
              )}
            { status === STATUS.DRAGDROP
              && <ArrowUpArrowDown width={16} height={16} color="#959595" />}
          </div>
        </StyleItem>
      </Container>
    );
  },
);
