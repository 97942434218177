import React, { FC, HTMLAttributes, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { EllipsisVertical } from '@gravity-ui/icons';
import { FetchResult, useMutation, useQuery } from '@apollo/client';
import { Catalog, Section } from '@quotalogic/gateway/types';
import { useRouter } from 'next/router';
import { Modal, Popup, PopupRef } from '@quotalogic/ui';
import { Alert } from '@quotalogic/ui/Dialog';
import { DISABLE_SECTION, COPY_SECTION, INCLUDE_SECTION } from './mutation';
import { ListButton, ListStyle, StyleButton, StyleMenu } from './styles';
import { GET_CATALOG } from './query';
import { ModalForm } from '../../../CatalogMoveModal';
import { checkInChildren } from './helper';

interface Props extends Omit<HTMLAttributes<HTMLLIElement>, 'id'> {
  sectionName: string,
  sectionId: string,
  catalogId: string,
  createItem?: (parentId?: string) => void;
  canDelete?: boolean;
}

export const SectionMenu: FC<Props> = ({ createItem, sectionName, sectionId, catalogId, canDelete = true }) => {
  const { t: translation } = useTranslation();
  const router = useRouter();
  const [disableSection, { loading }] = useMutation(DISABLE_SECTION);
  const [visible, setVisible] = useState(false);
  const [isIncludeForm, setIncludeForm] = useState(false);
  const [isCopyForm, setCopyForm] = useState(false);
  const popupRef = useRef<PopupRef>(null);

  const { data: catalogData } = useQuery<{ catalog: Catalog }>(
    GET_CATALOG,
    {
      variables: { id: catalogId },
    },
  );

  async function deleteItem() {
    await disableSection({
      variables: {
        id: sectionId,
      },
      async update(cache, { data }: FetchResult<{ disableSection: boolean }>) {
        if (data && data.disableSection) {
          cache.modify<Catalog>({
            id: cache.identify({
              id: catalogId,
              __typename: 'Catalog',
            }),
            fields: {
              nodes(existingNodes, { isReference }) {
                if (isReference(existingNodes)) return existingNodes;
                return Object.entries(existingNodes).reduce<Record<string, string[]>>((acc, [key, value]) => {
                  if (key !== sectionId) {
                    acc[key] = value.filter((item) => item !== sectionId);
                  }
                  return acc;
                }, {});
              },
              sections(refs, { readField }) {
                if (Array.isArray(refs)) {
                  return refs.filter((sectionRef) => sectionId !== readField('id', sectionRef));
                }

                return refs;
              },
            },
          });

          if (typeof router.query.sectionId === 'string'
            && (sectionId === router.query.sectionId || (catalogData?.catalog.nodes && checkInChildren(catalogData.catalog.nodes, sectionId, router.query.sectionId)))
          ) {
            await router.replace({
              pathname: `/catalogs/${catalogId}`,
            });
          }
        }
      },
    });
  }

  const [includeSection] = useMutation(INCLUDE_SECTION);
  const [copySection] = useMutation(COPY_SECTION);

  const handleMoveSection = async (id: string, type: 'INCLUDE' | 'COPY') => {
    popupRef.current?.onClose();
    switch (type) {
      case 'INCLUDE':
        await includeSection({
          variables: {
            fromSectionId: sectionId,
            toSectionId: id,
          },
          // TODO: refactor includeSection type
          update(cache, { data }: FetchResult<{ includeSection: Section}>) {
            if (data && data.includeSection) {
              cache.modify({
                id: cache.identify({
                  __typename: 'Catalog',
                  id: catalogId,
                }),
                fields: {
                  // Nodes
                  nodes(existingNodes: any) {
                    const nodes = structuredClone(existingNodes);
                    nodes[id]?.push(data.includeSection.id);
                    nodes[data.includeSection.id] = [];
                    return nodes;
                  },
                  // Section[]
                  sections(refs: any, { toReference }) {
                    return [...refs, toReference({ __typename: 'Section', id: data.includeSection.id })];
                  },
                },
              });
            }
          },
        });
        break;

      case 'COPY':
        await copySection({
          variables: {
            sourceSectionId: sectionId,
            targetSectionId: id,
          },
          update(cache, { data }: FetchResult<{ copySection: Section}>) {
            if (data && data.copySection) {
              cache.modify({
                id: cache.identify({
                  __typename: 'Catalog',
                  id: catalogId,
                }),
                fields: {
                  // Nodes
                  nodes(existingNodes: any) {
                    const nodes = structuredClone(existingNodes);
                    nodes[id]?.push(data.copySection.id);
                    nodes[data.copySection.id] = [];
                    return nodes;
                  },
                  // Section[]
                  sections(refs: any, { toReference }) {
                    return [...refs, toReference({ __typename: 'Section', id: data.copySection.id })];
                  },
                },
              });
            }
          },
        });
        break;

      default:
        throw Error('Action not specified');
    }
  };

  const handleCreateItem = (parentId?: string) => {
    popupRef.current?.onClose();
    createItem && createItem(parentId);
  };

  const handleCopyForm = () => {
    setCopyForm(true);
    popupRef.current?.onClose();
  };

  const handleIncludeForm = () => {
    setIncludeForm(true);
    popupRef.current?.onClose();
  };

  const handleDelete = () => {
    setVisible(true);
    popupRef.current?.onClose();
  };

  return (
    <>
      <StyleMenu>
        <Popup
          offsetX={-4}
          ref={popupRef}
          trigger={(
            <StyleButton>
              <EllipsisVertical />
            </StyleButton>
          )}
        >
          <ListStyle>
            {!catalogData?.catalog?.parent && (
              <li>
                <ListButton view="ghost" align="left" onClick={() => handleCreateItem(sectionId)}>
                  {translation('button.newSubsection')}
                </ListButton>
              </li>
            )}
            <li>
              <ListButton view="ghost" align="left" onClick={handleCopyForm}>
                {translation('button.duplicate')}
              </ListButton>
            </li>
            <li>
              <ListButton view="ghost" align="left" onClick={handleIncludeForm}>
                {translation('button.deriveCopy')}
              </ListButton>
            </li>
            {!catalogData?.catalog?.parent && canDelete && (
              <li>
                <ListButton view="ghost" align="left" onClick={handleDelete}>
                  {translation('button.delete')}
                </ListButton>
              </li>
            )}
          </ListStyle>
        </Popup>
      </StyleMenu>

      {/* include section form */}
      <Modal
        onClose={() => setIncludeForm(false)}
        isVisible={isIncludeForm}
        title={`${translation('text.copy')} ${sectionName} ${translation('text.to')}`}
      >
        <ModalForm
          handleSectionClick={(id) => handleMoveSection(id, 'INCLUDE')}
          handleCloseModal={() => setIncludeForm(false)}
          catalogId={catalogId}
        />
      </Modal>

      {/* copy section form */}
      <Modal
        onClose={() => setCopyForm(false)}
        isVisible={isCopyForm}
        title={`${translation('text.copy')} ${sectionName} ${translation('text.to')}`}
      >
        <ModalForm
          handleSectionClick={(id) => handleMoveSection(id, 'COPY')}
          handleCloseModal={() => setCopyForm(false)}
          catalogId={catalogId}
        />
      </Modal>

      {visible && (
        <Alert
          viewStyle="delete"
          title={`${translation('text.delete')} ${sectionName}`}
          text={translation<string>('text.areYouSure')}
          isVisible={visible}
          onClose={() => setVisible(false)}
          submitText={translation('button.delete')}
          cancelText={translation('button.cancel')}
          onSubmit={() => deleteItem()}
          isLoading={loading}
        />
      )}
    </>
  );
};
