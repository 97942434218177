import React, { FC, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { Alert } from '@quotalogic/ui/Dialog';
import { List, ListItem, SmallText, Content } from './styles';
import { useDeleteColumn } from './hooks';

interface Props {
  isVisible: boolean
  sectionId: string
  catalogId: string
  columnId: string
  columnParent?: string
  columnName: string
  onClose: () => void
  onComplete?: () => void
}

export const DeleteColumnAlert: FC<Props> = ({
  isVisible,
  sectionId,
  catalogId,
  onClose,
  columnId,
  columnParent,
  columnName,
  onComplete,
}) => {
  const { t: translation } = useTranslation();
  const { deleteColumn, children } = useDeleteColumn({ catalogId, sectionId, columnId, columnParent });

  const childrenList = useMemo(() => children?.map((field) => {
    const sections = field.sections.reduce<Record<string, string>>((acc, section) => {
      if (section.name.length > 0) {
        acc[section.id] = section.name;
      }

      return acc;
    }, {});

    return {
      id: field.id,
      name: field.name,
      sections: Object.values(sections).join(', '),
    };
  }) ?? [], [children]);

  const handleDeleteColumn = async () => {
    await deleteColumn();
    onClose();
  };

  return (
    <Alert
      viewStyle="delete"
      title={`${translation('text.delete')} "${columnName}"`}
      isVisible={isVisible}
      onClose={onClose}
      submitText={translation('button.delete')}
      cancelText={translation('button.cancel')}
      onSubmit={handleDeleteColumn}
    >
      <Content>
        <p>{translation('common.delete.warning')}</p>
        {childrenList.length > 0 && (
          <>
            <p>{translation('catalog.alert.removeColumn.text')}:</p>
            <List>
              {childrenList.map((field) => (
                <ListItem key={field.id}>
                  {field.name}
                  {field.sections.length > 0 && (
                    <SmallText>
                      {field.sections}
                    </SmallText>
                  )}
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Content>
    </Alert>
  );
};
