import React, { FC } from 'react';
import { formatter } from '@quotalogic/gateway/utils/formatter';
import { money } from '@quotalogic/money';
import { PriceWithoutDiscount } from '../styles';

interface Props {
  price: number
  discountPrice: number
  visibilityOptions: Record<string, boolean>
  currency: string
}

export const ProductPriceBlock: FC<Props> = ({ discountPrice, price, currency, visibilityOptions }) => {
  return (
    <PriceWithoutDiscount style={{ textDecoration: visibilityOptions.discounts ? 'none' : 'line-through' }}>
      {formatter(
        visibilityOptions.discounts
          ? +money(discountPrice).format()
          : +money(price).format(),
        currency,
      )}
    </PriceWithoutDiscount>
  );
};
