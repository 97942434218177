import { FetchResult, Reference, useMutation, useQuery } from '@apollo/client';
import { Catalog } from '@quotalogic/gateway/types';
import { useEffect, useMemo, useState } from 'react';
import { Button, Spinner } from '@quotalogic/ui';
import { useTranslation } from 'next-i18next';
import { flatten } from '../../SectionAside/components/SortableList/utilities';
import { CONNECT_MULTI_SECTIONS, GET_CATALOG } from './gql';
import { SectionItem } from '../SectionItem/SectionItem';
import { DisconnectedAll } from '../DisconnectedAll';
import { Container, Controls, List } from './styles';
import { mapIds } from './helpers';

interface Props {
  activeCatalogId: string
  handleCloseModal: () => void
  sectionIds: string[]
  productId: string
  sectionId?: string
}

export const SectionTree = ({
  activeCatalogId,
  handleCloseModal,
  sectionIds,
  productId,
  sectionId,
}: Props) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery<{ catalog: Catalog }, { id: string }>(GET_CATALOG, {
    variables: {
      id: activeCatalogId,
    },
  });

  const [collapsed, setCollapsed] = useState<string[] | null>(null);
  const [connectedIds, setConnectedIds] = useState<string[]>(sectionIds ?? []);
  const [disconnected, setDisconnected] = useState(false);
  const [connectMultiSections] = useMutation(CONNECT_MULTI_SECTIONS, { errorPolicy: 'all' });

  /**
   * Updates the sectionIds state by adding or removing the given id.
   * If the id is already in the sectionIds array, it removes it.
   * If the id is not in the sectionIds array, it adds it.
   *
   * @param id - The id of the section to toggle in the sectionIds array.
   */
  const handleSectionClick = (id: string) => {
    setDisconnected(false);
    setConnectedIds((prevSectionIds) => {
      if (prevSectionIds.includes(id)) {
        return prevSectionIds.filter((sectionId) => sectionId !== id);
      }
      return [...prevSectionIds, id];
    });
  };

  useEffect(() => {
    if (sectionIds) {
      setConnectedIds(sectionIds);
    }
  }, [sectionIds]);

  const list = useMemo(() => {
    const nodes = data?.catalog.nodes;
    const catalogId = data?.catalog.id;
    if (nodes && catalogId && collapsed) {
      const root = {
        id: catalogId,
        children: [...nodes[catalogId]],
        parentId: null,
        depth: 0,
        index: 0,
        collapsed: collapsed.includes(catalogId),
      };
      return root.collapsed ? [root] : [root, ...flatten(nodes, catalogId, collapsed, 1)];
    }
    return [];
  }, [collapsed]);

  useEffect(() => {
    const nodes = data?.catalog.nodes;
    const catalogId = data?.catalog.id;
    if (nodes && catalogId) {
      // eslint-disable-next-line no-shadow
      // collect an array of section ids in order to hide their children
      const collapsed = Object.keys(nodes).reduce<string[]>((acc, key) => {
        // leave the root partition open
        if (key !== catalogId
          && nodes[key].length
          && nodes[catalogId].includes(key)) acc.push(key);

        return acc;
      }, []);

      // collect an array of the ids of the parents of those sections to which the product is attached
      const parentIds = sectionIds.reduce((acc, sectionId) => {
        // go through each section and check if the id is included in the children
        mapIds({ nodeId: sectionId, nodes, rootId: catalogId }).forEach((id) => acc.add(id));
        return acc;
      }, new Set<string>());

      // remove the ids of the parents of the sections to which the product is linked
      const result = collapsed.filter((item) => !parentIds.has(item));

      setCollapsed(result);
    }
  }, [data, sectionIds]);

  const handleCollapse = (id: string, status?: boolean) => {
    const nodes = data?.catalog.nodes;
    if (nodes && collapsed) {
      if (status) {
        const items = collapsed?.filter((item) => item !== id);
        const result = new Set([...items, ...nodes[id]]);
        setCollapsed([...result]);
      } else {
        const result = new Set([...collapsed, id]);
        setCollapsed([...result]);
      }
    }
  };

  const handleSave = async () => {
    await connectMultiSections({
      variables: {
        productId,
        newSectionIds: connectedIds.length > 0 ? connectedIds : [activeCatalogId],
      },
      update(cache, { data }: FetchResult<{ connectMultiSections: true }>) {
        if (data?.connectMultiSections) {
          cache.modify({
            id: cache.identify({
              __typename: 'Product',
              id: productId,
            }),
            fields: {
              originIds() {
                return connectedIds.length > 0 ? connectedIds : [activeCatalogId];
              },
            },
          });
          /*
            product should stay
            1. if sectionId includes in connectedIds -> connectedIds.includes(sectionId)
            2. when sectionId is root & connectedIds is empty -> sectionId === activeCatalogId && connectedIds.length === 0
          */
          if (sectionId && !(connectedIds.includes(sectionId) || (sectionId === activeCatalogId && connectedIds.length === 0))) {
            cache.modify({
              id: cache.identify({
                __typename: 'Section',
                id: sectionId,
              }),
              fields: {
                products(refs, { readField }) {
                  return refs.filter((ref: Reference) => productId !== readField('id', ref));
                },
              },
            });
          }
        }
      },
    });

    handleCloseModal();

    if (connectedIds.length === 0) {
      setConnectedIds([activeCatalogId]);
    }
  };
  const handleDisconnected = () => {
    setDisconnected(!disconnected);
    setConnectedIds([]);
  };

  if (loading) return <Spinner size={24} />;

  return (
    <Container>
      <List>
        {list.map((item) => (
          <SectionItem
            id={item.id}
            depth={item.depth}
            collapsed={Boolean(item.collapsed)}
            handleCollapse={handleCollapse}
            hasChildren={item.children.length > 0}
            key={item.id}
            catalogId={data?.catalog.id}
            handleSectionClick={handleSectionClick}
            catalogName={data?.catalog.name}
            sectionIds={connectedIds ?? []}
          />
        ))}
      </List>
      <Controls>
        <DisconnectedAll
          isDisconnected={disconnected || connectedIds.length === 0}
          handleDisconnect={handleDisconnected}
        />
        <Button onClick={handleSave}>{t('button.save')}</Button>
      </Controls>
    </Container>
  );
};
