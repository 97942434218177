import { TrashBin } from '@gravity-ui/icons';
import { Button } from '@quotalogic/ui';
import { Alert } from '@quotalogic/ui/Dialog';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { User } from '@quotalogic/gateway/types';
import { DELETE_ACCOUNT } from '../../AccountForms/Settings/gql/mutation';
import { GET_USER } from '../gql/query';
import { AlertText } from './styles';

export const DeleteUserAction = () => {
  const { t } = useTranslation('account');
  const [isOpen, setIsOpen] = useState(false);
  const { data } = useQuery<{ user: User }>(GET_USER, { errorPolicy: 'all' });

  const [deleteAccount] = useMutation(DELETE_ACCOUNT);
  const deleteAcc = async () => {
    await deleteAccount({
      variables: {
        id: data?.user.id,
      },
    });
    window && window.location.reload();
  };

  return (
    <>
      <Button
        view="ghost"
        iconLeft={<TrashBin width={14} height={14} />}
        onClick={() => setIsOpen(true)}
      />
      {isOpen && (
        <Alert
          viewStyle="delete"
          isVisible={isOpen}
          onClose={() => setIsOpen(false)}
          title={t<string>('delete.title')}
          submitText={t('button.delete')}
          cancelText={t('button.cancel')}
          onSubmit={deleteAcc}
        >
          <AlertText>
            <span>{t<string>('delete.prev')}</span>
            <span>{t<string>('delete.post')}</span>
          </AlertText>
        </Alert>
      )}
    </>
  );
};
