import { useApolloClient } from '@apollo/client';
import { Section } from '@quotalogic/gateway/types';
import { SECTION } from '@quotalogic/gateway/fragments';
import { ChevronRight } from '@gravity-ui/icons';
import React, { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { Layout, Button, Name } from './styles';

interface Props {
  id: string
  depth: number
  collapsed: boolean
  hasChildren: boolean
  handleCollapse: (id: string, status: boolean) => void
  catalogId?: string
  handleSectionClick: (id: string) => void
  handleCloseModal: () => void
}

export const SectionItem: FC<Props> = ({
  catalogId,
  id,
  depth,
  collapsed,
  hasChildren,
  handleCollapse,
  handleSectionClick,
  handleCloseModal,
}) => {
  const { t: translation } = useTranslation();
  const client = useApolloClient();
  const section: Section | null = client.readFragment({
    id: `Section:${id}`,
    fragment: SECTION,
  });

  const handleSection = async () => {
    handleSectionClick(id);
    handleCloseModal();
  };

  return (
    <Layout $depth={depth}>
      { hasChildren
        && (
        <Button onClick={() => handleCollapse(id, collapsed)} style={{ transform: collapsed ? '' : 'rotate(90deg)' }}>
          <ChevronRight />
        </Button>
        )}
      <Name onClick={handleSection}>
        {section?.name.length as number > 0
          ? section?.name
          : translation(id === catalogId ? 'title.rootSection' : 'title.newSection')}
      </Name>
    </Layout>
  );
};
