/* eslint-disable */
import React, { FC } from 'react';
import { ChevronLeft } from '@gravity-ui/icons';
import { BackButton, StatusStyle, ListBlock, ListItem, Text } from './styles';
import { SchedulerDetailsItemVar } from '../../SchedulerReactiveVar';
import { useTranslation } from 'next-i18next';

interface Props {
    item: string;
}

export const Details: FC<Props> = ({ item }) => {
    const { t: translation } = useTranslation();

  return (
    <div>
        <BackButton onClick={() => SchedulerDetailsItemVar(null)}>
            <ChevronLeft color={'#BDBDBD'} width={24} height={24} />
            <span>{item}</span>
        </BackButton>

        <ListBlock>
          <ListItem>
              <Text>{translation('scheduler.date')}</Text>
              <span>{item}</span>
          </ListItem>
            <ListItem>
                <Text>{translation('scheduler.time')}</Text>
                <span>13:55</span>
            </ListItem>
            <ListItem>
                <Text>{translation('scheduler.status')}</Text>
                <StatusStyle><div />Error</StatusStyle>
            </ListItem>
        </ListBlock>
    </div>
  );
};
