import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MeasuringStrategy,
  Modifier,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import type { Catalog, Nodes } from '@quotalogic/gateway/types';
import { FetchResult, useMutation } from '@apollo/client';
import { createPortal } from 'react-dom';
import { flatten, getProjection } from './utilities';
import { sortableTreeKeyboardCoordinates } from './keyboardCoordinates';
import { SensorContext } from './types';
import { MOVE_SECTION } from './mutation';
import { SortableItem } from '../SortableItem';
import { SectionItem } from '../SectionItem';

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

interface Props {
  collapsible?: boolean
  indentationWidth?: number
  indicator?: boolean
  nodes: Nodes;
  catalogId: string
  sectionId: string
  createItem?: (parentId?: string) => void
}

const parents: Set<string> = new Set([]);

// collapse all nodes except the current branch
const useCollapsed = (nodes: Nodes, sectionId: string) => {
  const findParent = (id: string) => Object.keys(nodes).find((key) => nodes[key].includes(id));
  const collectParents = (id: string) => {
    const parent = findParent(id);

    if (parent) {
      parents.add(parent);
      collectParents(parent);
    }
  };

  collectParents(sectionId);

  const [collapsedItems, setCollapsedItems] = useState(() =>
    Object.keys(nodes).reduce((acc: string[], key) => {
      if (!parents.has(key)) {
        acc.push(key);
      }
      return acc;
    }, []));

  return {
    collapsedItems, setCollapsedItems,
  };
};

export const SortableList: FC<Props> = ({
  collapsible,
  indicator = false,
  indentationWidth = 16,
  nodes,
  catalogId,
  sectionId,
  createItem,
}) => {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [currentPosition, setCurrentPosition] = useState<{
    parentId: UniqueIdentifier | null;
    overId: UniqueIdentifier;
  } | null>(null);

  const [moveSection] = useMutation(MOVE_SECTION);
  const { collapsedItems, setCollapsedItems } = useCollapsed(nodes, sectionId);
  const flattenedItems = useMemo(() => flatten(nodes, catalogId, collapsedItems), [nodes, collapsedItems]);

  const projected = activeId && overId
    ? getProjection(
      flattenedItems,
      activeId,
      overId,
      offsetLeft,
      indentationWidth,
    )
    : null;

  const sensorContext: SensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });

  const [coordinateGetter] = useState(() => sortableTreeKeyboardCoordinates(
    sensorContext,
    indicator,
    indentationWidth,
  ));

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    }),
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
  );

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [
    flattenedItems,
  ]);
  const activeItem = activeId
    ? flattenedItems.find(({ id }) => id === activeId)
    : null;

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  const adjustTranslate: Modifier = ({ transform }) => ({
    ...transform,
    y: transform.y,
    // duration: transition,
  });

  const handleDragStart = ({ active }: DragStartEvent) => {
    setActiveId(active.id);
    setOverId(active.id);

    const activeItem = flattenedItems.find(({ id }) => id === active.id);

    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: active.id,
      });
    }
  };

  const handleDragMove = ({ delta }: DragMoveEvent) => {
    setOffsetLeft(delta.x);
  };

  const handleDragOver = ({ over, active }: DragOverEvent) => {
    setOverId(over ? over?.id : null);
  };

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);
  }

  const handleDragEnd = async ({ active, over }: DragEndEvent) => {
    resetState();

    if (projected && over) {
      const { depth, parentId } = projected;
      const overIndex = flattenedItems.findIndex(({ id }) => id === over.id);
      const activeIndex = flattenedItems.findIndex(({ id }) => id === active.id);
      const activeItem = flattenedItems[activeIndex];
      const overItem = flattenedItems[overIndex];

      const id = parentId ?? catalogId;

      if (id && active.id) {
        const catalogNodes = structuredClone(nodes);
        if (catalogNodes && activeItem.parentId) {
          catalogNodes[activeItem.parentId].splice(activeItem.index, 1);
          let { index } = overItem;

          // По горизонтали (всегда разный depth и overID = activeID)
          if (activeItem.id === overItem.id) {
            const prevItem = flattenedItems[activeIndex - 1];

            if (prevItem) {
              // depth > prev.depth
              if (depth > prevItem.depth) {
                // prev collapsed = true => prev.children.length (node children)
                // prev collapsed = false => 0
                index = prevItem.collapsed ? prevItem.children.length : 0;
              }

              if (depth === prevItem.depth) {
                // depth = prev.depth => prev.idx + 1
                index = prevItem.index + 1;
              }

              if (depth < prevItem.depth) {
                // depth < prev.depth => next.idx - 1 ?? parent.children.length
                const nextItem = flattenedItems[activeIndex + 1];
                const parent = catalogNodes[id];
                index = nextItem?.index ?? parent.length;
              }
            }
          }

          // По вертикали (parentID = over.parentID)
          if (id === overItem.parentId) {
            // depth = over.depth => over.index
            index = overItem.index;
          }

          // Диагональ (parentID != over.parentID и меняется depth)
          if (id !== overItem.parentId && activeItem.id !== overItem.id) {
            // depth > over.depth => 0 // check collapsed = постоянно ставит на первое место в списке детей
            if (depth > overItem.depth) {
              index = overItem.collapsed ? catalogNodes[overItem.id].length : 0;
            }
          }

          catalogNodes[id].splice(index, 0, active.id as string);
          // Remove new parent id from collapsed list (id is new parent id)
          if (over.id !== catalogId) {
            const items = collapsedItems.filter((collapsedId) => collapsedId !== id);
            setCollapsedItems(items);
          }

          await moveSection({
            variables: {
              id: active.id,
              data: {
                parentId: activeItem.parentId,
                newIndex: index,
                newParentId: id,
              },
            },
            optimisticResponse: {
              __typename: 'Mutation',
              moveSection: true,
            },
            update(cache, { data }: FetchResult<{ moveSection: boolean }>) {
              if (data?.moveSection) {
                cache.modify<Catalog>({
                  id: cache.identify({
                    __typename: 'Catalog',
                    id: catalogId,
                  }),
                  fields: {
                    nodes(existingNodes, { isReference }) {
                      if (isReference(existingNodes)) return existingNodes;

                      const nodes = structuredClone(existingNodes);
                      // adding activeItem.parentId check, because TS doesn't know that activeItem.parentId is not null
                      if (activeItem.parentId && activeItem.parentId === id) { // if parent is the same
                        // find previous index in nodes
                        // (because activeIndex use flattenedItems and common index for all nodes)
                        const previousIndex = nodes[id].indexOf(active.id as string);
                        nodes[id].splice(previousIndex, 1);
                        nodes[id].splice(index, 0, active.id as string);
                      } else if (activeItem.parentId) { // if moving to another parent
                        nodes[activeItem.parentId].splice(activeItem.index, 1);
                        nodes[id].splice(index, 0, active.id as string);
                      }

                      return nodes;
                    },
                  },
                });
              }
            },
          });
        }
      }
    }
  };

  const handleDragCancel = () => {
    resetState();
  };

  function handleCollapse(id: UniqueIdentifier, collapsed?: boolean) {
    if (collapsed) {
      const items = collapsedItems.filter((item) => item !== id);
      setCollapsedItems([...items, ...nodes[id]]);
    } else {
      setCollapsedItems([...collapsedItems, id as string]);
    }
  }

  return (
    <DndContext
      id="sections"
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        {flattenedItems.map(({ id, children, collapsed, depth }) => (
          <SortableItem
            key={id}
            id={id}
            sectionId={id}
            catalogId={catalogId}
            depth={id === activeId && projected ? projected.depth : depth}
            indentationWidth={indentationWidth}
            indicator={indicator}
            collapsed={Boolean(collapsed && children.length)}
            onCollapse={
              collapsible && children.length
                ? () => handleCollapse(id, collapsed)
                : undefined
            }
            createItem={createItem}
            status={id === sectionId ? 'selected' : 'default'}
          />
        ))}
        {typeof document !== 'undefined' && createPortal(
          <DragOverlay
            dropAnimation={null}
            modifiers={indicator ? [adjustTranslate] : undefined}
          >
            {activeId && activeItem ? (
              <SectionItem
                id={activeId as string}
                depth={activeItem.depth}
                clone
                sectionId={activeId.toString()}
                catalogId={catalogId}
                indentationWidth={indentationWidth}
                status="dragDrop"
              />
            ) : null}
          </DragOverlay>,
          document.body,
        )}
      </SortableContext>
    </DndContext>
  );
};
