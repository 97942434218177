import { ReactNode, useEffect, useRef } from 'react';
import { DialogBackdropStyle } from './styles';

type Props = {
  children: ReactNode
  onClose: () => void
  centred?: boolean
}

export const DialogBackdrop = ({ children, onClose, centred = true }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const dialog = ref.current;

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      } else if (dialog && e.key === 'Tab') {
        // focus trap
        // querying all focusable elements in the dialog on each 'tab' keydown event, because the dialog can be updated
        // eslint-disable-next-line max-len
        const focusableElements = dialog.querySelectorAll('button:not(:disabled), [href], input:not(:disabled), select:not(:disabled), textarea:not(:disabled), [tabindex]:not([tabindex="-1"])');
        const firstElement = focusableElements[0] as HTMLElement;
        const lastElement = focusableElements[focusableElements.length - 1] as HTMLElement;

        if (e.shiftKey && document.activeElement === firstElement) {
          e.preventDefault();
          lastElement.focus();
        } else if (!e.shiftKey && document.activeElement === lastElement) {
          e.preventDefault();
          firstElement.focus();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  return (
    <DialogBackdropStyle ref={ref} $centred={centred} onClick={onClose}>
      {children}
    </DialogBackdropStyle>
  );
};
