import { Employee } from '@quotalogic/gateway/types';
import { FC, useMemo } from 'react';
import noAvatar from './avatar_placeholder.png';
import {
  Contacts, Link, Manager, Picture, Image, Position, Name,
} from './styles';

interface Props {
  manager: Employee
  photo?: boolean
}

export const OfferManagerCard: FC<Props> = ({ manager, photo = false }) => {
  const picture = useMemo(() => {
    if (manager.image && manager.image.length > 0) {
      return manager.image;
    }

    return noAvatar.src;
  }, []);

  return (
    <Manager>
      {photo && (
      <Picture>
        <Image src={picture} />
      </Picture>
      )}
      <div style={{ maxWidth: '253px', width: '100%' }}>
        <Position>{manager?.position}</Position>
        <Name>{manager?.name}</Name>
        <Contacts>
          {manager?.email
            && <Link style={{ color: '#8A80FF' }} $border href={`mailto:${manager.email}`}>{manager.email}</Link>}
          {manager?.phone
            && <Link href={`tel:${manager.phone}`}>{manager.phone}</Link>}
        </Contacts>
      </div>
    </Manager>
  );
};
