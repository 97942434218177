import { gql } from '@apollo/client';

export const DELETE_FIELD = gql`
    mutation DeleteField($catalogId: ID!, $sectionId: ID!, $fieldId: ID!) {
        deleteField(catalogId: $catalogId, sectionId: $sectionId, fieldId: $fieldId)
    }
`;

export const DELETE_CALCULATED_FIELD = gql`
    mutation DeleteCalcField($catalogId: ID!, $sectionId: ID!, $fieldId: ID!, $baseField: String!) {
        deleteCalcField(catalogId: $catalogId, sectionId: $sectionId, fieldId: $fieldId, baseField: $baseField)
    }
`;

export const GET_FIELD_CHILDREN = gql`
    query GetFieldChildren($sectionId: ID!, $fieldId: ID!) {
        fieldChildren(sectionId: $sectionId, fieldId: $fieldId) {
            id
            name
            parent
            sections {
                id
                name
            }
        }
    }
`;
