import { Employee } from '@quotalogic/gateway/types';
import { FC } from 'react';
import { OfferManagerCard } from './components/OfferManagerCard';
import { Grid, Title, Layout } from './styles';

interface Props {
  list: Employee[]
  title: string
}

export const PublicOfferManagers: FC<Props> = ({ list, title }) => (
  <Layout>
    <Title>
      { title }
    </Title>
    <Grid>
      {list.map((manager) => (
        <OfferManagerCard key={manager.id} manager={manager} photo />
      ))}
    </Grid>
  </Layout>
);
