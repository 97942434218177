import { gql } from '@apollo/client';

export const GET_WORKSPACE = gql`
    query Workspace {
        workspace {
            id
            name
            logo
            note
            currency
            isActive
        }
    }
`;

export const GET_WORKSPACE_LIST = gql`
    query Workspaces {
        workspaces {
            id
            name
            logo
            note
            currency
            isActive
        }
    }
`;
