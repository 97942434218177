import { useApolloClient } from '@apollo/client';
import { Section } from '@quotalogic/gateway/types';
import { SECTION } from '@quotalogic/gateway/fragments';
import { Check, ChevronRight } from '@gravity-ui/icons';
import { useTranslation } from 'next-i18next';
import { GlobalColors } from '@quotalogic/ui';
import { useMemo } from 'react';
import { Layout, Button, Name } from './styles';

interface Props {
  id: string
  depth: number
  collapsed: boolean
  hasChildren: boolean
  handleCollapse: (id: string, status: boolean) => void
  catalogId?: string
  handleSectionClick: (id: string) => void
  catalogName?: string
  sectionIds: string[]
}

export const SectionItem = ({
  catalogId,
  id,
  depth,
  collapsed,
  hasChildren,
  handleCollapse,
  handleSectionClick,
  catalogName = 'title.rootSection',
  sectionIds,
}: Props) => {
  const { t: translation } = useTranslation();
  const client = useApolloClient();
  const section: Section | null = client.readFragment({
    id: `Section:${id}`,
    fragment: SECTION,
  });

  const handleSection = async () => {
    handleSectionClick(id);
  };

  const isConnected = useMemo(() => sectionIds?.includes(id), [sectionIds]);

  return (
    <Layout $depth={depth} $isConnect={isConnected}>
      { hasChildren
        && (
        <Button onClick={() => handleCollapse(id, collapsed)} style={{ transform: collapsed ? '' : 'rotate(90deg)' }}>
          <ChevronRight color={`${GlobalColors.icon.ghost}`} />
        </Button>
        )}
      <Name onClick={handleSection}>
        {section?.name.length as number > 0
          ? section?.name
          : translation(id === catalogId ? catalogName : 'title.newSection')}
      </Name>
      {isConnected && (<Check />)}
    </Layout>
  );
};
