import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { Button, Popup, PopupRef } from '@quotalogic/ui';
import { CircleQuestion } from '@gravity-ui/icons';
import { Separator, Link } from './styles';
import { FeedbackButton } from './FeedbackButton';

type UrlLinkType = {
  url: string
  label: string
}

export const SupportBlock = () => {
  const { t } = useTranslation('account');

  const ref = useRef<PopupRef>(null);

  const supportLinks: UrlLinkType[] = useMemo(() => [
    {
      url: '/legal/license',
      label: t('links.license'),
    },
    {
      url: '/legal/privacy',
      label: t('links.policy'),
    },
    {
      url: '/legal/cookie-policy',
      label: t('links.cookie'),
    },
    {
      url: '/legal/terms-and-conditions',
      label: t('links.terms'),
    },
    // {
    //   url: '/',
    //   label: 'Imprint',
    // },
  ], [t]);

  const handleClose = () => {
    ref.current?.onClose();
  };

  return (
    <Popup
      allowedPlacements={['top-end', 'bottom-end']}
      trigger={(
        <Button view="ghost">
          <CircleQuestion width={16} height={16} />
        </Button>
      )}
      ref={ref}
      width={240}
    >
      <div>
        <FeedbackButton onClick={handleClose} />
        <Separator />
        {supportLinks.map((link) => (
          <Link key={link.url} href={link.url}>
            {link.label}
          </Link>
        ))}
      </div>
    </Popup>
  );
};
