import styled from 'styled-components';

export const DialogBackdropStyle = styled.div<{ $centred: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ $centred }) => ($centred ? 'center' : 'flex-start')};
  width: 100%;
  z-index: 200000000;
  box-sizing: border-box;
  padding: 24px;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
`;

export type ModalOffset = Partial<{ x: number; y: number }>

export const DialogStyle = styled.div<{ $width?: number, $offset?: ModalOffset }>`
  position: relative;
  padding: 24px 0;
  border-radius: 16px;
  box-sizing: border-box;
  background-color: white;
  flex-direction: column;
  display: flex;
  gap: 8px;
  box-shadow: 0 12px 24px 0 rgba(22, 34, 51, 0.06), 0 2px 8px 0 rgba(22, 34, 51, 0.04);
  width: ${({ $width }) => ($width ? `${$width}px` : 'auto')};
  ${({ $offset }) => $offset && `transform: translate(${($offset.x ?? 0)}px, ${($offset.y ?? 0)}px);`};
  
  @media (max-width: 768px) {
    padding: 0 16px 16px;
  }
`;

export const DialogHeaderStyle = styled.header`
  display: flex; 
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;
  padding: 0 24px;
    
  & > button {
    align-self: baseline;
    margin-top: -14px;
    margin-right: -14px;
  }
`;
