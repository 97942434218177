// eslint-disable-next-line import/no-extraneous-dependencies
import styled from 'styled-components';

export const ListBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 4px;
  border-bottom: 1px solid #F2F2F2;
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 24px;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 16px;
`;

export const StatusStyle = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  line-height: 14px;
  background: #F2F2F2;
  
  & > div {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #D9D9D9;
  }
`;

export const Text = styled.span`
  font-weight: 700;
  line-height: 18px;
`;
