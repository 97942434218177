import React, { FC, ReactNode, useCallback } from 'react';
import { useRouter } from 'next/router';
import { Link } from '@quotalogic/ui';
import { Wrapper, LinksBlock, UserLinksBlock, IconsBlock, LogoBlock } from './styles';
import { leftLinks } from './Links';
import { UserBlock } from './UserBlock';
import { ServiceLogo } from './ServiceLogo';
import { SupportBlock } from './SupportBlock';
import { WorkspaceSelector } from '../WorkspaceSelector';

interface Props {
  logo?: string
  name: string
  defaultCurrency: string
  settingsButton: ReactNode
}

export const Header: FC<Props> = ({ logo, name, defaultCurrency, settingsButton }) => {
  const { pathname } = useRouter();

  const isCurrent = useCallback((url: string | null) => {
    const divider = '/';
    const rootPath = pathname.split(divider)[1];
    const isRoot = url === divider && !rootPath;
    return (url && rootPath && url.includes(rootPath)) || isRoot;
  }, [pathname]);

  return (
    <Wrapper>
      <LinksBlock>
        <LogoBlock>
          <ServiceLogo name={name} src={logo} />
        </LogoBlock>
        <IconsBlock>
          {leftLinks.map((link) => (
            <Link
              key={link.url}
              href={link.url}
              active={isCurrent(link.url)}
              view="button"
              data-testid={`${link.name.toLowerCase()}Link`}
            >
              {link.icon}
              {/* {t(`asideMenu.${link.name}`)} */}
            </Link>
          ))}
        </IconsBlock>
      </LinksBlock>

      <UserLinksBlock>
        {/* <Button */}
        {/*   view="ghost" */}
        {/*   disabled */}
        {/*   iconLeft={<Magnifier width={16} height={16} color={GlobalColors.icon.disable} />} */}
        {/* /> */}

        <SupportBlock />

        <WorkspaceSelector defaultCurrency={defaultCurrency} settingsButton={settingsButton} />
        <UserBlock />

      </UserLinksBlock>
    </Wrapper>
  );
};
