import { gql, useQuery } from '@apollo/client';
import { User } from '@quotalogic/gateway/types';
import { Spinner } from '@quotalogic/ui';
import { styled } from 'styled-components';
import { useState } from 'react';
import { ExpiredToken, WaitingActivation } from './WaitingActivation';

const GET_USER = gql`
  query User {
    user {
      id
      name
      picture
      email
      status
    }
  }
`;

const Layout = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  error?: string
}

export const AccountFallbackForm = ({ error }: Props) => {
  const { data, loading } = useQuery<{ user: User }>(GET_USER, { fetchPolicy: 'cache-first' });
  const [state, setState] = useState<'TOKEN_EXPIRED' | 'UNVERIFIED'>(error ? 'TOKEN_EXPIRED' : 'UNVERIFIED');

  if (loading) {
    return (
      <Layout>
        <Spinner size={16} />
      </Layout>
    );
  }

  if (!data?.user) {
    return null;
  }

  if (state === 'TOKEN_EXPIRED') {
    return (
      <Layout>
        <ExpiredToken onResetToken={() => setState('UNVERIFIED')} />
      </Layout>
    );
  }

  return (
    <Layout>
      <WaitingActivation email={data.user.email} />
    </Layout>
  );
};
