import { FC, useMemo, useState } from 'react';
import { Button } from '@quotalogic/ui';
import { Copy } from '@gravity-ui/icons';
import { useTranslation } from 'next-i18next';
import { StyledLink, StyledLinkText } from './styles';

type Props = {
  publishedId: string
  width?: number
  stretched?: boolean
}

export const Copycat: FC<Props> = ({ publishedId, width, stretched }) => {
  const { t } = useTranslation('dashboard');
  const [copied, setCopied] = useState(false);

  const link = useMemo(() => {
    const host = window.location.origin;
    return `${host}/public/offers/${publishedId}`;
  }, [publishedId]);

  const copyToClipboard = async () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
    await navigator.clipboard.writeText(link);
  };

  return (
    <StyledLink $size={width} $stretched={stretched}>
      <StyledLinkText>
        {copied ? t('widgets.offer.copied') : link}
      </StyledLinkText>
      <Button size="small" view="ghost" onClick={copyToClipboard}>
        <Copy width={14} height={14} />
      </Button>
    </StyledLink>
  );
};
