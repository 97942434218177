import { FormField, Input } from '@quotalogic/ui';
import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_ACCOUNT } from '../gql/mutation';

type Props = {
  name: string
  value?: string
  label: string
  type?: 'text' | 'hidden' | 'password' | 'email' | 'tel' | 'url' | 'card' | 'money'
  disabled?: boolean
}
export const TextInput = ({ name, value, label, type = 'text', disabled = false }: Props) => {
  const [updateAccount] = useMutation(UPDATE_ACCOUNT, { errorPolicy: 'all' });
  const [state, setState] = useState(value);

  const handleUpdate = useCallback(async (val: string) => {
    await updateAccount(({
      variables: {
        data: {
          [name]: val,
        },
      },
    }));
  }, [name, updateAccount]);

  return (
    <FormField
      label={label}
      name={name}
    >
      <Input
        type={type}
        value={state}
        name={name}
        onChange={(e) => setState(e)}
        onBlur={(e) => handleUpdate(e.target.value)}
        disabled={disabled}
      />
    </FormField>
  );
};
