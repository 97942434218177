import { Picture } from '@quotalogic/ui';
import { FC, useEffect } from 'react';
import { StyledCard, StyledDescription, StyledLogo, StyledText, StyledTitle } from './styles';
import { PaymentStatusToggle } from './PaymentStatusToggle';

interface Props {
  id: string
  name?: string
  logo?: string
  commission?: number
  isDefault?: boolean
  onClick: (id: string, organizationId: string, name?: string) => void
  organizationId: string
  isConnectedToWorkspace?: boolean
  paymentId?: string | null
}

export const PaymentCard: FC<Props> = ({
  id,
  name,
  commission,
  logo,
  isDefault = false,
  onClick,
  organizationId,
  isConnectedToWorkspace,
  paymentId,
}) => {
  useEffect(() => {
    if (paymentId === id) {
      onClick(id, organizationId, name);
    }
  }, []);

  return (
    <StyledCard $isDefault={isDefault}>
      <StyledDescription role="button" onClick={() => onClick(id, organizationId, name)}>
        <StyledLogo>
          {logo && <Picture src={logo} alt={name} objectFit="cover" />}
        </StyledLogo>
        <StyledText>
          <StyledTitle>
            {name ?? ''}
          </StyledTitle>
          <p>{ (commission ?? 0) / 100 }%</p>
        </StyledText>
      </StyledDescription>
      {isConnectedToWorkspace && <PaymentStatusToggle id={id} isDefault={isDefault} />}
    </StyledCard>
  );
};
