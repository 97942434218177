import React from 'react';
import { Logo } from './Logo';

type Props = {
  name: string
  src?: string
}

export const ServiceLogo = ({ name, src }: Props) => {
  if (src) {
    return (
      <Logo src={src} name={name} />
    );
  }

  return name;
};
