import { FC, useMemo, useState } from 'react';
import { InviteEntity } from '@quotalogic/gateway/types';
import { useTranslation } from 'next-i18next';
import { SwitchBase } from '@quotalogic/ui';
import { QueryResult, useQuery } from '@apollo/client';
import { InviteDetails } from './InviteDetails';
import {
  Container, EmptyInvite, Label, List, SwitchBlock, Title, TitleContainer,
} from './styles';
import { GET_INVITER_INVITES } from './gql';

interface Props {
  workspaceId: string
}

export const InviterInvite: FC<Props> = ({ workspaceId }) => {
  const { t } = useTranslation();

  const { data }: QueryResult<{ inviterInvites: InviteEntity[]}> = useQuery(
    GET_INVITER_INVITES,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [isShowAll, setIsShowAll] = useState<boolean>(false);

  const workspaceInvites = useMemo(() => (
    data?.inviterInvites.filter((el) => el?.workspace?.id === workspaceId)
  ), [data, workspaceId]);

  const invitesList = useMemo(() => (isShowAll
    ? workspaceInvites
    : workspaceInvites?.filter((el) => el.status !== 'ACCEPTED' && el.status !== 'DECLINED')
  ), [isShowAll, workspaceInvites]);

  if (!data) return null;

  return (
    <Container>
      <TitleContainer>
        <Title>{t('inviterInvites.title')}</Title>
        <SwitchBlock>
          <Label>{t('inviterInvites.showAll')}</Label>
          <SwitchBase size="small" checked={isShowAll} onChange={() => setIsShowAll(!isShowAll)} />
        </SwitchBlock>
      </TitleContainer>
      <List>
        {invitesList && invitesList.length > 0 ? invitesList.map((invite) => (
          <InviteDetails
            key={invite.id}
            id={invite.id}
            status={invite.status}
            createdAt={invite.createdAt}
            email={invite.email}
          />
        )) : (<EmptyInvite>{t('inviterInvites.emptyInvite')}</EmptyInvite>)}
      </List>
    </Container>
  );
};
