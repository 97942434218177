import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 16px 0;
  height: calc(100vh - 122px);
`;

export const Wrapper = styled.div`
  display: flex;
  max-width: 350px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
`;

export const Controls = styled.div`
  display: flex;
  margin-top: 12px;
  padding-bottom: 12px;
`;

export const AvatarBlock = styled.div`
  display: flex;
  justify-content: center;
`;
