import { gql } from '@apollo/client';

export const GET_USER = gql`
    query User {
        user {
            id
            name
            picture
            email
        }
    }
`;

export const GET_WORKSPACE = gql`
    query Workspace {
        workspace {
            id
            name
            currency
            timeZone
            logo
            note
            brand {
                id
                shortDescription
                description
                region
                city
                address
                webSite
                email
                phone
                isPublic
                tagline
                features {
                    id
                    name
                    description
                }
            }
        }
    }
`;

export const GET_WORKSPACE_LIST = gql`
    query Workspaces {
        workspaces {
            id
            name
            currency
            logo
        }
    }
`;
