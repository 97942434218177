import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ChevronDown, ChevronUp } from '@gravity-ui/icons';
import { SchedulerStatus } from '@quotalogic/gateway/types/Scheduler';
import { MonthWrapper, DayStyle, SelectedStyle, SelectedDay, SelectDaysStyle, SelectedContainer } from './styles';

const month = 31;

interface Props {
  status?: SchedulerStatus
  days: number[]
  onDayChange: (days: number[]) => void
}

export const MonthDaysPicker: FC<Props> = ({ status, days, onDayChange }) => {
  const [isDaysVisible, setIsDaysVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const isActive = useMemo(() => status === 'ACTIVE', [status]);

  const selectDaysHandler = useCallback((day: number) => {
    if (days.includes(day)) {
      onDayChange(days.filter((el) => el !== day));
    } else onDayChange([...days, day]);
  }, [days, onDayChange]);

  let sortedDays = [...days];
  sortedDays = [...sortedDays.sort((a, b) => a - b)];

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsDaysVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <SelectDaysStyle ref={ref}>
      <SelectedStyle
        $isDisabled={isActive}
        onClick={() => { if (!isActive) setIsDaysVisible(!isDaysVisible); }}
      >
        <SelectedContainer>
          {days && sortedDays.map((day, index) => (
            <SelectedDay
              $isDisabled={isActive}
              key={`${index}_days`}
            >
              {day}
            </SelectedDay>
          ))}
        </SelectedContainer>

        {isDaysVisible
          ? <ChevronUp color="#828282" />
          : <ChevronDown color="#828282" />}
      </SelectedStyle>

      {isDaysVisible && !isActive
              && (
              <MonthWrapper>
                {[...Array(month)].map((item, index) => (
                  <DayStyle
                    $isSelected={!!(days.find((day) => day === (index + 1)))}
                    key={`${index}_days`}
                    onClick={() => selectDaysHandler(index + 1)}
                  >
                    {(index + 1)}
                  </DayStyle>
                ))}
              </MonthWrapper>
              )}
    </SelectDaysStyle>
  );
};
