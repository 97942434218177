import styled, { css } from 'styled-components';
import { Clock } from '@gravity-ui/icons';

export const LayoutStyle = styled.div`
  display: flex;
  gap: 4px;
  padding: 0 8px;
  align-items: center;
  height: 32px;
  border-radius: 6px;
  border: 1px solid #E0E0E0;
  background: white;
`;

export const InputStyle = styled.div`
  display: flex;
  font-variant-numeric: tabular-nums;
  align-items: stretch;
  align-self: stretch;
  position: relative;
`;

export const TimeInput = styled.input<{ $isDisabled: boolean }>`
  all: unset;
  width: 1.25rem;
  font-style: inherit;
  padding: 0 4px;

  ${({ $isDisabled }) => $isDisabled && css`
    color: #8c908d;
    pointer-events: none;
  `};
`;

export const ClockStyle = styled(Clock)<{ $isDisabled: boolean }>`
  ${({ $isDisabled }) => $isDisabled && 'color: #8c908d'};
`;

export const SeparatorStyle = styled.span`
  color: gray;
  align-self: center;
  line-height: 0;
  position: absolute;
  left: calc(6px + 1.25rem);
  margin-top: -2px;
`;
