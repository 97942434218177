import styled, { css } from 'styled-components';
// eslint-disable-next-line import/no-cycle,import/named
import { CardStatus, STATUS } from './index';

interface statusProps {
  $status: CardStatus
}

interface collapseProps {
  $isCollapse: any
}

const getColorStyle = (status: CardStatus) => {
  switch (status) {
    case STATUS.DRAGDROP:
      return css`
        background-color: #FFF;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.10);
        cursor: grabbing;
      `;
    case STATUS.SELECTED:
      return css`background-color: #e9e9e9`;
    case STATUS.SUCCESS:
      return css`background-color: #c5cec9`;
    case STATUS.PUSHING:
      return css`
        background-color: #e5e5e5;
        opacity: 0.5;
      `;
    case STATUS.DISABLED:
      return css`
        background-color: transparent;
        color: #b3b3b3;
      `;
    default:
      return css`
        background-color: transparent;
        //:hover {
        //  background-color: #F2F2F2;
        //}
      `;
  }
};

export const Container = styled.li<statusProps>`
  list-style: none;
  box-sizing: border-box;
  border-radius: 8px;
  margin-left: var(--spacing);
  margin-bottom: -1px;
  ${({ $status }) => getColorStyle($status)};
`;

export const StyleItem = styled.div<collapseProps>`
    position: relative;
    display: flex;
    align-items: center;
    color: #333;
    box-sizing: border-box;
    height: 44px;
    padding: 4px 8px 4px ${({ $isCollapse }) => ($isCollapse ? 0 : '16px')};

'&:hover': {
    color: #235AFF;
},
`;

export const Text = styled.a`
  width: 208px;
  flex-grow: 1;
  padding-left: 0.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
