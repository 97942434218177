/* eslint-disable */
import React, { FC } from 'react';
import { TrashBin } from '@gravity-ui/icons';
import { Wrapper, TitleBlock, Title, ListWrapper } from './styles';
import { HistoryItem } from './HistoryItem';
import { EventHistory } from '@quotalogic/gateway/types/Scheduler';

interface Props {
  list: EventHistory[]
}

export const History: FC<Props> = ({ list }) => {
  return (
    <Wrapper>
      <TitleBlock>
        <Title>History</Title>
        {/*<TrashBin color={'#BDBDBD'}/>*/}
      </TitleBlock>

      <ListWrapper>
        {list.map((item) =>
          <HistoryItem key={item.id} item={item} />
        )}
      </ListWrapper>
    </Wrapper>
  );
};
