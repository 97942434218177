import { currencies } from '@quotalogic/currency';

export const formatter = (price: number | bigint, currency?: string): string => {
  const currencyString = (price).toLocaleString('fi-FI', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (currency) {
    const symbol = currencies[currency];
    return `${currencyString} ${symbol}`;
  }

  return currencyString;
};
