import { styled } from 'styled-components';
import { Menu } from '@base-ui-components/react/menu';
import { Plus } from '@gravity-ui/icons';
import { GlobalColors } from '@quotalogic/ui';

export const ButtonStyle = styled(Menu.Item)`
  font-family: "PT Root UI VF", Arial, sans-serif;
  height: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 4px;
  outline: none;
  cursor: pointer;
  color: ${GlobalColors.text.ghost};
  font-weight: 500;
  gap: 4px;
    
  &:hover,
  &:focus-visible {
    background: rgba(13, 45, 94, 0.04);
  }
`;

export const IconStyle = styled(Plus)`
  color: ${GlobalColors.icon.ghost};
`;
