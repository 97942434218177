import { gql } from '@apollo/client';

export const ACCEPT_INVITE = gql`
    mutation AcceptInvite($inviteId: ID!) {
        acceptInvite(inviteId: $inviteId)
    }
`;

export const DECLINE_INVITE = gql`
    mutation DeclineInvite($inviteId: ID!) {
        declineInvite(inviteId: $inviteId)
    }
`;

export const GET_INVITES = gql`
    query InviteeInvites {
        inviteeInvites {
            id
            email
            status
            createdAt
            workspace {
                id
                name
                brand
                logo
                shortDescription
            }
        }
    }
`;

export const GET_WORKSPACE = gql`
    query Workspace {
        workspace {
            id
            name
            brand {
                logo
            }
        }
    }
`;
