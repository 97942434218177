import { useApolloClient } from '@apollo/client';
import { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { formatter } from '@quotalogic/gateway/utils/formatter';
import { OFFER_GROUP, PUBLIC_OFFER_PRODUCT } from '@quotalogic/gateway/fragments';
import { PublicOfferProduct } from '@quotalogic/gateway/types/Offer';
import { Money } from '@quotalogic/money/src/types';
import { add, money } from '@quotalogic/money';
import { OfferGroupTotal } from './OfferGroupTotal';
import {
  Group, Title, Comment, Footer, HeaderGroup, FooterTitle, MobileFooterTitle,
} from './styles';

type Maybe<T> = T | undefined | null

interface Props {
  children: ReactNode
  id: string
  products: string[]
  visibilityOptions: Record<string, boolean>
  currency: string
  publicId: string
}

export const OfferGroup: FC<Props> = ({ id, children, products, visibilityOptions, currency, publicId }) => {
  const { t: translation } = useTranslation();
  const client = useApolloClient();
  const group = client.readFragment({
    id: `OfferGroup:${id}`,
    fragment: OFFER_GROUP,
  });

  const totalWithDiscount: Money = useMemo(() => {
    if (!group) return money(0);

    if (group.type === 'GENERAL') {
      return products.reduce<Money>((acc, id) => {
        const product: Maybe<PublicOfferProduct> = client.readFragment({
          id: `PublicOfferProduct:${id}`,
          fragment: PUBLIC_OFFER_PRODUCT,
        });

        if (product?.totalDiscountPrice !== undefined) {
          acc = add(acc, money(product.totalDiscountPrice));
        }

        return acc;
      }, money(0));
    }

    if (group.selectedItemId) {
      const product: Maybe<PublicOfferProduct> = client.readFragment({
        id: `PublicOfferProduct:${group.selectedItemId}`,
        fragment: PUBLIC_OFFER_PRODUCT,
      });

      if (product?.totalDiscountPrice !== undefined) {
        return money(product.totalDiscountPrice);
      }

      return money(0);
    }

    return money(0);
  }, [group, products]);

  const totalWithoutDiscount: Money = useMemo(() => {
    if (group.type === 'GENERAL') {
      return products.reduce((acc, id) => {
        const product = client.readFragment({
          id: `PublicOfferProduct:${id}`,
          fragment: PUBLIC_OFFER_PRODUCT,
        });

        if (product?.totalPrice !== undefined) {
          acc = add(acc, money(product.totalPrice));
        }

        return acc;
      }, money(0));
    }

    if (group.selectedItemId) {
      const product: Maybe<PublicOfferProduct> = client.readFragment({
        id: `PublicOfferProduct:${group.selectedItemId}`,
        fragment: PUBLIC_OFFER_PRODUCT,
      });

      if (product?.totalPrice !== undefined) {
        return money(product.totalPrice);
      }
    }

    return money(0);
  }, [products]);

  const isTotalVisible = visibilityOptions.total && (visibilityOptions.allPrices || visibilityOptions.discounts);

  return (
    <Group id={group.id}>
      <HeaderGroup>
        <Title>
          {group?.name && group.name.length > 0 && group.name}
        </Title>
        {group?.comment && group.comment.length > 0 && <Comment>{group.comment}</Comment>}
      </HeaderGroup>
      {children}
      {isTotalVisible && (
        <Footer>
          <FooterTitle>
            {translation('label.total')}
            {group.name && <span>&#160;&quot;{group?.name}&quot;</span>}
          </FooterTitle>
          {group.name && <MobileFooterTitle>{group?.name}</MobileFooterTitle>}
          <OfferGroupTotal
            totalWithDiscount={formatter(+totalWithDiscount.format(), currency)}
            totalWithoutDiscount={formatter(+totalWithoutDiscount.format(), currency)}
            isDiscounts={visibilityOptions.discounts}
          />
        </Footer>
      )}
    </Group>
  );
};
