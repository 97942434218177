import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  StyleContainer,
  StyledBottomText, StyledButton,
  StyledEmail,
  StyledTopText,
  TextContainer,
  Title,
} from './styles';
import { LOG_OUT, RESET_ACCOUNT_VERIFICATION_TOKEN } from './gql';

interface Props {
  email?: string
}

export const WaitingActivation = ({ email }: Props) => {
  const { t } = useTranslation('register');
  const [countdown, setCountdown] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [logOut] = useMutation<{ logOut: boolean }>(LOG_OUT);
  const [resetAccountVerificationToken] = useMutation(RESET_ACCOUNT_VERIFICATION_TOKEN);

  const resetLink = async () => {
    setIsButtonDisabled(true);

    await resetAccountVerificationToken();
    setCountdown(60);
  };

  useEffect(() => {
    let timerId: ReturnType<typeof setInterval>;

    if (countdown > 0) {
      timerId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      setIsButtonDisabled(false);
    }

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [countdown]);

  const handleLogOut = async () => {
    await logOut({
      onCompleted(data) {
        if (data.logOut) {
          window.location.href = '/login';
        }
      },
    });
  };

  return (
    <StyleContainer>
      <Title>
        {t('waitingActivation.title')}
      </Title>
      <TextContainer>
        <StyledTopText>{t('waitingActivation.linkSent')}</StyledTopText>
        <StyledEmail>{email}</StyledEmail>
      </TextContainer>
      <TextContainer>
        <StyledBottomText>
          {t('waitingActivation.noEmailText')}
          {isButtonDisabled && countdown > 0
            ? <p>{t('waitingActivation.repeatAfter')} {countdown}s</p>
            : (
              <StyledButton
                type="button"
                onClick={resetLink}
                view="link"
                disabled={isButtonDisabled}
              >
                {t('waitingActivation.resentLink')}
              </StyledButton>
            )}
        </StyledBottomText>
        <StyledBottomText style={{ paddingTop: '12px' }}>
          {t('waitingActivation.changedMind')}
        </StyledBottomText>
        <StyledBottomText>
          <StyledButton
            type="button"
            view="link"
            onClick={handleLogOut}
          >
            {t('waitingActivation.logOutLink')}
          </StyledButton>
          {t('waitingActivation.singInText')}
        </StyledBottomText>
      </TextContainer>
    </StyleContainer>
  );
};
