import React, { ReactNode } from 'react';
import {
  Folder, House, Persons, Box, Factory, FileDollar,
} from '@gravity-ui/icons';
import { WatchActiveIcon } from '@quotalogic/ui';

export type UrlLinkType = {
  url: string
  name: string
  icon: ReactNode
}

export const leftLinks: UrlLinkType[] = [
  {
    url: '/',
    name: 'Dashboard',
    icon: <House width={16} height={16} />,
  },
  {
    url: '/offers',
    name: 'Offers',
    icon: <FileDollar width={16} height={16} />,
  },
  {
    url: '/catalogs',
    name: 'Catalogs',
    icon: <Folder width={16} height={16} />,
  },
  {
    url: '/deliveries',
    name: 'Deliveries',
    icon: <Box width={16} height={16} />,
  },
  {
    url: '/organizations',
    name: 'Organizations',
    icon: <Factory width={16} height={16} />,
  },
  {
    url: '/employees',
    name: 'Employees',
    icon: <Persons width={16} height={16} />,
  },
  {
    url: '/scheduler',
    name: 'Scheduler',
    icon: <WatchActiveIcon width={16} height={16} />,
  },
  // {
  //   url: null,
  //   name: 'Analytics',
  //   icon: <ChartLine width={16} height={16} />,
  // },
];
