import { Button } from '@quotalogic/ui';
import { useTranslation } from 'next-i18next';
import { useStore } from '@nanostores/react';
import { ImportProfile, ImportProfileData } from '@quotalogic/gateway/types';
import { useMutation } from '@apollo/client';
import { $reimportSettings } from './storage';
import { UPDATE_IMPORT_PROFILE } from './gql';

type Props = {
  profileId: string
}

export const ReimportButton = ({ profileId }: Props) => {
  const { t } = useTranslation();
  const settings = useStore($reimportSettings);
  const [updateImportProfile, { loading }] = useMutation<{ updateImportProfile: ImportProfile }, { id: string, data: Partial<ImportProfileData> }>(UPDATE_IMPORT_PROFILE);

  const handleSave = async () => {
    await updateImportProfile({
      variables: {
        id: profileId,
        data: settings,
      },
    });
  };

  return (
    <Button onClick={handleSave} loading={loading}>
      {t('scheduler.reimport.button')}
    </Button>
  );
};
