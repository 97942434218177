import React, { FC, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { Selector } from '@quotalogic/ui';
import { currencies } from '@quotalogic/currency';

type Props = {
  value?: string
  onChange: (value: string) => void
  name?: string
}

export const CurrencySelector: FC<Props> = ({ value, onChange, name }) => {
  const { t } = useTranslation();
  const currencyList = useMemo(() => Object.keys(currencies).map((item) => ({
    value: item,
    name: t(`currency.${item}`),
  })), [t]);

  return (
    <Selector
      options={currencyList}
      onChange={onChange}
      value={value}
      name={name}
      data-testid="currencySelectorDropDown"
    />
  );
};
