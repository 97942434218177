import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
`;

export const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 24px;
`;

export const Title = styled.span`
  font-weight: 700;
  line-height: 18px;
  margin-left: 4px;
`;

export const ListWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  max-height: 45vh;

  &::-webkit-scrollbar {
    width: 4px;
    padding: 0;
    background: #f2f2f2;
  },
    &::-webkit-scrollbar-thumb {
      background-color: blue;
      border-radius: 20px;
      border: 3px solid #bdbdbd;
    }
`;
