import { Menu } from '@base-ui-components/react/menu';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Workspace } from '@quotalogic/gateway/types';
import { useState } from 'react';
import { MenuPopup, MenuPositioner, MenuSeparator } from './styles';
import { GET_WORKSPACE, GET_WORKSPACE_LIST } from './gql';
import { WorkspaceButton, WorkspaceButtonSkeleton } from '../WorkspaceButton';
import { WorkspaceList } from '../WorkspaceList';
import { CreateWorkspaceButton, CreateWorkspaceForm } from '../CreateWorkspace';

type Props = {
  defaultCurrency: string
}

export const WorkspaceMenu = ({ defaultCurrency }: Props) => {
  const { data, loading } = useQuery<{ workspace: Workspace }>(GET_WORKSPACE, {
    errorPolicy: 'all',
  });
  const [getWorkspaceList, { data: list, loading: loadingList }] = useLazyQuery<{ workspaces: Workspace[] }>(GET_WORKSPACE_LIST, {
    errorPolicy: 'all',
  });
  const [isVisible, setVisible] = useState(false);

  if (loading) {
    return <WorkspaceButtonSkeleton />;
  }

  if (!data?.workspace) {
    return null;
  }

  return (
    <>
      <Menu.Root>
        <WorkspaceButton workspace={data.workspace} onClick={getWorkspaceList} />
        <Menu.Portal>
          <MenuPositioner sideOffset={4} positionMethod="absolute" align="start" collisionBoundary="clipping-ancestors" sticky>
            <MenuPopup>
              <WorkspaceList list={list?.workspaces} loading={loadingList} />
              <MenuSeparator />
              <CreateWorkspaceButton onClick={() => setVisible(true)} />
            </MenuPopup>
          </MenuPositioner>
        </Menu.Portal>
      </Menu.Root>
      <CreateWorkspaceForm defaultCurrency={defaultCurrency} isVisible={isVisible} onClose={() => setVisible(false)} />
    </>
  );
};
