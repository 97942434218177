import { gql } from '@apollo/client';

export const GET_INVITER_INVITES = gql`
  query InviterInvites {
    inviterInvites {
      id
      email
      status
      createdAt
      workspace {
          id
      }
    }
  }
`;
