import {
  ChangeEvent, FocusEvent, forwardRef, HTMLAttributes, ReactNode, useEffect, useState,
} from 'react';
import { addNotification } from '@quotalogic/notifications/store';
import { useTranslation } from 'next-i18next';
import { InputNum } from '@quotalogic/ui';
import { InputStyle } from './styles';
import { formatValue, valueToNumber } from './utils';

type Props = {
  value?: number
  onValueChange?: (value: number) => void
  onInputBlur?: (value: number) => void
  locale?: string
  align?: 'left' | 'right'
  icon?: ReactNode,
  allowNegative?: boolean
} & HTMLAttributes<HTMLInputElement>;

export const ProductPrice = forwardRef<HTMLInputElement, Props>(({
  value = 0,
  onValueChange,
  locale = 'fi-FI',
  align = 'left',
  onBlur,
  onInputBlur,
  icon,
  allowNegative = false,
  ...rest
}, ref) => {
  const [val, setVal] = useState(() => formatValue(value, locale));
  const [isFocused, setIsFocused] = useState(false);
  const { t } = useTranslation();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsFocused(true);
    const result = valueToNumber(e.target.value);

    // check if negative numbers are allowed
    const isNumberAcceptable = allowNegative ? true : result >= 0;

    // check if result is safe integer
    if (Number.isSafeInteger(result) && isNumberAcceptable) {
      setVal(e.target.value);
      onValueChange?.(result);
    } else {
      addNotification({
        message: t<string>('product.warning.valueTooLarge'),
        level: 'WARNING',
      });
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setVal(formatValue(value, locale));
    onBlur?.(e);
    onInputBlur?.(value);
    setIsFocused(false);
  };

  useEffect(() => {
    if (!isFocused) setVal(formatValue(value, locale));
  }, [value, locale]);

  return (
    <>
      <InputStyle
        ref={ref}
        type="text"
        value={val}
        onChange={onChange}
        onBlur={handleBlur}
        $align={align}
        {...rest}
      />
      {icon}
    </>
  );
});
