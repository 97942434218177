// eslint-disable-next-line import/no-extraneous-dependencies
import styled, { css } from 'styled-components';

interface selectedProps {
    $isSelected: boolean
}

interface disabledProps {
  $isDisabled: boolean
}

export const Wrapper = styled.div`
  display: flex;
  gap: 2px;
`;

const defaultStyle = css`
  border: 1px solid #E0E0E0;
  background-color: #FFFFFF;
  color: #333;
  cursor: pointer;
  transition: all 0.1s linear;
  &:hover {
    background: #F2F2F2;
  }
`;

const defaultDisabledStyle = css`
  border: 1px solid #E0E0E0;
  background-color: #f8fcfa;
  color: #333;
`;

const selectedStyle = css`
  border: 1px solid #2F80ED;
  background-color: #2F80ED;
  color: #FFFFFF;
`;

const disabledStyle = css`
  border: 1px solid #7bb0ef;
  background-color: #7bb0ef;
  color: #FFFFFF;
`;

const getStyle = ($isSelected: boolean, $isDisabled: boolean) => {
  switch (true) {
    case ($isSelected && !$isDisabled):
      return selectedStyle;
    case ($isSelected && $isDisabled):
      return disabledStyle;
    case (!$isSelected && $isDisabled):
      return defaultDisabledStyle;
    default:
      return defaultStyle;
  }
};

export const DayItem = styled.span<selectedProps & disabledProps>`
  width: 45px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 8px;
  ${({ $isSelected, $isDisabled }) => (getStyle($isSelected, $isDisabled))};
`;
