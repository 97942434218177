import { ImportModeType } from '@quotalogic/gateway/types';
import { FormField, Selector } from '@quotalogic/ui';
import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { useStore } from '@nanostores/react';
import { $reimportSettings, updateReimportSettings } from '../storage';

type Props = {
  ancestor: boolean
}

export const ModeSelector = ({ ancestor }: Props) => {
  const settings = useStore($reimportSettings);
  const { t } = useTranslation();

  const importModeOptions: { value: ImportModeType, name: string }[] = useMemo(() => [
    { value: 'BASIC', name: t('importForm.modeOptions.basic') },
    { value: 'CREATE', name: t('importForm.modeOptions.create') },
    { value: 'UPDATE', name: t('importForm.modeOptions.update') },
    { value: 'DELETE', name: t('importForm.modeOptions.delete') },
  ], [t]);

  const message = useMemo(() => {
    if (ancestor) {
      return t('importForm.modeDisabledMessage');
    }

    return undefined;
  }, [ancestor, t]);

  return (
    <FormField name="mode" label={t('importForm.importMode')} labelPlacement="left" rowLabelSize="short">
      <Selector
        name="mode"
        disabled={ancestor}
        value={settings.importMode ?? ''}
        options={importModeOptions}
        onChange={(importMode) => updateReimportSettings({ importMode })}
        message={message}
      />
    </FormField>
  );
};
