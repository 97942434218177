import { currencies } from './list';

export const formatter = (price: number | bigint | string, currency?: string, locale?: string): string => {
  const value = typeof price === 'string' ? Number(price) : price;
  const currencyString = (value).toLocaleString(locale ?? 'fi-FI', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (currency) {
    const symbol = currencies[currency];
    return `${currencyString} ${symbol}`;
  }

  return currencyString;
};
