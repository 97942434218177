import styled from 'styled-components';
import { Button, GlobalColors } from '@quotalogic/ui';

export const StyleContainer = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  text-align: center;
  align-items: center;
  background: tomato;
  border-radius: 16px;
  background: ${GlobalColors.bg.main.secondary};
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.04);
  gap: 16px;
`;

export const Title = styled.h2`
  color: ${GlobalColors.text.primary};
  font-size: 1.429rem;
  font-weight: 700;
  line-height: 24px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledText = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 18px;
  color: ${GlobalColors.text.secondary};
`;

export const StyledButton = styled(Button)`
  font-size: 1rem;
  font-weight: 400;
  line-height: 18px;
  padding: 0 4px;
  color: ${GlobalColors.text.accent};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;
