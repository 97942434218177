import gql from 'graphql-tag';

export const OFFER_GROUP = gql`
    fragment OfferGroup on OfferGroup {
        id
        name
        comment
        type
        selectedItemId
    }
`;

export const OFFER_PRODUCT = gql`
    fragment OfferProduct on OfferProduct {
        id
        name
        price
        discountPrice
        discountAmount
        discountType
        count
        description
        longDescription
        images
        toPiggyBank
        toPiggyBankType
        piggyBankType
        fromPiggyBank
        fromPiggyBankType
        parentProductId
        parentProductIsExist
        comment
        finalPrice
        totalDiscount
        totalPrice
    }
`;

export const PUBLIC_OFFER_PRODUCT = gql`
    fragment PublicOfferProduct on PublicOfferProduct {
        id
        name
        price
        totalPrice
        discountPrice
        totalDiscountPrice
        discountAmount
        count
        description
        longDescription
        images
        parentProductId
        parentProductIsExist
        comment
    }
`;
