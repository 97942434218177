import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const StyledRadioLayout = styled.div`
  grid-area: radio;
`;

export const RadioContainer = styled.div<{ $isChecked: boolean }>`
  grid-area: radio;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
    
  > svg {
    margin-top: 1px;
  }
    
  ${({ $isChecked }) => ($isChecked ? css`
    background: var(--public-button-blue);
    color: white;
  ` : css`
    background: var(--public-bg-element-blue);
  `)};

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const Title = styled.div`
  grid-area: name;
  font-size: 1.143rem;
  font-weight: 700;
`;

export const Radio = styled.div({});

const getProductStyles = (isChecked: boolean) => {
  if (isChecked) {
    return css`
        //pointer-events: none;
        cursor: default;
        background: var(--public-bg-white);
        opacity: 1;
        min-height: 220px;
      `;
  }

  return css`
      cursor: pointer;
      background: var(--public-bg-white-60);
      opacity: 0.5;
      min-height: 220px;
    `;
};

const getAlternativeStyles = (isAlternative: boolean, isChecked?: boolean) => {
  if (isAlternative && isChecked !== undefined) {
    return getProductStyles(isChecked);
  }

  return css`
    min-height: 172px;
    ${getProductStyles(true)};
  `;
};

export const Product = styled.div<{ $visibleAllPrices: boolean, $isAlternative: boolean, $isChecked: boolean }>`
  display: grid;
  padding: 24px;
  column-gap: 24px;
  row-gap: 16px;
  ${({ $isAlternative, $isChecked }) => getAlternativeStyles($isAlternative, $isChecked)};
  cursor: pointer;
  border-radius: 12px;
  grid-template-columns: 128px 1fr 220px;
  grid-template-rows: minmax(24px, max-content) repeat(2, auto);
  grid-template-areas: ${({ $visibleAllPrices }) => ($visibleAllPrices
    ? `'image name price'
        'image description price'
        'radio . .'`
    : `'image name name'
        'image description description' 
        'radio . .'`)};
  
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
  
  &:hover ${Title} {
    color: var(--public-button-blue);
  }

  @media (max-width: 768px) {
    grid-template-columns: 100px 1fr 24px;
    grid-template-areas: ${({ $visibleAllPrices }) => ($visibleAllPrices
    ? `'image name radio'
        'image price price'
        'description description description'`
    : `'image name radio'
      'image description description'`)};
    padding: 12px;
    column-gap: 12px;
    row-gap: 8px;
  }
`;

export const StyledPriceColumn = styled.div`
  grid-area: price;
`;

export const StyledProductPrice = styled.div<{ $isSingle: boolean }>`
  display: flex;
  flex-direction: ${({ $isSingle }) => ($isSingle ? 'column-reverse' : 'column')};
  justify-content: flex-start;
  gap: ${({ $isSingle }) => ($isSingle ? 0 : '12px')};

  @media (max-width: 768px) {
    gap: ${({ $isSingle }) => ($isSingle ? 0 : '4px')};
  }
`;

export const StyledProductTotal = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 0;
    align-items: flex-start;
  }
`;

export const Description = styled.div`
  grid-area: description;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const DescriptionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  > div {
    position: relative;
  }
`;

export const CollapseStyle = styled.div<{ $height: number, $isOpen: boolean }>`
  overflow: hidden;
  max-height: ${({ $isOpen, $height }) => ($isOpen ? 'auto' : `${$height}px`)};
`;

export const Quantity = styled.div`
  grid-area: count;
  text-align: right;
  align-self: center;
  font-size: 1.143rem;
  font-weight: 700;
  min-width: 70px;

  @media (max-width: 768px) {
    text-align: left;
    font-size: 0.786rem;
    font-weight: 500;
    color: var(--public-text-primary);
    align-self: flex-start;
  }
`;

export const Xmark = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Comment = styled.div`
  position: relative;
  padding: 12px;
  background-color: var(--public-bg-element-gray);
  border-radius: 8px;
  color: var(--public-text-secondary);
  width: fit-content;

  @media (max-width: 768px) {
    font-size: 0.857rem;
    line-height: normal;
  }
`;

export const ModalDescription = styled.div`
  padding: 24px;
  overflow: auto;
  position: relative;
  color: var(--public-text-secondary);

  > p + p {
    margin-top: 16px;
  };

  ul, ol {
    margin: 16px 0;
    padding-left: 40px
  }

  h1 {
    font-size: 2rem;
    margin: 16px 0 8px 0;
  }

  h2 {
    font-size: 1.714rem;
    margin: 16px 0 8px 0;
  }

  h3 {
    font-size: 1.143rem;
    margin: 16px 0 8px 0;
  }

  table {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
`;

export const ShortDescription = styled.div`
  margin-bottom: 20px;
  font-size: 1.143rem;
  font-weight: 400;
  line-height: normal;
  color: var(--public-text-secondary);
  
  p {
    word-break: break-all;
  }

  > p + p {
    margin-top: 16px;
  };

  ul, ol {
    margin: 16px 0;
    padding-left: 40px
  }

  h1 {
    font-size: 2rem;
    margin: 16px 0 8px 0;
  }

  h2 {
    font-size: 1.714rem;
    margin: 0 0 8px 0;
  }

  h3 {
    font-size: 1.143rem;
    margin: 16px 0 8px 0;
  }

  @media (max-width: 768px) {
    font-size: 0.857rem;
  }
`;

export const ButtonStyle = styled.button<{ $isChecked: boolean }>`
  all: unset;
  position: absolute;
  bottom: 3px;
  right: 0;
  cursor: pointer;
  color: var(--public-button-blue);
  background: ${({ $isChecked }) => $isChecked && 'linear-gradient(to right, transparent, #fff 20%, #fff 50%)'};
  font-size: 1.143rem;
  font-weight: 400;
  width: 98px;
  height: 19px;
  text-align: right;

  @media (max-width: 768px) {
    font-size: 0.857rem;
    bottom: -2px;
  }
`;

export const DescriptionButtonStyle = styled.button`
  all: unset;
  text-decoration: underline;
  cursor: pointer;
`;

export const NameTitle = styled.span`
  overflow: hidden;
  color:  #222934;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px; 
  margin-bottom: 12px;
`;

export const ValueNameStyle = styled.div`
  overflow: hidden;
  color: #222934;
  text-overflow: ellipsis;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;

  @media(max-width: 524px) {
     font-size: 20px;
     line-height: 24px;
  }
`;
