import { Avatar } from '@quotalogic/ui';
import React, { FC } from 'react';
import { useQuery } from '@apollo/client';
import { User } from '@quotalogic/gateway/types';
import { GET_USER } from '../gql/query';
import { MailStyle, NameStyle, UserButtonStyle } from './styles';

type Props = {
  showUserDrawer: () => void
}

export const UserButton: FC<Props> = ({ showUserDrawer }) => {
  const { data } = useQuery<{ user: User }>(GET_USER, { errorPolicy: 'all' });

  return (
    <UserButtonStyle onClick={showUserDrawer}>
      <Avatar
        src={data?.user?.picture ?? ''}
        size={36}
        name={data?.user?.name}
        isEditable={false}
      />
      <NameStyle>{data?.user?.name ?? ''}</NameStyle>
      <MailStyle>{data?.user?.email ?? ''}</MailStyle>
    </UserButtonStyle>
  );
};
