import dayjs from 'dayjs';
import React, { FC, FormEvent, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { usePathname } from 'next/navigation';
import { Button, Autocomplete, Input, FormField } from '@quotalogic/ui';
import { useTranslation } from 'next-i18next';
import timezone from 'dayjs/plugin/timezone';
import { timezones, Timezone } from '@quotalogic/common';
import { Form, Controls } from './styles';
import { GET_WORKSPACE } from '../../gql/query';
import { CREATE_WORKSPACE } from '../../gql/mutation';
import { CurrencySelector } from '../../../CurrencySelector';

dayjs.extend(timezone);

interface Props {
  defaultCurrency: string
}

export const WorkspaceForm: FC<Props> = ({ defaultCurrency }) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [createWorkspaceMutation] = useMutation(CREATE_WORKSPACE, { errorPolicy: 'all' });
  const [activeCurrency, setActiveCurrency] = useState(defaultCurrency);
  const [timezone, setTimezone] = useState<{ label: string, value: string }|null>(
    timezones.find((tz) => tz.value === dayjs.tz.guess()) ?? null,
  );
  const router = useRouter();

  const pathname = usePathname();

  const createWorkspace = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    if (timezone) {
      await createWorkspaceMutation({
        variables: {
          name,
          currency: activeCurrency.length > 0 ? activeCurrency : defaultCurrency,
          timeZone: timezone.value,
        },
        onError: () => setLoading(false),
        refetchQueries: [
          { query: GET_WORKSPACE },
        ],
      });
      if (pathname === '/') {
        router.reload();
      } else {
        await router.push('/');
      }
    }
  };

  return (
    <Form onSubmit={createWorkspace}>
      <FormField
        name="workspaceName"
        labelPlacement="left"
        rowLabelSize="short"
        label={t('placeholder.nameNewWorkspace')}
      >
        <Input
          type="text"
          name="name"
          $size="m"
          value={name}
          onChange={(e) => setName(e)}
        />
      </FormField>

      <FormField
        name="timezone"
        labelPlacement="left"
        label={t('placeholder.timezone')}
        rowLabelSize="short"
      >
        <Autocomplete<Timezone>
          list={timezones}
          value={timezone}
          onChange={setTimezone}
        />
      </FormField>

      <FormField
        name="currency"
        labelPlacement="left"
        label={t('placeholder.currency')}
        rowLabelSize="short"
      >
        <CurrencySelector onChange={setActiveCurrency} value={activeCurrency} />
      </FormField>

      <Controls>
        <Button type="submit" disabled={name.trim().length === 0 || isLoading} loading={isLoading}>
          {t('button.create')}
        </Button>
      </Controls>
    </Form>
  );
};
