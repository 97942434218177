import { gql } from '@apollo/client';

export const CREATE_SECTION = gql`
  mutation CreateSection($catalogId: ID!, $data: SectionData!) {
    createSection(catalogId: $catalogId, data: $data) {
      id
      name
    }
  }
`;
