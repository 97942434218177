import { Column, ColumnType, Modifier } from '@quotalogic/gateway/types';
import { FC, useEffect, useMemo, useReducer, useState } from 'react';
import { LayoutStyle, NumberInputStyle, PriceInputStyle } from './styles';
import { ModifierInput } from './ModifierInput';
import { initializer, reducer, ValueModifier, getParents, computeParentValue } from './utils';

interface Props {
  fieldId: string
  fields: Record<string, any>
  columnType: ColumnType
  columnModifier?: Modifier
  // send only one field modifier because we do not support multiple at the moment
  productModifier?: Modifier
  onUpdateModifier: (modifier: Modifier) => void
  onDeleteModifier: (fieldId: string) => void
  columns: Column[]
}

// Receives a parent price, column, modifier and a callback to update the modifier
// Should return a modifier field and disabled calculated price field
export const ProductModifier: FC<Props> = ({
  fieldId,
  fields,
  columnModifier,
  productModifier,
  columnType,
  columns,
  onUpdateModifier,
  onDeleteModifier,
}) => {
  // calculate parent value
  const parentValue = useMemo(() => {
    const columnMap = columns.reduce<Record<string, Column>>((acc, column) => {
      acc[column.id] = column;
      return acc;
    }, {});

    // find active column
    const activeColumn = columnMap[fieldId];
    // get array of all parents
    const parents = getParents(columnMap, activeColumn.parent);
    return computeParentValue(parents.reverse(), fields);
  }, [columns, fieldId, fields]);

  const [isChanged, setIsChanged] = useState(false);

  const [valueIsActive, setValueIsActive] = useState(() => {
    if (productModifier) {
      return productModifier.type === 'EXACT';
    }

    return !!(columnModifier && !productModifier);
  });

  const [{ modifier, value, type }, dispatch] = useReducer(
    reducer,
    {},
    () => initializer({ productModifier, columnModifier, parentValue }),
  );

  // update state if column modifier updates with undefined product modifier (only for table)
  useEffect(() => {
    // check if only column modifier exists
    // TODO: fix gateway types for column (no EXACT type)
    if (!productModifier
      && columnModifier
      && columnModifier.type !== 'EXACT'
      && typeof columnModifier.value === 'number'
    ) {
      dispatch({
        type: 'COLUMN_UPDATE',
        modifierPayload: {
          type: columnModifier.type,
          value: columnModifier.value,
        },
        parentValue,
      });
    }
    setIsChanged(false);
  }, [columnModifier]);

  useEffect(() => {
    if (parentValue !== undefined) {
      dispatch({ type: 'PARENT_UPDATE', parentValue });
    }
    setIsChanged(false);
  }, [parentValue]);

  // update state in table if product modifier updates in card
  useEffect(() => {
    if (productModifier === undefined) {
      // if product has no modifier than use column modifier
      const { value, type } = columnModifier ?? { value: 0, type: 'ABSOLUTE' };
      if (typeof value === 'number' && type !== 'EXACT') {
        dispatch({ type: 'COLUMN_UPDATE', modifierPayload: { type, value }, parentValue });
      }
    } else {
      if (productModifier && productModifier.type !== 'EXACT' && typeof productModifier.value === 'number') {
        dispatch({
          type: 'UPDATE',
          modifierPayload: {
            type: productModifier.type,
            value: productModifier.value,
          },
          parentValue,
        });
        setValueIsActive(false);
      }
      if (productModifier && productModifier.type === 'EXACT' && typeof productModifier.value === 'number') {
        dispatch({
          type: 'UPDATE',
          valuePayload: productModifier.value,
          parentValue,
        });
        setValueIsActive(true);
      }
    }
    setIsChanged(false);
  }, [productModifier]);

  // update modifier on blur and only if state was changed
  const handleBlur = () => {
    if (isChanged) {
      // if type is "EXACT" than update with value
      if (type === 'EXACT' && value !== undefined) {
        onUpdateModifier({ type, value });
      }

      // if type is "PERCENT" or "ABSOLUTE" than update with modifier
      if (modifier && type !== 'EXACT') {
        // if modifier is parent or empty -> delete modifier
        if (modifier.isParent || !modifier.string) {
          onDeleteModifier(fieldId);
        } else {
          onUpdateModifier({ type, value: modifier.value });
        }
      }
    } else {
      if (productModifier) {
        setValueIsActive(productModifier.type === 'EXACT');
      } else {
        setValueIsActive(!!(columnModifier && !productModifier));
      }
    }

    // reset changed state after update
    setIsChanged(false);
  };

  // update modifier on change
  const handleUpdate = ({ value, modifier }: { value?: number, modifier?: ValueModifier }) => {
    // set changed state so we can update modifier on blur
    if (!isChanged) setIsChanged(true);
    if (modifier !== undefined) {
      dispatch({ type: 'UPDATE', modifierPayload: modifier, parentValue });
    }

    if (value !== undefined) {
      dispatch({ type: 'UPDATE', valuePayload: value, parentValue });
    }
  };

  // reset modifier to column modifier if product modifier value is empty
  const handleReset = () => {
    // set changed state so we can update modifier on blur
    if (!isChanged) setIsChanged(true);
    const { value, type } = columnModifier ?? { value: 0, type: 'ABSOLUTE' };
    if (typeof value === 'number' && type !== 'EXACT') {
      dispatch({ type: 'COLUMN_UPDATE', modifierPayload: { type, value }, parentValue });
    }
  };

  return (
    <LayoutStyle>
      <ModifierInput
        value={modifier}
        onValueChange={(mod) => handleUpdate({ modifier: mod })}
        onReset={handleReset}
        onFocus={() => setValueIsActive(false)}
        onBlur={handleBlur}
        isFade={valueIsActive}
      />
      {columnType === 'MONEY' && (
        <PriceInputStyle
          $fade={!valueIsActive}
          value={value}
          onBlur={handleBlur}
          onFocus={() => setValueIsActive(true)}
          onValueChange={(val) => handleUpdate({ value: val })}
        />
      )}
      {columnType === 'NUMBER' && (
        <NumberInputStyle
          $fade={!valueIsActive}
          value={value}
          onValueChange={(val) => handleUpdate({ value: val })}
          onFocus={() => setValueIsActive(true)}
          onBlur={handleBlur}
        />
      )}
    </LayoutStyle>
  );
};
