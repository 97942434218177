import { $emitter } from '@quotalogic/intercom';
import { useTranslation } from 'next-i18next';
import { StyledButton } from './styles';

type Props = {
  onClick: () => void;
}

export const FeedbackButton = ({ onClick }: Props) => {
  const { t } = useTranslation('account');
  const { t: feedbackT } = useTranslation('feedback');

  const handleClick = () => {
    $emitter.emit('OPEN_FEEDBACK', { summary: feedbackT<string>('form.summary.common') });
    onClick();
  };

  return (
    <StyledButton type="submit" onClick={handleClick}>
      {t('links.support')}
    </StyledButton>
  );
};
