import { gql, useApolloClient } from '@apollo/client';
import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import { SectionMenu } from '../SectionMenu';
import { StyledLink, StyledRoot } from './styles';

type Props = {
  catalogId: string
  createItem: (parentId?: string) => void
}

const CatalogRootFragment = gql`
    fragment CatalogRoot on Catalog {
        id
        name
    }
`;

export const RootItem = ({ catalogId, createItem }: Props) => {
  const client = useApolloClient();
  const title = useMemo(() => {
    const catalog = client.readFragment({
      id: `Catalog:${catalogId}`,
      fragment: CatalogRootFragment,
    });
    return catalog?.name ?? '';
  }, [catalogId, client]);

  const router = useRouter();
  const isActive = useMemo(
    () => router.query.catalogId === catalogId && router.query.sectionId === undefined,
    [router, catalogId],
  );

  return (
    <StyledRoot $active={isActive} data-testid="rootSection">
      {/* <StyledButton $open={true}> */}
      {/*   <ChevronDown style={{ color: '#828282' }} /> */}
      {/* </StyledButton> */}
      <StyledLink href={`/catalogs/${catalogId}`}>
        {title}
      </StyledLink>
      <SectionMenu
        sectionName={title}
        sectionId={catalogId}
        catalogId={catalogId}
        createItem={createItem}
        canDelete={false}
      />
    </StyledRoot>
  );
};
