import { useQuery } from '@apollo/client';
import { Catalog } from '@quotalogic/gateway/types';
import { FC, useEffect, useMemo, useState } from 'react';
import { Spinner } from '@quotalogic/ui';
import { flatten } from '../../SectionAside/components/SortableList/utilities';
import { GET_CATALOG } from './gql';
import { SectionItem } from '../SectionItem/SectionItem';

interface Props {
  activeCatalogId: string
  handleSectionClick: (id: string) => void
  handleCloseModal: () => void
}

export const SectionTree: FC<Props> = ({ activeCatalogId, handleSectionClick, handleCloseModal }) => {
  const { data, loading } = useQuery<{ catalog: Catalog }, { id: string }>(GET_CATALOG, {
    variables: {
      id: activeCatalogId,
    },
  });

  const [collapsed, setCollapsed] = useState<string[] | null>(null);

  useEffect(() => {
    const nodes = data?.catalog.nodes;
    const catalogId = data?.catalog.id;
    if (nodes && catalogId) {
      // eslint-disable-next-line no-shadow
      const collapsed = Object.keys(nodes).reduce<string[]>((acc, key) => {
        if (key !== catalogId && nodes[key].length && nodes[catalogId].includes(key)) acc.push(key);
        return acc;
      }, []);
      setCollapsed(collapsed);
    }
  }, [data]);

  const list = useMemo(() => {
    const nodes = data?.catalog.nodes;
    const catalogId = data?.catalog.id;
    if (nodes && catalogId && collapsed) {
      const root = {
        id: catalogId,
        children: [...nodes[catalogId]],
        parentId: null,
        depth: 0,
        index: 0,
        collapsed: collapsed.includes(catalogId),
      };
      return root.collapsed ? [root] : [root, ...flatten(nodes, catalogId, collapsed, 1)];
    }
    return [];
  }, [collapsed]);

  const handleCollapse = (id: string, status?: boolean) => {
    const nodes = data?.catalog.nodes;
    if (nodes && collapsed) {
      if (status) {
        const items = collapsed?.filter((item) => item !== id);
        const result = new Set([...items, ...nodes[id]]);
        setCollapsed([...result]);
      } else {
        const result = new Set([...collapsed, id]);
        setCollapsed([...result]);
      }
    }
  };

  if (loading) return <Spinner size={24} />;

  return (
    <>
      {list.map((item) => (
        <SectionItem
          id={item.id}
          depth={item.depth}
          collapsed={Boolean(item.collapsed)}
          handleCollapse={handleCollapse}
          hasChildren={item.children.length > 0}
          key={item.id}
          catalogId={data?.catalog.id}
          handleSectionClick={handleSectionClick}
          handleCloseModal={handleCloseModal}
        />
      ))}
    </>
  );
};
