import styled from 'styled-components';
import { GlobalColors } from '@quotalogic/ui';

export const NameStyle = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin-top: 6px;

  &:hover {
    cursor: pointer;
    color: ${GlobalColors.text.accentHover};
  }
`;

export const MailStyle = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`;

export const UserButtonStyle = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 12px;
  align-items: center;
  border-bottom: 1px solid ${GlobalColors.border.secondary};

  &:hover {
    border-radius: 8px;
    background: ${GlobalColors.bg.darkBlue.disable};
    border-bottom-color: transparent;
  }
;
`;

export const AlertText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 0 52px;
  gap: 2px;
  color: ${GlobalColors.text.secondary};
`;
