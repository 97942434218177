import { gql } from '@apollo/client';
import { SECTION } from '@quotalogic/gateway/fragments';

export const GET_CATALOG = gql`
    ${SECTION}
    query Catalog($id: ID!) {
        catalog(id: $id) {
            id
            name
            isPublished
            parent {
                brand {
                    id
                    brandName
                }
                catalogToken
                profileToken
                disabled
            }
            nodes
            currency
            logo
            sections {
                ...Section
            }
        }
    }
`;
