import styled from 'styled-components';
import { GlobalColors } from '../../GlobalStyle';

export const TitleStyle = styled.h2`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  word-wrap: break-word;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  flex: 1;
  
  > span {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;

export const FooterStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 24px 0;
  justify-content: flex-end;
`;

export const ContentStyle = styled.p`
  padding: 0 24px 0 52px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${GlobalColors.text.secondary};
`;
