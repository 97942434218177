// TODO: need to refactor this component!!!
/* eslint-disable */
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { ChevronsRight, ChevronsLeft, ChevronRight, ChevronLeft, ChevronUp, ChevronDown } from '@gravity-ui/icons';
import { format, differenceInCalendarDays } from 'date-fns';
import {
  CaptionProps,
  DayContentProps,
  DayPicker,
  useNavigation,
  DayContent,
  Row,
  RowProps,
  SelectSingleEventHandler,
} from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { ArrowButton, Header, MonthWrapper, SelectDaysStyle, SelectedStyle, SelectedDay } from './styles';
import { SchedulerStatus } from '@quotalogic/gateway/types/Scheduler';

interface Props {
  status?: SchedulerStatus
  onDateChange: (value: Date | undefined) => void
  date?: Date
}

export const DataPicker: FC<Props> = ({ status, onDateChange, date }) => {
  const [isDaysVisible, setIsDaysVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const isPastDate = (date: Date) => differenceInCalendarDays(date, new Date()) < 0;

  const isActive = useMemo(() => status === 'ACTIVE', [status]);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsDaysVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /**
   * TODO: need to refactor this!
   * @param props
   * @constructor
   * 1. Do not render component inside another component
   * 2. Must destructure props
   * 3. Do not return empty fragment (return null instead)
   */
  // eslint-disable-next-line react/no-unstable-nested-components
  const OnlyFutureRow = (props: RowProps) => {
    // eslint-disable-next-line react/destructuring-assignment
    const isPastRow = props.dates.every(isPastDate);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    if (isPastRow) return <></>;
    return <Row {...props} />;
  };

  const CustomCaption = (props: CaptionProps) => {
    const { goToMonth, nextMonth, previousMonth } = useNavigation();
    return (
      <>
        <Header>
          <ArrowButton
            disabled={!previousMonth}
            onClick={() => previousMonth && goToMonth(previousMonth)}
          >
            <ChevronLeft />  <ChevronsLeft width={18} height={18} />
          </ArrowButton>
          {format(props.displayMonth, 'MMM yyy')}

          <ArrowButton
            disabled={!nextMonth}
            onClick={() => nextMonth && goToMonth(nextMonth)}
          >
            <ChevronRight /> <ChevronsRight width={18} height={18} />
          </ArrowButton>
        </Header>
      </>
    );
  };

  const DateTime = (props: DayContentProps) => {
    const dateTime = format(props.date, 'yyyy-MM-dd');
    return (
      <time dateTime={dateTime}>
        <DayContent {...props} />
      </time>
    );
  };

  return (
      <SelectDaysStyle ref={ref}>
        <SelectedStyle
           $isDisabled={isActive}
           onClick={() => { if (!isActive) setIsDaysVisible(!isDaysVisible); }}
        >
          <div>
            <SelectedDay $isDisabled={isActive}>
             {date?.toLocaleDateString("en-GB").replaceAll('/', '.')
                 ?? new Date().toLocaleDateString("en-GB").replaceAll('/', '.')}
            </SelectedDay>
          </div>

          {isDaysVisible
              ? <ChevronUp color="#828282" />
              : <ChevronDown color="#828282" />}
        </SelectedStyle>

        {isDaysVisible &&
            <MonthWrapper >
              <DayPicker
                  fromDate={new Date()}
                  hidden={isPastDate}
                  showOutsideDays
                  components={{
                    Caption: CustomCaption,
                    DayContent: DateTime,
                    Row: OnlyFutureRow,
                  }}
                  styles={{
                    caption: { fontSize: '14px' },
                  }}
                  modifiers={{ cell: true, day: true }}
                  modifiersStyles={{
                    selected: {
                      color: '#FFFFFF',
                      padding: '1px',
                      borderRadius: '2px',
                      background: '#2F80ED',
                    },
                    day: { width: '24px', height: '24px' },
                    cell: { width: '24px', height: '24px' },
                  }}
                  mode="single"
                  selected={date}
                  onSelect={(value) => onDateChange(value)}
              />
            </MonthWrapper>
        }
   </SelectDaysStyle>
  );
};
