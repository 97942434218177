import gql from 'graphql-tag';

export const WORKSPACE_PERMISSIONS = gql`
    fragment WorkspacePermissions on Permissions {
        workspace {
            self
            user
            workspace
        }
    }
`;

export const OFFER_PERMISSIONS = gql`
    fragment OfferPermissions on Permissions {
        offer {
            offer
            own
        }
    }
`;
