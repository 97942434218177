export const toString = (value: bigint, hideNullishDecimal = false): string => {
  const result = [...value.toString()];

  let sign = '';
  if (value < BigInt(0)) {
    sign = '-'; // do not use \u2212 here, it is not supported by toLocaleString and Number constructor
    result.shift();
  }

  if (result.length > 2) {
    result.splice(result.length - 2, 0, '.');
  }

  if (result.length === 2) {
    result.unshift(...'0.');
  }

  if (result.length === 1) {
    result.unshift(...'0.0');
  }

  if (hideNullishDecimal) {
    const [body, decimal] = result.join('').split('.');
    if (decimal === '00') {
      return sign + body;
    }
  }

  return sign + result.join('');
};

export const toBigInt = (value: string | number): bigint => {
  const stringValue = String(value)
    .replace(/\s/g, '')
    .replace(',', '.')
    .replace(/\u2212/g, '\u002D');

  const [intPart, floatPart = ''] = stringValue.split('.');

  return BigInt(`${intPart}${floatPart.padEnd(2, '0')}`);
};
