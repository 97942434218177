import styled, { css } from 'styled-components';
import { GlobalColors } from '@quotalogic/ui';

export const StyledCard = styled.article<{ $isDefault: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 16px;
  
  button {
    opacity: 0;
  }
  
  ${({ $isDefault }) => $isDefault && css`
    background-color: ${GlobalColors.bg.darkBlue.disable};
    
    button {
      opacity: 1;
    }
  `}
  
  &:hover {
    background-color: ${GlobalColors.bg.darkBlue.disable};
  }
  
  &:hover button {
    opacity: 1;
  }
`;

export const StyledDescription = styled.div`
  flex: 1;
  display: flex;
  gap: 8px;
`;

export const StyledText = styled.div`
`;

export const StyledTitle = styled.h3`
  font-weight: 600;
  font-size: 1rem;
`;

export const StyledLogo = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  background-color: ${GlobalColors.bg.blue.secondary};
  position: relative;
`;
