import { gql } from '@apollo/client';

export const TOGGLE_DEFAULT_PAYMENT = gql`
    mutation ToggleDefaultPayment($id: ID!, $isDefault: Boolean!) {
        updatePaymentDefaultStatus(id: $id, isDefault: $isDefault) {
            id
            isDefault
        }
    }
`;
