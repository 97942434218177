import React, { ChangeEvent, FC, useReducer } from 'react';
import { initialTime, timeInitializer, timeReducer } from './helpers';
import { LayoutStyle, SeparatorStyle, TimeInput, ClockStyle, InputStyle } from './styles';

interface Props {
  isActive: boolean
  startTime?: string
  onBlur: (time: string) => void
}

export const TimePicker: FC<Props> = ({ isActive, startTime, onBlur }) => {
  const [time, dispatchTime] = useReducer(timeReducer, initialTime, () => timeInitializer(startTime));
  const handleBlur = () => {
    let { hours, minutes } = time;

    if (hours.length < 2) {
      // add a 0 before if there is no number
      if (hours.length === 0) hours = '00';
      // add a 0 before if there is only one number
      if (hours.length === 1) hours = `0${hours}`;

      dispatchTime({ type: 'SET_HOURS', payload: hours });
    }

    if (minutes.length < 2) {
      // add a 0 before if there is no number
      if (minutes.length === 0) minutes = '00';
      // add a 0 before if there is only one number
      if (minutes.length === 1) minutes = `0${minutes}`;

      dispatchTime({ type: 'SET_MINUTES', payload: minutes });
    }

    onBlur(`${hours}:${minutes}`);
  };

  return (
    <LayoutStyle>
      <ClockStyle $isDisabled={isActive} />
      <InputStyle>
        <TimeInput
          value={time.hours}
          onChange={(e) => dispatchTime({ type: 'SET_HOURS', payload: e.target.value })}
          onBlur={handleBlur}
          min={0}
          max={23}
          inputMode="numeric"
          $isDisabled={isActive}
        />
        <SeparatorStyle>:</SeparatorStyle>
        <TimeInput
          value={time.minutes}
          onChange={(e) => dispatchTime({ type: 'SET_MINUTES', payload: e.target.value })}
          onBlur={handleBlur}
          min={0}
          max={59}
          inputMode="numeric"
          $isDisabled={isActive}
        />
      </InputStyle>
    </LayoutStyle>
  );
};
