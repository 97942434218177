import { ChangeEvent, FC, HTMLAttributes, useMemo } from 'react';
import { InputStyle } from './styles';

type Props = {
  value?: number
  onValueChange: (value: number) => void
} & HTMLAttributes<HTMLInputElement>;

export const NumberInput: FC<Props> = ({ value = 0, onValueChange, onFocus, onBlur, ...rest }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const result = Number(e.target.value) * 100;
    onValueChange(result);
  };

  const val = useMemo(() => (value / 100).toFixed(2), [value]);

  return <InputStyle value={val} type="number" onBlur={onBlur} onFocus={onFocus} onChange={handleChange} {...rest} />;
};
