import { BroadcastChannel } from 'broadcast-channel';

const workspaceChannel = new BroadcastChannel('changeWorkspace');
const messageId = 'activate';

export const activateWorkspace = () => {
  workspaceChannel.postMessage(messageId);
};

export const activateAllTabs = () => {
  workspaceChannel.onmessage = (message) => {
    const path = window.location.pathname.split('/');
    if (message === messageId) {
      window.location.pathname = `/${path[1]}`;
    }
    workspaceChannel.close();
  };
};
