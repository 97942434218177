import type { FC } from 'react';
import { Button, GlobalColors, Tooltip } from '@quotalogic/ui';
import { Check } from '@gravity-ui/icons';
import { useMutation } from '@apollo/client';
import { Payment, UpdatePaymentDefaultStatusInput } from '@quotalogic/gateway/types';
import { useTranslation } from 'next-i18next';
import { TOGGLE_DEFAULT_PAYMENT } from './gql';

interface Props {
  id: string
  isDefault?: boolean
}

export const PaymentStatusToggle: FC<Props> = ({ id, isDefault = false }) => {
  const { t } = useTranslation();

  const [toggleDefault] = useMutation<{ updatePaymentDefaultStatus: Payment }, UpdatePaymentDefaultStatusInput>(
    TOGGLE_DEFAULT_PAYMENT,
  );

  const handleToggleDefault = async () => {
    await toggleDefault({
      variables: {
        id,
        isDefault: !isDefault,
      },
    });
  };

  return (
    <Tooltip
      content={t('label.autoAdding')}
      placement="top-end"
    >
      <Button size="small" colorTheme="green" onClick={handleToggleDefault} data-testid="toggleCheckmarkBtn">
        <Check color={isDefault ? GlobalColors.icon.primaryOnColor : 'transparent'} />
      </Button>
    </Tooltip>
  );
};
